import styled from "styled-components";

export const ContainerForPoints = styled.div`
  border: 1px solid #d1d5db;
  margin-right: 30px;
  padding: 8px 0;
  border-radius: 8px;
  background: #f3f4f6;
  width: auto;
`;

export const ContainerInstrumentName = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 16px;
`;

export const ContainerForCheckbox = styled.div`
  padding: 0 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const TermoparContainer = styled.div`
  display: flex;
  align-items: center;
  background: #212b361a;
  padding: 4px 16px;
  border-radius: 8px;
  margin: 4px 0;
`;

export const TermoparIndex = styled.label`
  margin-right: 8px; /* Espaço entre o índice e o checkbox */
  font-size: 14px; /* Ajuste o tamanho do texto se necessário */
`;

export const SelectedPointsContainer = styled.div`
  width: 70%;
  padding: 32px 0;
  display: block;
  margin: auto;
  text-align: center;
`;

export const TitlePointsContainer = styled.h1`
  font-size: 24px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: 0em;
  color: #4b5563;
`;

export const BoxForPointsSelected = styled.div`
  margin: 16px 0;
  border: 1px solid #d1d5db;
  width: 100%;
  min-height: 160px;
  border-radius: 8px;
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  padding: 32px;
  background: #f3f4f6;
`;
export const BoxPoint = styled.div`
  display: block;
  position: relative;
  width: auto;
  background: #212b361a;
  border-radius: 8px;
  height: 32px;
`;

export const BoxCloseIcon = styled.div`
  position: absolute;
  top: -8px;
  right: -16px;
  height: 16px;
  width: 16px;
`;

export const BoxForRename = styled.div`
  display: block;
  width: 50%;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  padding: 16px 0;
  margin: auto;
`;

export const InputRename = styled.input`
  font-size: 16px;
  width: 100%;
  padding: 24px;
  border: 1px solid #d1d5db;
  margin: 0 0 16px 0;
`;
