export enum UsersType {
  ADMINISTRADOR = "ADMINISTRADOR",
  ENGENHEIRO = "ENGENHEIRO",
  OPERADOR = "OPERADOR",
}

export const SelectTypeUser = [
  { label: "OPERADOR", value: UsersType.OPERADOR },
  { label: "ENGENHEIRO", value: UsersType.ENGENHEIRO },
  { label: "ADMINISTRADOR", value: UsersType.ADMINISTRADOR },
];
