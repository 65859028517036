import styled from "styled-components";

export const FilterLayoutContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-top: 64px;
  height: 120px;
`;

export const TitleModal = styled.h1`
  color: ${(props) => props.theme.colors.blueSteel};
  font-size: ${(props) => props.theme.font.size.lg};
  margin-bottom: 16px;
  text-align: center;
`;

export const CustomLine = styled.hr`
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 80px;
  height: 6px;
  border-radius: 4px;
  background-color: #3758f9;
`;
