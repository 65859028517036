import React, { FunctionComponent, PropsWithChildren  } from "react";
import Navbar from "../navbar";
import { Container } from "react-bootstrap";
import Breadcrumb from "../breadcrumb";

interface MainContainerProps {

}
 
const MainContainer: FunctionComponent<PropsWithChildren<MainContainerProps>> = ({children}) => {
    return (
        <>
            <Navbar />
            <Breadcrumb />
            <Container fluid style={{padding: '0px 40px'}}>
                {children}
            </Container>
        </>
    );
}
 
export default MainContainer;