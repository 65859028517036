import styled from "styled-components";

export const Title = styled.h1`
  padding-right: ${(props) => props.theme.padding.md};
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  color: #505d68;
  text-align: center;
`;

export const TitleSection = styled.h2`
  padding-right: ${(props) => props.theme.padding.md};
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #505d68;
`;

export const TitleFilter = styled.text`
  padding-right: ${(props) => props.theme.padding.md};
  font-size: 18px;
  text-align: left;
  color: #505d68;
`;
