import { FunctionComponent, PropsWithChildren } from "react";
import Carousel from "react-multi-carousel";

import "react-multi-carousel/lib/styles.css";

interface CarouselCustomizedProps {
  itemsByResponsive?: {
    desktop: number;
    mobile: number;
    tablet: number;
  };
}

const CarouselCustomized: FunctionComponent<
  PropsWithChildren<CarouselCustomizedProps>
> = ({ children, itemsByResponsive }) => {
  return (
    <Carousel
      additionalTransfrom={0}
      arrows
      autoPlaySpeed={3000}
      centerMode={false}
      className=""
      containerClass="p-0 m-0"
      dotListClass=""
      draggable
      focusOnSelect={false}
      infinite={false}
      itemClass=""
      keyBoardControl
      minimumTouchDrag={80}
      pauseOnHover
      renderArrowsWhenDisabled={false}
      renderButtonGroupOutside={false}
      renderDotsOutside={false}
      responsive={{
        desktop: {
          breakpoint: {
            max: 6000,
            min: 1024,
          },
          items: itemsByResponsive ? itemsByResponsive.desktop : 2.3,
          partialVisibilityGutter: 40,
        },
        mobile: {
          breakpoint: {
            max: 464,
            min: 0,
          },
          items: itemsByResponsive ? itemsByResponsive.mobile : 1,
          partialVisibilityGutter: 30,
        },
        tablet: {
          breakpoint: {
            max: 1024,
            min: 464,
          },
          items: itemsByResponsive ? itemsByResponsive.tablet : 2,
          partialVisibilityGutter: 30,
        },
      }}
      rewind={false}
      rewindWithAnimation={false}
      rtl={false}
      shouldResetAutoplay
      showDots={false}
      sliderClass=""
      slidesToSlide={1}
      // customRightArrow={
      //   <IoArrowForwardSharp
      //   fontSize={40}
      //   style={{position:'absolute', top: '45%', right: '32px', display: 'block', background: '#fff', cursor: 'pointer', borderRadius: '50%'}}
      // />}
      // customLeftArrow={
      //   <IoArrowBackSharp
      //   fontSize={40}
      //   style={{position:'absolute', top: '45%', left: '16px', display: 'block', background: '#fff', cursor: 'pointer', borderRadius: '50%'}}
      // />}
    >
      {children}
    </Carousel>
  );
};

export default CarouselCustomized;
