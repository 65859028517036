import { FunctionComponent } from "react";
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Text,
} from "./styles";
import { AiOutlineCheck } from "react-icons/ai";

interface CheckboxProps {
  text: string;
  checked: boolean;
  action?: () => void;
}

const Checkbox: FunctionComponent<CheckboxProps> = ({
  text,
  checked,
  action,
}) => {
  function handleCheckboxChange() {
    if (action) {
      action();
    }
  }

  return (
    <CheckboxContainer checked={checked} onClick={handleCheckboxChange}>
      <HiddenCheckbox onChange={handleCheckboxChange} checked={checked} />
      <StyledCheckbox checked={checked}>
        {checked ? <AiOutlineCheck /> : null}
      </StyledCheckbox>
      <Text checked={checked}> {text} </Text>
    </CheckboxContainer>
  );
};
export default Checkbox;
