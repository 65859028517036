import * as yup from "yup";

export const schema = yup
  .object()
  .shape({
    nome: yup.string().required("Nome Obrigatório"),
    time_collection: yup
      .number()
      .required("Tempo de coleta Obrigatório")
      .min(30, "O tempo de coleta deve ser maior que 30"),
    termopares: yup
      .array()
      .of(
        yup.object().shape({
          canal: yup.string().required(),
          tag: yup.string().required("Tag Obrigatória"),
          funcao: yup.string().required("Função do Instrumento Obrigatória"),
          minima: yup.number().required("Insira um valor Mínimo").min(1),
          maxima: yup.number().required("Insira um valor Máximo").min(1),
        })
      )
      .min(1)
      .required(),
  })
  .required();
