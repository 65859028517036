import { FunctionComponent, PropsWithChildren } from "react";
import {
  ContainerForChart,
  FullScreenContainer,
  HeaderChart,
  HeaderFullScreen,
  IconHeaderChart,
} from "./styles";
import { MdOutlineFullscreen, MdPrint } from "react-icons/md";
import { ROUTES } from "../../constants/routes";
import { TypeGraphics } from "../../shared/enums/layoutContent/typeGraphics.enum";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { RiFullscreenExitLine } from "react-icons/ri";
import { TypeElement } from "../../shared/enums/typeContent/typeElement.enum";

interface ChartContainerProps {
  title: string;
  fullScreen?: boolean;
  newTab?: boolean;
  type: TypeGraphics;
  typeElement?: TypeElement;
  idElement?: number;
}

const ChartContainer: FunctionComponent<
  PropsWithChildren<ChartContainerProps>
> = ({
  title,
  children,
  fullScreen = true,
  newTab = true,
  type,
  typeElement,
  idElement,
}) => {
  const handle = useFullScreenHandle();

  const onClickFullScreen = () => {
    if (newTab) {
      window.open(
        `${
          ROUTES.VISUALIZATION_FULL
        }?title=${title!}&typeGraphic=${type!}&typeElement=${typeElement!}&idElement=${idElement}`,
        "_blank"
      );
    } else {
      handle.enter();
    }
    // handle.enter();
  };

  const onClickFullScreenExit = () => {
    handle.exit();
  };

  const onClickPrintPage = () => {
    window.print();
  };

  return (
    <>
      <ContainerForChart>
        <HeaderChart>
          <h4>{title}</h4>
          <IconHeaderChart>
            {!newTab ? (
              <MdPrint
                fontSize={24}
                onClick={onClickPrintPage}
                style={{ cursor: "pointer", marginRight: 16 }}
              />
            ) : null}
            {fullScreen ? (
              <MdOutlineFullscreen
                fontSize={24}
                onClick={onClickFullScreen}
                style={{ cursor: "pointer" }}
              />
            ) : null}
          </IconHeaderChart>
        </HeaderChart>
        {children}
      </ContainerForChart>

      <FullScreen handle={handle}>
        {handle.active ? (
          <div
            style={{ width: "100vw", height: "100vh", background: "#F9FAFB" }}
          >
            <HeaderFullScreen>
              <h3>{title}</h3>
              <RiFullscreenExitLine
                fontSize={24}
                onClick={onClickFullScreenExit}
                style={{ cursor: "pointer" }}
              />
            </HeaderFullScreen>
            <FullScreenContainer>{children}</FullScreenContainer>
          </div>
        ) : null}
      </FullScreen>
    </>
  );
};

export default ChartContainer;
