import { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../../components/main-container";
import { Col, Container, Row } from "react-bootstrap";
import { HrWork, TitleWorkForm, WorkFormContainer } from "./styles";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useToast } from "../../../hooks/toast";
import { SubmitHandler, useForm } from "react-hook-form";
import { useWorksService } from "../../../hooks/api/works";
import CustomInput from "../../../components/input";
import { ButtonForSubmit } from "../../../components/input/styles";
import Select from "../../../components/select";
import { useClientsService } from "../../../hooks/api/clients";
import { formatDataForSelect } from "../../../utils/formatDataForSelect";

interface NewWorkProps {}

interface IFormInput {
  descricao: string;
  data_inicio: Date;
  data_fim: Date;
  cliente_id: number;
}

const NewWork: FunctionComponent<NewWorkProps> = () => {
  const [allClients, setAllClients] = useState<any[]>([]);

  const { register, handleSubmit, formState, control } = useForm<IFormInput>();
  const { showErrorToast, showSuccessToast } = useToast();
  const { create } = useWorksService();
  const { getAllForDropdown } = useClientsService();
  const navigate = useNavigate();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    create(data)
      .then(() => {
        handleClickSave();
        showSuccessToast("Obra Cadastrada com sucesso");
      })
      .catch((err) => {
        showErrorToast("Falha ao realizar cadastro de obra");
      });
  };

  const handleClickSave = () => {
    navigate(ROUTES.WORKS);
  };

  useEffect(() => {
    getAllForDropdown().then(({ data }) => {
      setAllClients(
        formatDataForSelect(data, { newLabel: "nome", newValue: "id" })
      );
    });
  }, []);

  return (
    <MainContainer>
      <Container className="mt-5">
        <WorkFormContainer>
          <TitleWorkForm>Nova Obra</TitleWorkForm>
          <HrWork />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <CustomInput
                  label="Descrição"
                  type="text"
                  sizeInput="full"
                  fieldName="descricao"
                  errors={formState.errors.descricao}
                  schema={formState.errors.descricao ? "danger" : "default"}
                  placeholder="Descrição da obra"
                  register={register}
                  options={{ required: true }}
                />
              </Col>
              <Col>
                <Select
                  defaultOption="Selecione o Cliente"
                  options={allClients}
                  label="Cliente"
                  control={control}
                  name="cliente_id"
                  rules={{ required: "O campo é obrigatório" }}
                  validationMessage={
                    formState.errors.cliente_id
                      ? formState.errors.cliente_id.message
                      : undefined
                  }
                  schema={formState.errors.cliente_id ? "danger" : "default"}
                  sizeInput="full"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  label="Data Início"
                  type="date"
                  sizeInput="full"
                  fieldName="data_inicio"
                  errors={formState.errors.data_inicio}
                  schema={formState.errors.data_inicio ? "danger" : "default"}
                  register={register}
                  options={{ required: true }}
                />
              </Col>
              <Col>
                <CustomInput
                  label="Data Fim"
                  type="date"
                  sizeInput="full"
                  fieldName="data_fim"
                  errors={formState.errors.data_fim}
                  schema={formState.errors.data_fim ? "danger" : "default"}
                  register={register}
                  options={{ required: true }}
                />
              </Col>
            </Row>
            <ButtonForSubmit type="submit" value="Cadastrar" />
          </form>
        </WorkFormContainer>
      </Container>
    </MainContainer>
  );
};

export default NewWork;
