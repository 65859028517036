import styled from 'styled-components';

export const RadioContainer = styled.div<{checked: boolean, type: "FULL" | "OUTLINE"}>`
    width: 136px;
    height: 56px;
    margin: 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background-color: ${props => props.type === "FULL" ? '#dee0e3;' : props.theme.colors.white};
    
    border: ${props => props.type === "FULL" ? 'none' : '1px solid #000'};
`;
export const HiddenRadio = styled.input.attrs({type: 'radio'})`
    overflow: hidden;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
`;

export const Text = styled.label<{checked: boolean, type: "FULL" | "OUTLINE"}>`
    color: #4B5563;
    cursor: pointer;
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0em;
`;

export const StyledRadio = styled.label<{checked: boolean, type: "FULL" | "OUTLINE"}>`
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: ${props => props.type === "FULL" ? '2px solid #fff' : '1px solid #000'};
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;