import React, { ReactElement, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../constants/routes";
import { UsersType } from "../shared/enums/users-type";
import { useAuthContext } from "../context/auth-context";

export const ProtectedRoutes = ({
  children,
  permissions,
}: {
  children: ReactElement;
  permissions?: UsersType[];
}) => {
  const { isSigned, user } = useAuthContext();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    if (!isSigned) navigate(ROUTES.LOGIN);

    if (
      permissions &&
      !permissions.some((permission) => permission === user?.type)
    )
      navigate(ROUTES.UNAUTHORIZED);

    setIsLoading(false);
  }, [isSigned, user, navigate, permissions]);

  return !isLoading ? children : <></>;
};
