import React, { FunctionComponent, useEffect, useState } from "react";
import Table from "rc-table";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import CustomInput from "../../../components/input";
import Button from "../../../components/button";
import MainContainer from "../../../components/main-container";
import { useToast } from "../../../hooks/toast";
import { useWorksService } from "../../../hooks/api/works";
import { IWorks } from "../../../shared/interfaces/works/works";
import { ROUTES } from "../../../constants/routes";
import "../../../styles/table.css";
import Swal, { SweetAlertOptions } from "sweetalert2";

import Modal from "../../../components/modal";
import ModalContent from "../modal-users-works";

interface WorkListProps {}

interface IFormInput {
  search: string;
}

const WorkList: FunctionComponent<WorkListProps> = () => {
  const take = 10;

  const [page, setPage] = useState(0);
  const [worksData, setWorksData] = useState<IWorks>({} as IWorks);
  const [showModal, setShowModal] = useState(false);

  const navigate = useNavigate();
  const { handleSubmit, register, getValues, reset, watch } =
    useForm<IFormInput>();
  const { showErrorToast, showSuccessToast } = useToast();
  const { getAll, deleteOne, getOne } = useWorksService();

  const handleGetAllWorks = () => {
    getAll({
      take,
      page: getValues("search") ? 0 : page,
      search: getValues("search"),
    })
      .then(({ data }) => {
        setWorksData(data);
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    handleGetAllWorks();
  };

  const handleCleanForm = () => {
    reset();
    handleGetAllWorks();
  };

  const handleNavigateUpdate = (id: number) => {
    navigate(ROUTES.UPDATE_WORK, { state: { id } });
  };

  const handleDelete = (id: number) => {
    deleteOne(id)
      .then(() => {
        showSuccessToast("Obra excluída com sucesso");
        handleGetAllWorks();
      })
      .catch((err) => {
        console.log(err);
        const alertOptions: SweetAlertOptions = {
          title: `Erro ao deletar o Obra`,
          text: err.response.data.message,
          icon: "warning",
          denyButtonText: "Ok",
        };
        Swal.fire(alertOptions);
      });
  };

  const handleShowDelete = (id: number) => {
    getOne(id)
      .then(({ data }) => {
        if (data) {
          const alertOptions: SweetAlertOptions = {
            title: `Tem certeza que deseja excluir a obra ${data.descricao}`,
            text: "A ação poderá afetar outros registros.",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: "Não",
          };

          Swal.fire(alertOptions).then((result) => {
            if (result.isConfirmed) {
              handleDelete(data.id);
            }
          });
        }
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    handleGetAllWorks();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 200,
    },
    {
      title: "Descrição",
      dataIndex: "descricao",
      key: "descricao",
      width: 300,
    },
    {
      title: "Data Início",
      dataIndex: "data_inicio",
      key: "data_inicio",
      render: (data_incio: string) => {
        const dataInicio = new Date(data_incio);
        return dataInicio.toLocaleDateString();
      },
    },
    {
      title: "Data Fim",
      dataIndex: "data_fim",
      key: "data_fim",
      render: (data_fim: string) => {
        const dataFim = new Date(data_fim);
        return dataFim.toLocaleDateString();
      },
    },
    {
      title: "Cliente",
      dataIndex: "cliente",
      key: "cliente",
      width: 500,
    },
    {
      title: "Ações",
      dataIndex: "id",
      key: "id",
      render: (id: number) => (
        <div style={{ display: "flex", gap: "16px" }}>
          <Button
            schemaButton="out-primary"
            sizeButton="md"
            action={() => handleNavigateUpdate(id)}
          >
            Editar
          </Button>
          <Button
            schemaButton="out-danger"
            sizeButton="md"
            action={() => handleShowDelete(id)}
          >
            Excluir
          </Button>
        </div>
      ),
    },
  ];

  const handleLinkUsers = () => {
    setShowModal(true);
  };

  return (
    <>
      <MainContainer>
        <div className="d-flex justify-content-between align-items-center">
          <div className="d-flex align-items-center gap-4">
            <Button
              schemaButton="full-primary"
              sizeButton="md"
              action={() => navigate(ROUTES.NEW_WORK)}
            >
              Nova Obra
            </Button>
            <Button
              schemaButton="full-primary"
              sizeButton="md"
              action={handleLinkUsers}
            >
              Vincular Usuários
            </Button>
          </div>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex align-items-center"
            style={{ gap: "16px" }}
          >
            <CustomInput
              label="Pesquisa"
              type="text"
              sizeInput="default"
              fieldName="search"
              errors={undefined}
              schema="default"
              placeholder="Pesquisar"
              register={register}
              options={{}}
            />
            <Button
              schemaButton="full-primary"
              sizeButton="md"
              type="submit"
              className="mt-4"
            >
              Pesquisar
            </Button>
            <Button
              schemaButton={watch("search") ? "out-danger" : "full-danger"}
              sizeButton="md"
              action={handleCleanForm}
              className="mt-4"
              disabled={watch("search") ? false : true}
            >
              Limpar
            </Button>
          </form>
        </div>
        <Table columns={columns} data={worksData.data} className="mt-3" />
        <div className="d-flex w-100 justify-content-center align-items-center mt-4">
          <ReactPaginate
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            nextClassName={"next-label"}
            previousClassName={"previous-label"}
            onPageChange={(event) => setPage(event.selected)}
            breakLabel="..."
            pageCount={Math.ceil(worksData.total_count / take)}
            previousLabel={<MdOutlineArrowBackIos color="#4B5563" />}
            nextLabel={<MdOutlineArrowForwardIos color="#4B5563" />}
          />
        </div>
      </MainContainer>

      <Modal show={showModal} setShow={setShowModal}>
        <ModalContent
          setShowModal={setShowModal}
          closeModal={handleCloseModal}
        />
      </Modal>
    </>
  );
};

export default WorkList;
