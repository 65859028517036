import React, { FunctionComponent, useEffect, useState } from "react";
import Select from "../../../components/select";
import { CustomLine, FilterLayoutContent, TitleModal } from "./styles";
import Button from "../../../components/button";
import { SubmitHandler, useForm } from "react-hook-form";
import { useInstrumentsService } from "../../../hooks/api/instruments";
import { useGroupsService } from "../../../hooks/api/groups";
import { TypeGraphics } from "../../../shared/enums/layoutContent/typeGraphics.enum";
import { useToast } from "../../../hooks/toast";
import { TypeElement } from "../../../shared/enums/typeContent/typeElement.enum";
import { IQuadros } from "../../../shared/interfaces/quadros/quadros";

interface SelectOptions {
  value: any;
  label: string;
}

interface IFormInput {
  graphic: string;
  element: string;
  group: string;
  instrument: string;
}
interface LayoutContentProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setDataQuadros: React.Dispatch<React.SetStateAction<IQuadros>>;
  dataQuadros: IQuadros;
  content: number;
}

const ModalLayoutContent: FunctionComponent<LayoutContentProps> = ({
  setShow,
  content,
  setDataQuadros,
  dataQuadros,
}) => {
  const { handleSubmit, formState, control } = useForm<IFormInput>();
  const { getByObrasInstrumentos } = useInstrumentsService();
  const { getByObrasGrupos } = useGroupsService();
  const { showSuccessToast } = useToast();

  const [selectValue, setSelectValue] = useState<string>("");
  const [dataInstrumentos, setDataInstrumentos] = useState<SelectOptions[]>([]);
  const [dataGrupos, setDataGrupos] = useState<SelectOptions[]>([]);

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    if (!Array.isArray(dataQuadros.data)) {
      setDataQuadros((prevState) => ({
        ...prevState,
        data: [],
      }));
      return;
    }
    const newData = [...dataQuadros.data];

    newData[content - 1] = {
      ...newData[content - 1],
      tipo_visualizacao: data.graphic as TypeGraphics,
      id_instrumento:
        data.element === TypeElement.INSTRUMENTO
          ? parseInt(data.instrument)
          : null,
      id_grupo:
        data.element === TypeElement.GRUPO ? parseInt(data.group) : null,
    };

    setDataQuadros((prevState) => ({
      ...prevState,
      data: newData,
    }));

    showSuccessToast("Gráfico inserido com sucesso!");
    setShow(false);
  };

  const getDataInstrumentos = async () => {
    await getByObrasInstrumentos().then(({ data }) => {
      let dataInstrumentsMap = data.map((item) => ({
        value: item.id,
        label: item.nome,
      }));
      setDataInstrumentos(dataInstrumentsMap);
    });
  };

  const getDataGrupos = async () => {
    await getByObrasGrupos().then(({ data }) => {
      let dataGroupMap = data.map((item) => ({
        value: item.id,
        label: item.nome,
      }));
      setDataGrupos(dataGroupMap);
    });
  };

  useEffect(() => {
    getDataInstrumentos();
    getDataGrupos();
  }, []);

  const elementOptions: SelectOptions[] = [
    { value: TypeElement.INSTRUMENTO, label: TypeElement.INSTRUMENTO },
    { value: TypeElement.GRUPO, label: TypeElement.GRUPO },
  ];

  const graphicsOptions: SelectOptions[] = [
    { value: TypeGraphics.BARGRAPH, label: "BarGraph" },
    { value: TypeGraphics.TENDENCIA, label: "Tendencias" },
    { value: TypeGraphics.HISTORICO, label: "Históricos" },
  ];

  return (
    <div className="d-block">
      <TitleModal>Selecione a visualização desejada</TitleModal>
      <CustomLine />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FilterLayoutContent>
          <Select
            defaultOption="Selecione um gráfico"
            name="graphic"
            control={control}
            rules={{ required: "O campo é obrigatório" }}
            sizeInput="full"
            validationMessage={
              formState.errors.graphic
                ? formState.errors.graphic.message
                : undefined
            }
            schema={formState.errors.graphic ? "danger" : "default"}
            options={graphicsOptions}
            label="Selecione um modelo de gráfico"
          />

          <Select
            defaultOption="Selecione o elemento"
            name="element"
            control={control}
            rules={{ required: "O campo é obrigatório" }}
            sizeInput="default"
            validationMessage={
              formState.errors.element
                ? formState.errors.element.message
                : undefined
            }
            schema={formState.errors.element ? "danger" : "default"}
            options={elementOptions}
            label="Selecione um elemento"
            setSelectValue={setSelectValue}
          />

          {selectValue !== "" ? (
            selectValue === TypeElement.INSTRUMENTO ? (
              <Select
                defaultOption={"Selecione o Instrumento"}
                name="instrument"
                rules={{ required: "O campo é obrigatório" }}
                sizeInput="default"
                validationMessage={
                  formState.errors.instrument
                    ? formState.errors.instrument.message
                    : undefined
                }
                schema={formState.errors.instrument ? "danger" : "default"}
                control={control}
                options={dataInstrumentos}
                label="Selecione um Instrumento"
              />
            ) : (
              <Select
                defaultOption="Selecione o grupo"
                name="group"
                rules={{ required: "O campo é obrigatório" }}
                sizeInput="default"
                validationMessage={
                  formState.errors.group
                    ? formState.errors.group.message
                    : undefined
                }
                schema={formState.errors.group ? "danger" : "default"}
                control={control}
                options={dataGrupos}
                label="Selecione um grupo"
              />
            )
          ) : null}
        </FilterLayoutContent>
        <div style={{ textAlign: "center", margin: "80px 0" }}>
          <Button sizeButton="md" schemaButton="full-primary">
            Aplicar
          </Button>
        </div>
      </form>
    </div>
  );
};

export default ModalLayoutContent;
