import React, { FunctionComponent, useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Spinner,
  Form,
  InputGroup,
} from "react-bootstrap";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoIosCloseCircleOutline } from "react-icons/io";
import Button from "../../components/button";
import { IoEye, IoEyeOff } from "react-icons/io5";
import { useIntegrationService } from "../../hooks/api/integration";
import { useToast } from "../../hooks/toast";
import MainContainer from "../../components/main-container";

interface ICard {
  slave: number;
  status: "success" | "error" | null;
  testing: boolean;
}

interface TestInstrumentConnectionProps {}

const TestInstrumentConnection: FunctionComponent<
  TestInstrumentConnectionProps
> = () => {
  const { getPortCOM, getTestCommunicationInstrument } =
    useIntegrationService();
  const { showSuccessToast, showErrorToast } = useToast();

  const [cards, setCards] = useState<ICard[]>([]);
  const [indexCard, setIndexCard] = useState<number>(0);
  const [selectedQuantity, setSelectedQuantity] = useState<number>(0);
  const [monitoring, setMonitoring] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [portCom, setPortCom] = useState<string>("");

  async function getPort() {
    setPortCom("Buscando porta COM, aguarde...");
    await getPortCOM()
      .then((response) => {
        setPortCom(response.data.toUpperCase());
      })
      .catch(() => {
        showErrorToast(
          "Não foi possível identificar a porta COM, verifique se o equipamento está conectado ao computador."
        );
        setPortCom("Porta não identificada!");
      });
    setIsRequest(false);
    setIsLoading(false);
  }

  function resetCards() {
    setCards((prevCards) =>
      prevCards.map((card) => ({
        ...card,
        status: null,
        testing: false,
      }))
    );
    setIndexCard(0);
  }

  async function testCommunicationInstrument(index: number) {
    if (monitoring && index < cards.length) {
      const updatedCards = [...cards];
      updatedCards[index].testing = true;
      setCards(updatedCards);

      // showSuccessToast(
      //   `Testando a comunicação do equipamento no endereço ${updatedCards[index].slave}, aguarde...`
      // );

      await getTestCommunicationInstrument(portCom, updatedCards[index].slave)
        .then((response) => {
          updatedCards[index].status = response.data.status;
          updatedCards[index].testing = false;
          setCards(updatedCards);
        })
        .catch(() => {
          updatedCards[index].status = "error";
          updatedCards[index].testing = false;
          setCards(updatedCards);
        });

      setIndexCard((prevIndex) => prevIndex + 1);
    } else if (!monitoring) {
      setIndexCard(cards.length + 1);
    }
    setIsRequest(false);
  }

  useEffect(() => {
    if (monitoring && indexCard < cards.length) {
      setIsRequest(true);
      testCommunicationInstrument(indexCard);
    } else if (indexCard >= cards.length) {
      setMonitoring(false);
      setIndexCard(0);
    }
  }, [indexCard, monitoring]);

  useEffect(() => {
    getPort();
  }, []);

  useEffect(() => {
    setCards([]);
    for (let i = 1; i <= selectedQuantity; i++) {
      setCards((prevCards) => [
        ...prevCards,
        { slave: i, status: null, testing: false },
      ]);
    }
  }, [selectedQuantity]);

  return (
    <MainContainer>
      <Card className="m-5">
        <Card.Body>
          <Container fluid className="my-5">
            <Row>
              <Col>
                <Form.Label>Insira a quantidade de instrumentos</Form.Label>
                <InputGroup className="mb-5">
                  <InputGroup.Text>Instrumentos</InputGroup.Text>
                  <Form.Control
                    value={selectedQuantity}
                    min={0}
                    type="number"
                    disabled={monitoring || isLoading}
                    onChange={(e) =>
                      setSelectedQuantity(parseInt(e.target.value))
                    }
                  />
                </InputGroup>
              </Col>
              <Col>
                <Form.Label>Porta COM identificada</Form.Label>
                <InputGroup className="mb-5">
                  <Form.Control value={portCom} disabled readOnly />
                </InputGroup>
              </Col>
              <Col>
                {portCom.includes("COM") && selectedQuantity > 0 && (
                  <Button
                    className="mt-4"
                    sizeButton="md"
                    disabled={isRequest}
                    icon={
                      monitoring ? (
                        <IoEyeOff style={{ fontSize: 24 }} />
                      ) : (
                        <IoEye style={{ fontSize: 24 }} />
                      )
                    }
                    schemaButton={monitoring ? "full-danger" : "out-primary"}
                    action={() => {
                      if (monitoring) {
                        setMonitoring(false);
                      } else {
                        resetCards();
                        setMonitoring(true);
                      }
                    }}
                  >
                    {monitoring ? "Parar de Testes" : "Iniciar Testes"}
                  </Button>
                )}
              </Col>
            </Row>
            <Row xl={3} md={2} className="my-5">
              {cards.map((card, index) => (
                <Col md={4} key={index} className="mt-2">
                  <Card className="text-center border-light">
                    <Card.Header
                      className={
                        card.status === "success"
                          ? "bg-success text-white"
                          : card.status === "error"
                          ? "bg-danger text-white"
                          : "bg-primary text-white"
                      }
                    >
                      Instrumento {card.slave}
                    </Card.Header>
                    <Card.Body>
                      {card.testing ? (
                        <Spinner animation="border" />
                      ) : card.status === "success" ? (
                        <FaRegCircleCheck
                          className="text-success"
                          style={{ fontSize: "3rem" }}
                        />
                      ) : card.status === "error" ? (
                        <IoIosCloseCircleOutline
                          className="text-danger"
                          style={{ fontSize: "3rem" }}
                        />
                      ) : (
                        <div
                          style={{
                            fontSize: "1.2rem",
                            fontWeight: "bold",
                            color: "#6c757d",
                          }}
                        >
                          Aguardando teste...
                        </div>
                      )}
                    </Card.Body>
                    <Card.Footer
                      className={
                        card.status === "success"
                          ? "bg-success text-white"
                          : card.status === "error"
                          ? "bg-danger text-white"
                          : "bg-primary text-white"
                      }
                    >
                      {card.status === "success"
                        ? "Conectado com sucesso"
                        : card.status === "error"
                        ? "Falha na comunicação"
                        : ""}
                    </Card.Footer>
                  </Card>
                </Col>
              ))}
            </Row>
          </Container>
        </Card.Body>
      </Card>
    </MainContainer>
  );
};

export default TestInstrumentConnection;
