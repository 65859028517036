import styled from "styled-components";

export const ContainerForChart = styled.div`
  display: block;
  height: auto;
  width: auto;
  background: #f9fafb;
  box-shadow: 0px 1px 3px 0px #0000001a;
  padding: 5px;
`;

export const HeaderChart = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
`;

export const IconHeaderChart = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 32px;
`;

export const FullScreenContainer = styled.div`
  padding: 32px;
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const HeaderFullScreen = styled.div`
  padding: 32px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
