import styled from "styled-components";

export const LogoContainer = styled.div`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: start;
    padding-left: ${props => props.theme.padding.lg};
`

export const ActionsContainer = styled.div`
    position: relative;
    height: max-content;
    width: max-content;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: center;
    padding-right: ${props => props.theme.padding.lg};
`