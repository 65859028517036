import React, {
  FunctionComponent,
  useEffect,
  useState,
  useCallback,
} from "react";
import { SelectContainer, SelectLabelButton } from "./styles";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

type TItem = {
  label: string;
  value: any;
};

interface SelectArrowsProps {
  items: TItem[];
  defaultLabel: string;
  onChange: (value: any) => void;
  hasSideArrow?: boolean;
}

const SelectArrows: FunctionComponent<SelectArrowsProps> = ({
  defaultLabel,
  onChange,
  items,
}) => {
  const itemsLength = items.length - 1;
  const [indexSelected, setIndexSelected] = useState(0);
  const [itemSelected, setItemSelected] = useState<TItem>(items[indexSelected]);

  const handleChange = useCallback((index: number) => {
    setIndexSelected(index);
  }, []);

  useEffect(() => {
    setItemSelected(items[indexSelected]);
    onChange(items[indexSelected]?.value);
  }, [indexSelected, itemSelected, items]);

  return (
    <SelectContainer>
      <SelectLabelButton>
        <FaChevronLeft
          onClick={() => {
            if (indexSelected > 0) {
              handleChange(indexSelected - 1);
            }
          }}
        />
        {itemSelected?.label}
        <FaChevronRight
          onClick={() => {
            if (indexSelected < itemsLength) {
              handleChange(indexSelected + 1);
            }
          }}
        />
      </SelectLabelButton>
    </SelectContainer>
  );
};

export default SelectArrows;
