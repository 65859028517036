import React, { FunctionComponent, useEffect, useState } from "react";
import { GreyContainer } from "../../../components/grey-container/styles";
import { Col, Container, Row } from "react-bootstrap";
import { LuPlusCircle } from "react-icons/lu";
import { TextGreyContainer } from "../../../components/text-grey-container/styles";
import { AddViewContainer } from "./styles";
import { TypeGraphics } from "../../../shared/enums/layoutContent/typeGraphics.enum";
import TemperatureChart from "../../../components/charts/temperature-chart";
import CustomLineChart from "../../../components/charts/custom-line-chart";
import CustomAreaChart from "../../../components/charts/custom-area-chart";
import ChartContainer from "../../../components/chart-container";
import { TypeElement } from "../../../shared/enums/typeContent/typeElement.enum";
import { IQuadros } from "../../../shared/interfaces/quadros/quadros";

interface FormatsProps {
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  setContent: React.Dispatch<React.SetStateAction<number>>;
  dataQuadros: IQuadros;
}

interface dataGraphic {
  show: boolean;
  graphic: TypeGraphics | null;
  id: number | null;
  type: TypeElement | null;
}

const Single: FunctionComponent<FormatsProps> = ({
  setShow,
  setContent,
  dataQuadros,
}) => {
  const [data, setData] = useState<dataGraphic>({
    show: false,
    graphic: null,
    id: null,
    type: null,
  });

  useEffect(() => {
    if (dataQuadros && dataQuadros.data && dataQuadros.data.length > 0) {
      if (dataQuadros.data[0]) {
        setData({
          show: true,
          graphic: dataQuadros.data[0].tipo_visualizacao,
          id: dataQuadros.data[0].id_grupo
            ? dataQuadros.data[0].id_grupo!
            : dataQuadros.data[0].id_instrumento!,
          type: dataQuadros.data[0].id_grupo
            ? TypeElement.GRUPO
            : TypeElement.INSTRUMENTO,
        });
      }
    }
  }, [dataQuadros]);

  return (
    <GreyContainer>
      <AddViewContainer
        onClick={() => {
          setShow(true);
          setContent(1);
        }}
      >
        <Col lg={12} md={12}>
          {data.show ? (
            <ChartContainer title="GRÁFICO 01" type={data.graphic!}>
              {(() => {
                switch (data.graphic) {
                  case TypeGraphics.BARGRAPH:
                    return <TemperatureChart id={data.id!} type={data.type!} />;
                  case TypeGraphics.HISTORICO:
                    return <CustomAreaChart id={data.id!} type={data.type!} />;
                  case TypeGraphics.TENDENCIA:
                    return <CustomLineChart id={data.id!} type={data.type!} />;
                  default:
                    return <h1>Gráfico não cadastrado!</h1>;
                }
              })()}
            </ChartContainer>
          ) : (
            <>
              <LuPlusCircle style={{ fontSize: "64px" }} color="#3758F9" />
              <TextGreyContainer>
                Adicione a visualização de um instrumento ou grupo.
              </TextGreyContainer>
            </>
          )}
        </Col>
      </AddViewContainer>
    </GreyContainer>
  );
};

const Half: FunctionComponent<FormatsProps> = ({
  setShow,
  setContent,
  dataQuadros,
}) => {
  const [data, setData] = useState<dataGraphic>({
    show: false,
    graphic: null,
    id: null,
    type: null,
  });

  const [data2, setData2] = useState<dataGraphic>({
    show: false,
    graphic: null,
    id: null,
    type: null,
  });
  useEffect(() => {
    if (dataQuadros && dataQuadros.data && dataQuadros.data.length > 0) {
      if (dataQuadros.data[0]) {
        setData({
          show: true,
          graphic: dataQuadros.data[0].tipo_visualizacao,
          id: dataQuadros.data[0].id_grupo
            ? dataQuadros.data[0].id_grupo!
            : dataQuadros.data[0].id_instrumento!,
          type: dataQuadros.data[0].id_grupo
            ? TypeElement.GRUPO
            : TypeElement.INSTRUMENTO,
        });
      }
      if (dataQuadros.data[1]) {
        setData2({
          show: true,
          graphic: dataQuadros.data[1].tipo_visualizacao,
          id: dataQuadros.data[1].id_grupo
            ? dataQuadros.data[1].id_grupo!
            : dataQuadros.data[1].id_instrumento!,
          type: dataQuadros.data[1].id_grupo
            ? TypeElement.GRUPO
            : TypeElement.INSTRUMENTO,
        });
      }
    }
  }, [dataQuadros]);

  return (
    <GreyContainer>
      <Container fluid style={{ height: "100%" }}>
        <Row className="my-5" style={{ height: "100%" }}>
          <Col lg={6} md={12}>
            <AddViewContainer
              onClick={() => {
                setShow(true);
                setContent(1);
              }}
            >
              {data.show ? (
                <ChartContainer title="GRÁFICO 01" type={data.graphic!}>
                  {(() => {
                    switch (data.graphic) {
                      case TypeGraphics.BARGRAPH:
                        return (
                          <TemperatureChart id={data.id!} type={data.type!} />
                        );
                      case TypeGraphics.HISTORICO:
                        return (
                          <CustomAreaChart id={data.id!} type={data.type!} />
                        );
                      case TypeGraphics.TENDENCIA:
                        return (
                          <CustomLineChart id={data.id!} type={data.type!} />
                        );
                      default:
                        return <h1>Gráfico não cadastrado!</h1>;
                    }
                  })()}
                </ChartContainer>
              ) : (
                <>
                  <LuPlusCircle style={{ fontSize: "64px" }} color="#3758F9" />
                  <TextGreyContainer>
                    Adicione a visualização de um instrumento ou grupo.
                  </TextGreyContainer>
                </>
              )}
            </AddViewContainer>
          </Col>
          <Col lg={6} md={12}>
            <AddViewContainer
              onClick={() => {
                setShow(true);
                setContent(2);
              }}
            >
              {data2.show ? (
                <ChartContainer title="GRÁFICO 02" type={data2.graphic!}>
                  {(() => {
                    switch (data2.graphic) {
                      case TypeGraphics.BARGRAPH:
                        return (
                          <TemperatureChart id={data2.id!} type={data2.type!} />
                        );
                      case TypeGraphics.HISTORICO:
                        return (
                          <CustomAreaChart id={data2.id!} type={data2.type!} />
                        );
                      case TypeGraphics.TENDENCIA:
                        return (
                          <CustomLineChart id={data2.id!} type={data2.type!} />
                        );
                      default:
                        return <h1>Gráfico não cadastrado!</h1>;
                    }
                  })()}
                </ChartContainer>
              ) : (
                <>
                  <LuPlusCircle style={{ fontSize: "64px" }} color="#3758F9" />
                  <TextGreyContainer>
                    Adicione a visualização de um instrumento ou grupo.
                  </TextGreyContainer>
                </>
              )}
            </AddViewContainer>
          </Col>
        </Row>
      </Container>
    </GreyContainer>
  );
};

const Quarter: FunctionComponent<FormatsProps> = ({
  setShow,
  setContent,
  dataQuadros,
}) => {
  const [data, setData] = useState<dataGraphic>({
    show: false,
    graphic: null,
    id: null,
    type: null,
  });

  const [data2, setData2] = useState<dataGraphic>({
    show: false,
    graphic: null,
    id: null,
    type: null,
  });

  const [data3, setData3] = useState<dataGraphic>({
    show: false,
    graphic: null,
    id: null,
    type: null,
  });

  const [data4, setData4] = useState<dataGraphic>({
    show: false,
    graphic: null,
    id: null,
    type: null,
  });

  useEffect(() => {
    if (dataQuadros && dataQuadros.data && dataQuadros.data.length > 0) {
      if (dataQuadros.data[0]) {
        setData({
          show: true,
          graphic: dataQuadros.data[0].tipo_visualizacao,
          id: dataQuadros.data[0].id_grupo
            ? dataQuadros.data[0].id_grupo!
            : dataQuadros.data[0].id_instrumento!,
          type: dataQuadros.data[0].id_grupo
            ? TypeElement.GRUPO
            : TypeElement.INSTRUMENTO,
        });
      }
      if (dataQuadros.data[1]) {
        setData2({
          show: true,
          graphic: dataQuadros.data[1].tipo_visualizacao,
          id: dataQuadros.data[1].id_grupo
            ? dataQuadros.data[1].id_grupo!
            : dataQuadros.data[1].id_instrumento!,
          type: dataQuadros.data[1].id_grupo
            ? TypeElement.GRUPO
            : TypeElement.INSTRUMENTO,
        });
      }
      if (dataQuadros.data[2]) {
        setData3({
          show: true,
          graphic: dataQuadros.data[2].tipo_visualizacao,
          id: dataQuadros.data[2].id_grupo
            ? dataQuadros.data[2].id_grupo!
            : dataQuadros.data[2].id_instrumento!,
          type: dataQuadros.data[2].id_grupo
            ? TypeElement.GRUPO
            : TypeElement.INSTRUMENTO,
        });
      }
      if (dataQuadros.data[3]) {
        setData4({
          show: true,
          graphic: dataQuadros.data[3].tipo_visualizacao,
          id: dataQuadros.data[3].id_grupo
            ? dataQuadros.data[3].id_grupo!
            : dataQuadros.data[3].id_instrumento!,
          type: dataQuadros.data[3].id_grupo
            ? TypeElement.GRUPO
            : TypeElement.INSTRUMENTO,
        });
      }
    }
  }, [dataQuadros]);

  return (
    <GreyContainer>
      <Container fluid style={{ height: "100%" }}>
        <Row style={{ height: "50%" }}>
          <Col lg={6} md={12}>
            <AddViewContainer
              onClick={() => {
                setShow(true);
                setContent(1);
              }}
            >
              {data.show ? (
                <ChartContainer title="GRÁFICO 01" type={data.graphic!}>
                  {(() => {
                    switch (data.graphic) {
                      case TypeGraphics.BARGRAPH:
                        return (
                          <TemperatureChart id={data.id!} type={data.type!} />
                        );
                      case TypeGraphics.HISTORICO:
                        return (
                          <CustomAreaChart id={data.id!} type={data.type!} />
                        );
                      case TypeGraphics.TENDENCIA:
                        return (
                          <CustomLineChart id={data.id!} type={data.type!} />
                        );
                      default:
                        return <h1>Gráfico não cadastrado!</h1>;
                    }
                  })()}
                </ChartContainer>
              ) : (
                <>
                  <LuPlusCircle style={{ fontSize: "64px" }} color="#3758F9" />
                  <TextGreyContainer>
                    Adicione a visualização de um instrumento ou grupo.
                  </TextGreyContainer>
                </>
              )}
            </AddViewContainer>
          </Col>
          <Col lg={6} md={12}>
            <AddViewContainer
              onClick={() => {
                setShow(true);
                setContent(2);
              }}
            >
              {data2.show ? (
                <ChartContainer title="GRÁFICO 02" type={data2.graphic!}>
                  {(() => {
                    switch (data2.graphic) {
                      case TypeGraphics.BARGRAPH:
                        return (
                          <TemperatureChart id={data2.id!} type={data2.type!} />
                        );
                      case TypeGraphics.HISTORICO:
                        return (
                          <CustomAreaChart id={data2.id!} type={data2.type!} />
                        );
                      case TypeGraphics.TENDENCIA:
                        return (
                          <CustomLineChart id={data2.id!} type={data2.type!} />
                        );
                      default:
                        return <h1>Gráfico não cadastrado!</h1>;
                    }
                  })()}
                </ChartContainer>
              ) : (
                <>
                  <LuPlusCircle style={{ fontSize: "64px" }} color="#3758F9" />
                  <TextGreyContainer>
                    Adicione a visualização de um instrumento ou grupo.
                  </TextGreyContainer>
                </>
              )}
            </AddViewContainer>
          </Col>
        </Row>
        <Row style={{ height: "50%", marginTop: "5px" }}>
          <Col lg={6} md={12}>
            <AddViewContainer
              onClick={() => {
                setShow(true);
                setContent(3);
              }}
            >
              {data3.show ? (
                <ChartContainer title="GRÁFICO 03" type={data3.graphic!}>
                  {(() => {
                    switch (data3.graphic) {
                      case TypeGraphics.BARGRAPH:
                        return (
                          <TemperatureChart id={data3.id!} type={data3.type!} />
                        );
                      case TypeGraphics.HISTORICO:
                        return (
                          <CustomAreaChart id={data3.id!} type={data3.type!} />
                        );
                      case TypeGraphics.TENDENCIA:
                        return (
                          <CustomLineChart id={data3.id!} type={data3.type!} />
                        );
                      default:
                        return <h1>Gráfico não cadastrado!</h1>;
                    }
                  })()}
                </ChartContainer>
              ) : (
                <>
                  <LuPlusCircle style={{ fontSize: "64px" }} color="#3758F9" />
                  <TextGreyContainer>
                    Adicione a visualização de um instrumento ou grupo.
                  </TextGreyContainer>
                </>
              )}
            </AddViewContainer>
          </Col>
          <Col lg={6} md={12}>
            <AddViewContainer
              onClick={() => {
                setShow(true);
                setContent(4);
              }}
            >
              {data4.show ? (
                <ChartContainer title="GRÁFICO 04" type={data4.graphic!}>
                  {(() => {
                    switch (data4.graphic) {
                      case TypeGraphics.BARGRAPH:
                        return (
                          <TemperatureChart id={data4.id!} type={data4.type!} />
                        );
                      case TypeGraphics.HISTORICO:
                        return (
                          <CustomAreaChart id={data4.id!} type={data4.type!} />
                        );
                      case TypeGraphics.TENDENCIA:
                        return (
                          <CustomLineChart id={data4.id!} type={data4.type!} />
                        );
                      default:
                        return <h1>Gráfico não cadastrado!</h1>;
                    }
                  })()}
                </ChartContainer>
              ) : (
                <>
                  <LuPlusCircle style={{ fontSize: "64px" }} color="#3758F9" />
                  <TextGreyContainer>
                    Adicione a visualização de um instrumento ou grupo.
                  </TextGreyContainer>
                </>
              )}
            </AddViewContainer>
          </Col>
        </Row>
      </Container>
    </GreyContainer>
  );
};

export { Single, Half, Quarter };
