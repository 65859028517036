import { useApi } from '../';

import { ICreateObras } from '../../../shared/interfaces/works/works.create';
import { IResultApi } from '../../../shared/interfaces/api/api.response';
import { IWork, IWorks } from '../../../shared/interfaces/works/works';
import { IPaginateQuery } from '../../../shared/interfaces/api/api.paginate.request';

export const useWorksService = () => {
  const { post, put, get, del } = useApi();

  const getAll = async ({take, page, search}: IPaginateQuery) => {
    return get<IWorks>('/obras/all', {params: {take, page, search}});
  }

  const getAllForDropdown = async () => {
    return get<IWork[]>('/obras/all-for-dropdown');
  }

  const getOne = async (id: number) => {
    return get<IWork>(`/obras/get-by-id/${id}`);
  }

  const create = async (body: ICreateObras) => {
    return post<IResultApi>('/obras/create', body)
  };

  const update = async (body: ICreateObras, id: number) => {
    return put<IResultApi>(`/obras/update/${id}`, body)
  };

  const deleteOne = async (id: number) => {
    return del<IResultApi>(`/obras/delete/${id}`)
  };

  return {
    create,
    getAll,
    getOne,
    getAllForDropdown,
    update,
    deleteOne
  };
};