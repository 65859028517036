import React, { FunctionComponent } from "react";
import {
  LabelForName,
  LabelForRole,
  NameBox,
  PhotoBox,
  ProfileDropdownBox,
} from "./styles";
import { IoSettingsOutline } from "react-icons/io5";
import { useAuthContext } from "../../context/auth-context";

interface ProfileDropdownProps {
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const ProfileDropdown: FunctionComponent<ProfileDropdownProps> = ({
  setShowDropdown,
  showDropdown,
}) => {
  const { user } = useAuthContext();
  return (
    <ProfileDropdownBox>
      <NameBox>
        <LabelForName>{user?.name}</LabelForName>
        <LabelForRole>{user?.type}</LabelForRole>
        <LabelForRole>{user?.obra?.descricao}</LabelForRole>
      </NameBox>
      <PhotoBox>
        <IoSettingsOutline
          fontSize={24}
          onClick={() => setShowDropdown(!showDropdown)}
          style={{ cursor: "pointer" }}
        />
      </PhotoBox>
    </ProfileDropdownBox>
  );
};

export default ProfileDropdown;
