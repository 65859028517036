import styled from "styled-components";

export const GreyContainer = styled.div`
  margin-top: 2rem;
  height: auto;
  background-color: #f2f2f2;
  border-radius: 2px;
  padding: 10px;
  border: 1px solid #e2e2e2;
`;
