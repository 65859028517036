import { createGlobalStyle } from "styled-components";
import "../fonts/Inter/Inter.ttf";

export default createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: 0;
    font-family: 'Open Sans', sans-serif;
  }
  body {
    overflow-x: hidden;
    padding-bottom: 80px;
  }
`;
