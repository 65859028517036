import React, { FunctionComponent } from "react";
import MainContainer from "../../../components/main-container";
import ContainerFormsUser from "../../../components/forms-user-container";

interface NewUserProps {}

const NewUser: FunctionComponent<NewUserProps> = () => {
  return (
    <MainContainer>
      <section className="d-flex justify-content-center align-items-center mb-3 mt-3">
        <ContainerFormsUser editPerfil={false}></ContainerFormsUser>
      </section>
    </MainContainer>
  );
};

export default NewUser;
