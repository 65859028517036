import styled from "styled-components";

export const FilterLayoutContent = styled.div`
  display: flex;
  justify-content: center;
  gap: 96px;
  margin-top: 64px;
  height: auto;
`;

export const TitleModal = styled.h1`
  color: ${(props) => props.theme.colors.blueSteel};
  font-size: ${(props) => props.theme.font.size.lg};
  margin-bottom: 16px;
  text-align: center;
`;

export const CustomLine = styled.hr`
  display: block;
  margin-right: auto;
  margin-left: auto;
  width: 80px;
  height: 6px;
  border-radius: 4px;
  background-color: #3758f9;
`;

export const ContainerForPointsUsers = styled.div`
  border: 1px solid #d1d5db;
  margin-right: 30px;
  padding: 8px 0;
  border-radius: 8px;
  background: #f3f4f6;
  width: auto;
  height: auto;
  max-height: 450px;
  max-width: 500px;
  overflow-y: auto;
`;
