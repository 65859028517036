import { Link } from "react-router-dom";
import styled from "styled-components";

export const DropdownContainer = styled.div`
  display: block;
  background-color: #f9fafb;
  border: 1px solid #e5e7eb;
  position: absolute;
  top: 52px;
  right: 0;
  box-shadow: 3px -1px 11px -6px rgba(0, 0, 0, 0.75);
  width: 180px;
  height: auto;
  color: #000;
  z-index: 2000;
`;

export const CustomLink = styled(Link)`
  padding: 16px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
`;

export const CustomButton = styled.span`
  padding: 16px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
`;

export const LogoutButton = styled.span`
  padding: 16px;
  display: block;
  width: 100%;
  text-decoration: none;
  color: #000;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  cursor: pointer;
`;
