import React, { FunctionComponent } from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useUsersService } from "../../hooks/api/user";
import { useAuthContext } from "../../context/auth-context";
import urlLogoBig from "../../assets/logo-big.png";
import Link from "../../components/link";
import { Button } from "react-bootstrap";

interface UnauthorizedProps {}

const Unauthorized: FunctionComponent<UnauthorizedProps> = () => {
  const { signOut } = useAuthContext();
  const { putLogout } = useUsersService();
  const navigate = useNavigate();

  const handleClickBackLogin = (e: any) => {
    navigate(ROUTES.LOGIN);
  };
  return (
    <>
      <div
        className="d-flex align-items-center justify-content-center"
        style={{ height: "100vh", backgroundColor: "#f8f9fa" }}
      >
        <div className="text-center">
          <img src={urlLogoBig} alt="logo" style={{ width: "250px" }} />
          <h2 className="mt-4">
            Você não tem permissão para acessar esta pagina ou
            <br /> foi desconectado
          </h2>
          <p className="lead mt-4">Faça login novamente</p>
          <Button onClick={handleClickBackLogin} variant="outline-dark">
            Voltar a página de login
          </Button>
        </div>
      </div>
    </>
  );
};

export default Unauthorized;
