import styled from "styled-components";
import { EPointStatus } from "../../pages/dashboard/enum/points.enum";

export const InstrumentBox = styled.div`
  min-height: 300px;
  cursor: pointer;
  background: #f3f4f6;
  border: 1px solid #f3f4f6;
  box-shadow: 0px 1px 3px 0px #0000001a;
  border-radius: ${(props) => props.theme.border.radius};
  padding: ${(props) => props.theme.padding.md};
  margin-right: ${(props) => props.theme.padding.sm};
`;

export const TitleInstrument = styled.h1`
  font-size: 20px;
  color: #505d68;
  font-weight: ${(props) => props.theme.font.weight.strong};
  text-align: left;
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const PointsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
`;

export const PointBox = styled.div`
  width: auto;
  height: auto;
  position: relative;
`;

export const PointTemperature = styled.div<{
  status: EPointStatus;
  fontSize?: number;
}>`
  width: 100%;
  border-radius: 4px;
  padding: ${(props) => props.theme.padding.sm};
  font-size: ${(props) => props.fontSize || 16}px;
  font-weight: ${(props) => props.theme.font.weight.strong};
  text-align: center;

  ${(props) => {
    switch (props.status) {
      case "alert":
        return `
                color: #F23030;
                background: #fff;
                border: 1px solid #F23030;
            `;
      case "ok":
        return `
    background: #212B361A;
    color: #4B5563;
`;
      case "cold":
        return `
                border: 1px solid #93C5FD;
                background: #D0F0FD;
                color: #0B76B7;
            `;
      case "hot":
        return `
                border: 1px solid #FCA5A5;
                background: #FEEBEB;
                color: #E10E0E;
            `;

      default:
        return `
        border: 1px solid #41454b;
        background: #4B5563;
        color: #fff;
    `;
    }
  }}
`;

export const BoxForStatusIcon = styled.div`
  position: absolute;
  top: -16px;
  right: -8px;
  padding: 4px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PointLabel = styled.p<{ status: EPointStatus }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  text-align: left;
  ${(props) => {
    switch (props.status) {
      case "alert":
        return `
                
        color: #E10E0E;
            `;
      case "ok":
        return `
    color: #4B5563;
`;
      case "cold":
        return `
                
                color: #0B76B7;
            `;
      case "hot":
        return `
                
                color: #E10E0E;
            `;

      default:
        return `
      
        color: #fff;
    `;
    }
  }}
  margin-top: 4px;
`;
