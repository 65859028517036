import styled from "styled-components";

export const FilterContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.padding.md};
  margin-bottom: ${(props) => props.theme.padding.md};
  justify-content: space-between;
`;

export const Line = styled.hr`
  opacity: 0.5;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  gap: ${(props) => props.theme.padding.md};
  margin-top: ${(props) => props.theme.padding.md};
`;

export const BoxUpload = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 80%;
`;

export const TextForUpload = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #4b5563;
`;

export const BoxForNewScreen = styled.div`
  margin-top: 20px;
  text-align: right;
`;
