import { FunctionComponent, PropsWithChildren, ReactNode } from "react";
import { StyledButton, TSchemaButton, TSizeButton } from "./styles";

interface ButtonProps {
  action?: () => void;
  icon?: ReactNode;
  sizeButton: TSizeButton;
  schemaButton: TSchemaButton;
  disabled?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
}

const Button: FunctionComponent<PropsWithChildren<ButtonProps>> = ({
  children,
  action,
  icon,
  sizeButton,
  schemaButton,
  disabled,
  className,
  type,
}) => {
  return (
    <StyledButton
      onClick={action}
      sizeButton={sizeButton}
      schemaButton={schemaButton}
      disabled={disabled}
      className={className}
      type={type}
    >
      {icon && icon}
      {children}
    </StyledButton>
  );
};

export default Button;
