import React, { FunctionComponent } from "react";
import { RadioContainer, HiddenRadio, StyledRadio, Text } from "./styles";
import { FaCircle } from "react-icons/fa";
import { statusTermopar } from "../../shared/enums/termopar/statusTermopar.enum";

interface RadioProps {
  text: string;
  value: number;
  type: "FULL" | "OUTLINE";
  status?: statusTermopar;
  setSelectedTermopar: React.Dispatch<React.SetStateAction<number>>;
  checked: boolean;
  selected: boolean;
}

const Radio: FunctionComponent<RadioProps> = ({
  text,
  value,
  type,
  setSelectedTermopar,
  checked,
  selected,
}) => {
  function handleRadioChange() {
    setSelectedTermopar(value);
  }

  return (
    <RadioContainer
      checked={checked}
      onClick={() => selected && handleRadioChange()}
      type={type}
    >
      <HiddenRadio onChange={handleRadioChange} checked={checked} />
      {selected && (
        <StyledRadio checked={checked} type={type}>
          {checked && <FaCircle color={type === "FULL" ? "#fff" : "#000"} />}
        </StyledRadio>
      )}
      <Text checked={checked} type={type}>
        {" "}
        {text}{" "}
      </Text>
    </RadioContainer>
  );
};
export default Radio;
