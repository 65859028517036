import styled from "styled-components";

export const BreadcrumbContainer = styled.div`
    margin-top: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 30vw;
    margin-right: auto;
    margin-left: auto;
    text-align: center;
`