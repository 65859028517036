export const lineColors = {
  line1: "#3498db",
  line2: "#e74c3c",
  line3: "#2ecc71",
  line4: "#9b59b6",
  line5: "#f1c40f",
  line6: "#95a5a6",
  line7: "#66d9ef",
  line8: "#e67e73",
  line9: "#8e44ad",
  line10: "#2196f3",
  line11: "#ffb300",
  line12: "#2980b9",
  line13: "#e6d8c3",
  line14: "#1abc9c",
  line15: "#7f8c8d",
  line16: "#f39c12",
  line17: "#16a085",
  line18: "#d35400",
  line19: "#2c3e50",
  line20: "#8dd3c7",
  line21: "#afb9ca",
  line22: "#45b3fa",
  line23: "#e5e5e5",
  line24: "#434a54",
  line25: "#9a59b5",
  line26: "#a3a7ae",
  line27: "#4a85a7",
  line28: "#ffd700",
  line29: "#9b2e2e",
  line30: "#00b5e2",
};
