export enum StatusAlarmes {
  SILENCIADO = "SILENCIADO",
  ATIVO = "ATIVO",
  RESOLVIDO = "RESOLVIDO",
}

export enum FilterAlarmes {
  GRUPO = "GRUPO",
  INSTRUMENTO = "INSTRUMENTO",
}
