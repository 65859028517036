import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useEffect,
} from "react";
import { HeaderModal, ModalContainer, ModalContent, Overlay } from "./styled";
import { IoMdClose } from "react-icons/io";

interface ModalProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: FunctionComponent<PropsWithChildren<ModalProps>> = ({
  show,
  setShow,
  children,
}) => {
  const modalRef = useRef(null);

  useEffect(() => {
    if (show) {
      window.document.body.style.overflow = "hidden";
    } else {
      window.document.body.style.overflow = "auto";
    }
  }, [show]);

  const handleClose = () => {
    setShow(false);
  };

  if (show)
    return (
      <Overlay>
        <ModalContainer ref={modalRef}>
          <HeaderModal>
            <IoMdClose
              fontSize={32}
              color="#4B5563"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </HeaderModal>
          <ModalContent>{children}</ModalContent>
        </ModalContainer>
      </Overlay>
    );
  else return null;
};

export default Modal;
