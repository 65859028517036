import React, { createContext, useContext, useState, ReactNode } from 'react';

interface AppContextType {
    selectedChart: ReactNode;
    setSelectedChart: React.Dispatch<React.SetStateAction<ReactNode>>;
    selectedChartTitle: string | null;
    setSelectedChartTitle: React.Dispatch<React.SetStateAction<string | null>>;
}

const AppContext = createContext<AppContextType | undefined>(undefined);

interface AppProviderProps {
    children: ReactNode;
}

function AppProvider({ children }: AppProviderProps) {
    const [selectedChart, setSelectedChart] = useState<ReactNode | null>(null);
    const [selectedChartTitle, setSelectedChartTitle] = useState<string | null>(null);
    return (
        <AppContext.Provider value={{ selectedChart, setSelectedChart, selectedChartTitle, setSelectedChartTitle }}>
            {children}
        </AppContext.Provider>
    );
}

function useAppContext(): AppContextType {
    const context = useContext(AppContext);
    if (!context) {
        throw new Error('useApp deve ser usado dentro de um AppProvider');
    }
    return context;
}

export { AppProvider, useAppContext, AppContext }