export const ROUTES = {
  LOGIN: "/",
  LOGOUT: "/logout",
  UNAUTHORIZED: "/unauthorized",
  DASHBOARD: "/dashboard",
  FORGOT_PASSWORD: "/esqueci-minha-senha",
  RECOVER_PASSWORD: "/recuperar-senha",
  VISUALIZATION: "/visualizacao",
  VISUALIZATION_FULL: "/visualizacao/tela-cheia",
  SELECT_LAYOUT: "/selecionar-layout",
  ALARM: "/alarme",
  SET_POINTS: "/definir-pontos",
  GROUPS: "/grupos",
  UPDATE_GROUP: "/grupos/atualizar",
  NEW_GROUP: "/grupos/novo",
  INSTRUMENTS: "/instrumentos",
  NEW_INSTRUMENT: "/instrumentos/novo",
  UPDATE_INSTRUMENT: "/instrumentos/atualizar",
  USERS: "/usuarios",
  NEW_USER: "/usuarios/novo",
  UPDATE_USER: "/usuarios/atualizar",
  WORKS: "/obras",
  NEW_WORK: "/obras/novo",
  UPDATE_WORK: "/obras/atualizar",
  TESTE_INSTRUMENT: "/teste-instrumento",
};
