import { useApi } from "../";
import { IResultApi } from "../../../shared/interfaces/api/api.response";
import { IPaginateQuery } from "../../../shared/interfaces/api/api.paginate.request";
import { IAlarmesRequestBody } from "../../../shared/interfaces/alarmes/alarmes";
import { StatusAlarmes } from "../../../shared/enums/alarmes/statusAlarmes.enum";
import { statusTermopar } from "../../../shared/enums/termopar/statusTermopar.enum";

export const useAlarmesService = () => {
  const { post, put, get } = useApi();

  const getAll = async ({ take, page }: IPaginateQuery) => {
    return get<IAlarmesRequestBody>("/alarmes/all", {
      params: { take, page },
    });
  };

  const getAllByFilter = async (
    filter: string,
    id: number,
    { take, page }: IPaginateQuery
  ) => {
    return get<IAlarmesRequestBody>(`/alarmes/all/${filter}/${id}`, {
      params: { take, page },
    });
  };

  const updateStatus = async (
    id: number,
    data: {
      id_termopar?: number;
      status_termopar?: statusTermopar;
      status_alarme: string;
    }
  ) => {
    return put<IResultApi>(`/alarmes/updateStatus/${id}`, data);
  };

  const createAlarmeHistorico = async (data: {
    id_alarme: number;
    status: StatusAlarmes;
  }) => {
    return post<IResultApi>(`/alarmes-historico/create`, data);
  };

  return {
    getAll,
    getAllByFilter,
    updateStatus,
    createAlarmeHistorico,
  };
};
