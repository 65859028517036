import React from "react";
import toast from "react-hot-toast";
import { FaCircleCheck } from "react-icons/fa6";
import { GoAlertFill } from "react-icons/go";

export const useToast = () => {
  const showErrorToast = (errorMessage: string) => {
    toast.error(errorMessage, {
      duration: 4000,
      position: "top-center",
      style: {},
      className: "",
      icon: <GoAlertFill color="#B91C1C" />,
      iconTheme: {
        primary: "#B91C1C",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  const showSuccessToast = (successMessage: string) => {
    toast.success(successMessage, {
      duration: 4000,
      position: "top-center",
      style: {},
      className: "",
      icon: <FaCircleCheck color="#008634" />,
      iconTheme: {
        primary: "#008634",
        secondary: "#fff",
      },
      ariaProps: {
        role: "status",
        "aria-live": "polite",
      },
    });
  };

  return { showErrorToast, showSuccessToast };
};
