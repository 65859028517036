import React, { FunctionComponent, useState } from "react";
import CustomInput from "../../components/input";
import { ButtonForSubmit } from "../../components/input/styles";
import { FormContainer, HrLogin, ImgLogo, LoginContainer, TitleLogin } from "../login/styles";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import urlLogoBig from '../../assets/logo-big.png';
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { SubmitHandler, useForm } from "react-hook-form";

interface RecoverPasswordProps {
    
}

interface IFormInput {
    password: string
    passwordConfirm: string
}
 
const RecoverPassword: FunctionComponent<RecoverPasswordProps> = () => {

    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
    const { register, handleSubmit, formState } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = (data) => {
           alert(JSON.stringify(data))
    }

    return (
        <LoginContainer>
        <ImgLogo src={urlLogoBig} alt="logo"/>
        <FormContainer>
            <TitleLogin>Recuperar senha</TitleLogin>
            <HrLogin />
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomInput
                    label="Senha"
                    type={showPassword ? "text" : "password"} 
                    sizeInput="full"
                    fieldName="password"
                    errors={formState.errors.password}
                    schema={formState.errors.password ? "danger" : "default"}
                    register={register}
                    options={{required: true}} 
                    icon={showPassword ? <AiOutlineEyeInvisible onClick={() => setShowPassword(false)} /> : <AiOutlineEye onClick={() => setShowPassword(true)} />}
                />
                <CustomInput 
                    label="Confirmar Senha"
                    sizeInput="full"
                    fieldName="passwordConfirm"
                    errors={formState.errors.passwordConfirm}
                    schema={formState.errors.passwordConfirm ? "danger" : "default"}
                    type={showPasswordConfirm ? "text" : "password"} 
                    register={register}
                    options={{required: true}}
                    icon={showPasswordConfirm ? <AiOutlineEyeInvisible onClick={() => setShowPasswordConfirm(false)} /> : <AiOutlineEye onClick={() => setShowPasswordConfirm(true)} />} 
                />
                <ButtonForSubmit type="submit" value="Redefinir senha" />
            </form>
            <HrLogin style={{margin: '40px 0'}}/>
            <Link to={ROUTES.LOGIN} style={{color: '#2563EB', textDecoration: 'none'}}>Voltar</Link>
        </FormContainer>
    </LoginContainer>
    );
}
 
export default RecoverPassword;