import React, { FunctionComponent } from "react";

interface SplitSquareIconProps {
    width: string;
    height: string;
    color: string;
}

export const SplitSquareIcon: FunctionComponent<SplitSquareIconProps> = ({width, height, color}) => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 26" fill="none">
            <rect width="16" height="12" fill={color}/>
            <rect x="18" width="16" height="12" fill={color}/>
            <rect y="15" width="16" height="12" fill={color}/>
            <rect x="18" y="15" width="16" height="12" fill={color}/>
        </svg>
    );
}