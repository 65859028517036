import styled from "styled-components";

export const ContainerForNewUser = styled.div`
    display: block;
    width: 812px;
    background: #F9FAFB;
    box-shadow: 0px 1px 3px 0px #0000001A;
    padding: 16px;
`

export const CustomInput = styled.input`
    max-width: 360px;   
    padding: 12px;
    font-size: 16px;
    border-radius: 4px;
    border: 1px solid #DFE4EA;
    color: #4B5563;
`

export const LabelInput = styled.label`
    display: block;
    color: #4B5563;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0em;
    text-align: left;
    margin: 16px 0px;
`