import React, { FunctionComponent } from "react";
import {
  AlterLayoutLabel,
  ContainerMiniLayout,
  ContainerLayoutSquare,
  FigureLayout,
} from "./styles";
import { SquareIcon, HalfSquareIcon, SplitSquareIcon } from "../icons";
import { TypeContent } from "../../shared/enums/layoutContent/typeContent.enum";

interface AlterLayoutProps {
  setVisualization: React.Dispatch<React.SetStateAction<TypeContent | null>>;
  visualization: TypeContent | null;
}

const AlterLayout: FunctionComponent<AlterLayoutProps> = ({
  setVisualization,
  visualization,
}) => {
  return (
    <ContainerMiniLayout>
      <AlterLayoutLabel>Alterar Layout:</AlterLayoutLabel>
      <ContainerLayoutSquare>
        <FigureLayout onClick={() => setVisualization(TypeContent.SINGLE)}>
          <SquareIcon
            width="34"
            height="26"
            color={visualization === TypeContent.SINGLE ? "#3758F9" : "#D1D5DB"}
          />
        </FigureLayout>
        <FigureLayout onClick={() => setVisualization(TypeContent.HALF)}>
          <HalfSquareIcon
            width="34"
            height="26"
            color={visualization === TypeContent.HALF ? "#3758F9" : "#D1D5DB"}
          />
        </FigureLayout>
        <FigureLayout onClick={() => setVisualization(TypeContent.QUARTER)}>
          <SplitSquareIcon
            width="34"
            height="26"
            color={
              visualization === TypeContent.QUARTER ? "#3758F9" : "#D1D5DB"
            }
          />
        </FigureLayout>
      </ContainerLayoutSquare>
    </ContainerMiniLayout>
  );
};

export default AlterLayout;
