import { FunctionComponent, useState } from "react";
import { Alert, Col, Container, Modal, Row } from "react-bootstrap";
import logoUrl from "../../assets/logo.png";
import Button from "../../components/button";
import { ActionsContainer, LogoContainer } from "./styles";
import NotificationDropdown from "../notification";
import ProfileDropdown from "../profile-dropdown";
import { useNavigate } from "react-router-dom";
import DropDown from "../dropdown";
import { ROUTES } from "../../constants/routes";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { useAuthContext } from "../../context/auth-context";
import { UsersType } from "../../shared/enums/users-type";
import ModalContentSincronize from "./modal-sincronize";

interface NavbarProps {}

const Navbar: FunctionComponent<NavbarProps> = () => {
  const { user } = useAuthContext();
  const navigate = useNavigate();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showModalSincronize, setShowModalSincronize] =
    useState<boolean>(false);
  const [typeSincronize, setTypeSincronize] = useState<"online" | "local">();
  const [showNotificationDropdown, setShowNotificationDropdown] =
    useState<boolean>(false);
  const [showNotificationBar, setShowNotificationBar] =
    useState<boolean>(false);

  const handleCloseModalSincronize = () => {
    setShowModalSincronize(false);
  };

  function handleOpenModal() {
    if (user.type === UsersType.OPERADOR) {
      const alertOptions: SweetAlertOptions = {
        title: `Deseja efetuar a sincronização de dados?`,
        text: "Essa ação irá enviar os dados atuais ao servidor online, certifique que os dados estão corretos antes de enviar!",
        icon: "warning",
        showDenyButton: true,
        confirmButtonText: "Sim",
        denyButtonText: "Não",
      };

      Swal.fire(alertOptions).then((result) => {
        if (result.isConfirmed) {
          setTypeSincronize("local");
          setShowModalSincronize(true);
        }
      });
    } else {
      const alertOptions: SweetAlertOptions = {
        title: `Ferramentas de sincronização`,
        text: "Quais dessas operações deseja fazer?",
        icon: "question",
        showCancelButton: true,
        showDenyButton: true,
        showConfirmButton: true,
        confirmButtonText: "Sincronizar Local - Online",
        denyButtonText: "Pegar Obra(s) e Usuário(s)",
        cancelButtonText: "Cancelar",
      };

      Swal.fire(alertOptions).then((result) => {
        if (result.isConfirmed) {
          const alertOptionsConfirm: SweetAlertOptions = {
            title: `Deseja efetuar a sincronização de dados?`,
            text: "Essa ação irá enviar os dados atuais ao servidor online, certifique que os dados estão corretos antes de enviar!",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: "Não",
          };

          Swal.fire(alertOptionsConfirm).then((result) => {
            if (result.isConfirmed) {
              setTypeSincronize("local");
              setShowModalSincronize(true);
            }
          });
        } else if (result.isDenied) {
          setTypeSincronize("online");
          setShowModalSincronize(true);
        }
        // O SweetAlert automaticamente fecha o alerta se for cancelado, então não é necessário adicionar uma ação para isso.
      });
    }
  }

  return (
    <nav style={{ borderBottom: "1px solid #DFE4EA" }}>
      {showNotificationBar && (
        <Alert
          variant="danger"
          onClose={() => setShowNotificationBar(false)}
          dismissible
          className="d-flex justify-content-center align-items-center"
        >
          <strong>Possui alarme(s) a serem resolvidos!</strong>
        </Alert>
      )}
      <Container fluid>
        <Row style={{ height: "80px" }}>
          <Col
            md={6}
            xs={10}
            className="d-flex justify-content-start align-items-center"
          >
            <LogoContainer onClick={() => navigate(ROUTES.DASHBOARD)}>
              <img src={logoUrl} alt="logo" />
            </LogoContainer>
          </Col>
          <Col
            md={6}
            xs={2}
            className="d-flex justify-content-end align-items-center"
          >
            <ActionsContainer>
              <Button
                disabled={showModalSincronize}
                sizeButton="md"
                schemaButton="out-primary"
                action={() => {
                  handleOpenModal();
                }}
              >
                Sincronizar dados
              </Button>

              <NotificationDropdown
                showDropdown={showNotificationDropdown}
                setShowDropdown={setShowNotificationDropdown}
                setShowNotificationBar={setShowNotificationBar}
              />

              <ProfileDropdown
                setShowDropdown={setShowDropdown}
                showDropdown={showDropdown}
              />
              <DropDown show={showDropdown} setShowDropdown={setShowDropdown} />
            </ActionsContainer>
          </Col>
        </Row>
        <Modal
          size="lg"
          show={showModalSincronize}
          setShow={setShowModalSincronize}
        >
          <ModalContentSincronize
            closeModal={handleCloseModalSincronize}
            type={typeSincronize!}
          />
        </Modal>
      </Container>
    </nav>
  );
};

export default Navbar;
