import React, {
  FunctionComponent,
  ReactNode,
  useEffect,
  useState,
} from "react";
import {
  StyledInput,
  LabelForInput,
  TInputSchema,
  TInputSize,
  InputContainer,
  IconFieldContainer,
  IconBox,
  StyledInputNoBorder,
  InputMessageError,
  TemperaturaFormControl,
  styleCelsiusTemp,
} from "./styles";
import { FieldError, RegisterOptions, UseFormRegister } from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";
import { InputGroup } from "react-bootstrap";
import { EModelInput } from "./enum/modelInput.enum";

interface CustomInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  label: string;
  fieldName: string;
  errors: FieldError | undefined;
  register: UseFormRegister<any>;
  options: RegisterOptions;
  schema: TInputSchema;
  sizeInput: TInputSize;
  placeholder?: string;
  icon?: ReactNode;
  type?: string;
  model?: EModelInput;
  disabled?: boolean;
}

const CustomInput: FunctionComponent<CustomInputProps> = ({
  label,
  readOnly,
  type,
  value,
  width,
  schema,
  sizeInput,
  icon,
  errors,
  fieldName,
  register,
  options,
  defaultValue,
  model,
  placeholder,
  disabled,
}) => {
  const [messageError, setMessageError] = useState("");
  const [isDisabled, setIsDisabled] = useState<boolean>(disabled || false);

  useEffect(() => {
    if (errors) {
      switch (errors.type) {
        case "required":
          setMessageError("O campo é obrigatório");
          break;

        default:
          break;
      }
    }
  }, [errors]);

  useEffect(() => {
    if (disabled !== undefined) {
      setIsDisabled(disabled);
    }
  }, [disabled]);

  if (model === EModelInput.TEMP) {
    return (
      <InputContainer>
        <LabelForInput>{label}</LabelForInput>
        <InputGroup>
          <TemperaturaFormControl
            type={type}
            readOnly={readOnly}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            {...register(fieldName, options)}
            disabled={isDisabled}
          />
          <InputGroup.Text style={styleCelsiusTemp}>°C</InputGroup.Text>
        </InputGroup>
        {errors && (
          <InputMessageError>
            <FiAlertCircle size={18} />
            {messageError}
          </InputMessageError>
        )}
      </InputContainer>
    );
  }

  if (model === EModelInput.ICON) {
    return (
      <InputContainer>
        <LabelForInput>{label}</LabelForInput>
        <IconFieldContainer schema={schema} sizeInput={sizeInput}>
          <StyledInputNoBorder
            type={type}
            sizeInput={sizeInput}
            readOnly={readOnly}
            value={value}
            defaultValue={defaultValue}
            placeholder={placeholder}
            {...register(fieldName, options)}
            disabled={isDisabled}
          />
          <IconBox>{icon}</IconBox>
        </IconFieldContainer>
        {errors && (
          <InputMessageError>
            <FiAlertCircle size={18} />
            {messageError}
          </InputMessageError>
        )}
      </InputContainer>
    );
  }

  return (
    <InputContainer>
      <LabelForInput>{label}</LabelForInput>
      <StyledInput
        type={type}
        readOnly={readOnly}
        value={value}
        schema={schema}
        sizeInput={sizeInput}
        defaultValue={defaultValue}
        placeholder={placeholder}
        {...register(fieldName, options)}
        disabled={isDisabled}
      />
      {errors && (
        <InputMessageError>
          <FiAlertCircle size={18} />
          {messageError}
        </InputMessageError>
      )}
    </InputContainer>
  );
};

export default CustomInput;
