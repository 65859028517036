import { Link } from "react-router-dom";
import styled from "styled-components";

export const CustomLink = styled(Link)`
    text-decoration: none;
    color: #6B7280;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
`