import React, { FunctionComponent, useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import Radio from "../../components/radio";
import Button from "../../components/button";
import { HiOutlineArrowsRightLeft } from "react-icons/hi2";
import SelectArrows from "../../components/select-arrows";
import { useInstrumentsService } from "../../hooks/api/instruments";
import { statusTermopar } from "../../shared/enums/termopar/statusTermopar.enum";
import { useToast } from "../../hooks/toast";
import {
  BoxForChange,
  ContainerForButton,
  ContainerForRadio,
  TitleModal,
} from "./styles";
import { CustomLine } from "../navbar/modal-sincronize/styles";
import { useTermoparesService } from "../../hooks/api/termopares";
import { TypeElement } from "../../shared/enums/typeContent/typeElement.enum";
import { useGroupsService } from "../../hooks/api/groups";

interface ChangeThermocoupleContainerProps {
  idElement: number;
  typeElement: TypeElement;
  idTermoparSelect: number;
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ITermoparData {
  label: string;
  value: number;
  status: statusTermopar;
}

interface IInstrumentoData {
  label: string;
  value: number;
  termopares: ITermoparData[];
}

const ChangeThermocoupleContainer: FunctionComponent<
  ChangeThermocoupleContainerProps
> = ({ idElement, idTermoparSelect, typeElement, show, setShow }) => {
  const { getOneInstrument, getByObrasInstrumentos } = useInstrumentsService();
  const { getOneGroup } = useGroupsService();
  const { showSuccessToast } = useToast();
  const { changeThermocoupleInstrument } = useTermoparesService();

  const [selectedIdTermopar, setSelectedIdTermopar] =
    useState<number>(idTermoparSelect);
  const [selectedSwitchIdTermopar, setSelectedSwitchIdTermopar] =
    useState<number>(0);
  const [allInstruments, setAllInstrumente] = useState<any[]>([]);
  const [dataInstrumentsTermo, setDataInstrumentsTermo] = useState<any[]>([]);
  const [instrumentSelected, setInstrumentSelected] = useState<number>();
  const [loading, setLoading] = useState<boolean>(true);

  const getDataInstrumento = async () => {
    await getOneInstrument(idElement).then(({ data }) => {
      let termopares: ITermoparData[] = [];
      data.termopares.map((termopar) => {
        termopares.push({
          label: termopar.tag,
          value: termopar.id,
          status: termopar.status,
        });
        return null;
      });
      setDataInstrumentsTermo(termopares);
    });
  };

  const getDataGroup = async () => {
    await getOneGroup(idElement).then(({ data }) => {
      let termopares: ITermoparData[] = [];
      data.termopares.map((termopar) => {
        termopares.push({
          label: termopar.tag,
          value: termopar.id,
          status: termopar.status,
        });
        return null;
      });
      setDataInstrumentsTermo(termopares);
    });
  };
  async function getAllInstrumentos() {
    await getByObrasInstrumentos().then(({ data }) => {
      let dataInstrumentsMap: IInstrumentoData[] = [];
      data.forEach((instrumento) => {
        if (instrumento.id !== idElement) {
          dataInstrumentsMap.push({
            value: instrumento.id,
            label: instrumento.nome,
            termopares: instrumento.termopares.map((termopar) => {
              return {
                label: termopar.tag,
                value: termopar.id,
                status: termopar.status,
              };
            }),
          });
        }
      });
      setAllInstrumente(dataInstrumentsMap);
      if (dataInstrumentsMap.length > 0) {
        setInstrumentSelected(dataInstrumentsMap[0].value);
      }
    });
    setLoading(false);
  }

  useEffect(() => {
    if (typeElement === TypeElement.INSTRUMENTO) {
      getDataInstrumento();
    } else {
      getDataGroup();
    }
    getAllInstrumentos();
  }, []);

  const handleSwitchTermopar = async () => {
    if (selectedIdTermopar === null || selectedSwitchIdTermopar === null) {
      console.log("Selecione os termopares que deseja trocar.");
      return;
    }
    console.log(selectedIdTermopar, selectedSwitchIdTermopar!);

    try {
      await changeThermocoupleInstrument(
        selectedIdTermopar,
        selectedSwitchIdTermopar!
      ).then((result) => {
        console.log(result);
      });
      console.log("IDs dos termopares trocados com sucesso!");
      showSuccessToast("Termopares trocados com sucesso!");
      setShow(false);
    } catch (error) {
      console.error("Erro ao trocar os IDs dos termopares:", error);
    }
  };

  const handleSelectInstrumento = async (value: number) => {
    setInstrumentSelected(value);
  };

  return (
    <>
      {!loading ? (
        <>
          <Row>
            <Col>
              <TitleModal>Trocar Termopar</TitleModal>
              <CustomLine />
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="d-flex justify-content-center">
                <h5>Instrumento com termopar inoperante</h5>
              </div>
              <BoxForChange>
                <ContainerForRadio>
                  {dataInstrumentsTermo.map(
                    (termopar: ITermoparData, index) => (
                      <Radio
                        key={index}
                        value={termopar.value}
                        text={termopar.label}
                        status={termopar.status}
                        type="OUTLINE"
                        setSelectedTermopar={setSelectedIdTermopar}
                        checked={termopar.value === selectedIdTermopar}
                        selected={termopar.status === statusTermopar.INOPERANTE}
                      />
                    )
                  )}
                </ContainerForRadio>
              </BoxForChange>
            </Col>
            <Col>
              <ContainerForButton>
                <Button
                  sizeButton="md"
                  icon={<HiOutlineArrowsRightLeft />}
                  schemaButton="out-primary"
                  action={() => handleSwitchTermopar()}
                >
                  TROCAR TERMOPAR
                </Button>
              </ContainerForButton>
            </Col>
            <Col>
              <div className="d-flex justify-content-center mt-3">
                <h5>Instrumentos para troca</h5>
              </div>
              <div className="d-flex justify-content-center">
                <SelectArrows
                  items={allInstruments}
                  defaultLabel="Selecione o instrumento"
                  onChange={(v) => handleSelectInstrumento(v)}
                />
              </div>

              <BoxForChange>
                <ContainerForRadio>
                  {instrumentSelected &&
                    allInstruments.map((instrumentos: IInstrumentoData) =>
                      instrumentos.value === instrumentSelected
                        ? instrumentos.termopares.map(
                            (termopar: ITermoparData, index: number) => (
                              <Radio
                                key={index}
                                value={termopar.value}
                                text={termopar.label}
                                type="OUTLINE"
                                selected={
                                  termopar.status === statusTermopar.OPERANTE
                                }
                                status={termopar.status}
                                setSelectedTermopar={
                                  setSelectedSwitchIdTermopar
                                }
                                checked={
                                  termopar.value === selectedSwitchIdTermopar
                                }
                              />
                            )
                          )
                        : null
                    )}
                </ContainerForRadio>
              </BoxForChange>
            </Col>
          </Row>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: "100px", height: "100px", borderWidth: "0.2em" }}
          />
        </div>
      )}
    </>
  );
};

export default ChangeThermocoupleContainer;
