import styled from "styled-components";

export const ImageForPoints = styled.img`
  height: 100%;
`;

export const DragPointsContainer = styled.div`
  display: block;
`;

export const TitleModal = styled.h3`
  text-align: center;
`;

export const BoxForChange = styled.div`
  margin: 16px 0;
  padding: 24px;
  border: 1px solid #c2bdbd;
  width: 100%;
  height: auto;
  max-height: 300px;
  overflow-y: auto;
`;

export const ContainerForRadio = styled.div`
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

export const ContainerForButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 250px;
`;
