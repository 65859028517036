import styled from "styled-components";

export type TSizeButton = "lg" | "md" | "sm";
export type TSchemaButton =
  | "full-primary"
  | "full-secondary"
  | "full-danger"
  | "disabled"
  | "out-primary"
  | "out-secondary"
  | "out-danger"
  | "out-success";

export type TButton = {
  sizeButton: TSizeButton;
  schemaButton: TSchemaButton;
};

export const StyledButton = styled.button<TButton>`
  display: inline-flex;
  align-items: center;
  gap: ${(props) => props.theme.padding.sm};
  padding: ${(props) => {
    if (props.sizeButton === "lg") return props.theme.button.padding.lg;
    else if (props.sizeButton === "md") return props.theme.button.padding.md;
    else return props.theme.button.padding.sm;
  }};
  font-size: ${(props) => {
    if (props.sizeButton === "lg") return props.theme.font.size.lg;
    else if (props.sizeButton === "md") return props.theme.font.size.md;
    else return props.theme.font.size.sm;
  }};
  font-weight: ${(props) => props.theme.font.weight.default};
  letter-spacing: 0em;
  cursor: pointer;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  ${(props) => {
    switch (props.schemaButton) {
      case "full-primary":
        return `
          background: #2563EB;
          color: #fff;
          border: none;
        `;
      case "full-danger":
        return `
          background: #DC2626;
          color: #fff;
          border: none;
        `;
      case "out-primary":
        return `
          background: #fff;
          color: #3758F9;
          border: 1px solid #3758F9;
          &:hover {
            background: #3758F9;
            color: #fff;
          }
        `;
      case "out-danger":
        return `
          background: #fff;
          color: #DC2626;
          border: 1px solid #DC2626;
          &:hover {
            background: #DC2626;
            color: #fff;
          }
        `;
      case "out-success":
        return `
          background: #fff;
          color: #047857;
          border: 1px solid #047857;
          &:hover {
            background: #047857;
            color: #fff;
          }
        `;
      default:
        break;
    }
  }}

  pointer-events:${(props) => (props.disabled ? "none" : null)};
  background: ${(props) => (props.disabled ? "#E5E7EB" : null)};
  color: ${(props) => (props.disabled ? "#6B7280" : null)};
`;
