const theme = {
      colors: {
        black: "#000000",
        white: "#FFFFFF",
        ice: "#F2F2F2",
        grey: "#CCCCCC",
        blueSteel: "#505D68",
      },
      padding: {
        xl: "32px",
        lg: "24px",
        md: "16px",
        sm: "8px",
      },
      border: {
        radius: "8px",
        style: "1px solid",
      },
      font: {
        weight: {
          strong: "700",
          default: "600",
        },
        size: {
          xl: "3rem",
          lg: "2rem",
          md: "1rem",
          sm: "0.5rem",
        }
      },
      button: {
        padding: {
          lg: '16px 24px',
          md: '8px 16px',
        }
      },
      checkbox: {
        width: "24px",
        height: "24px",
      }
}

export default theme;