import { useApi } from "../";
import { IResultApi } from "../../../shared/interfaces/api/api.response";
import {
  IInstrumentsTermopares,
  IInstrumentsTermoparesCheck,
  InstrumentosRequest,
  InstrumentosRequestBody,
} from "../../../shared/interfaces/instruments/instruments";
import { TypeGraphics } from "../../../shared/enums/layoutContent/typeGraphics.enum";
import { IDataForDashboard } from "../../../shared/interfaces/temperatures/temperatures";
import { TypeElement } from "../../../shared/enums/typeContent/typeElement.enum";

export const useInstrumentsService = () => {
  const { get, post, put, del } = useApi();

  const getAllWithTermopares = async () => {
    const result = await get<IInstrumentsTermoparesCheck[]>(
      "/instrumentos/get-by-obra"
    );

    // Format data for TermoparCheckBox
    const { data } = result;
    if (data) {
      for (let itemTerm of data) {
        for (let termopar of itemTerm.termopares) {
          termopar.checked = false;
        }
      }
    }

    return { data };
  };

  const getOneInstrument = (id: number) => {
    return get<IInstrumentsTermopares>("/instrumentos/get-by-id/" + id);
  };

  const getAll = async () => {
    return await get<IInstrumentsTermopares[]>("/instrumentos/all");
  };

  const getByObrasInstrumentos = async () => {
    return await get<IInstrumentsTermopares[]>("/instrumentos/get-by-obra");
  };

  const getDataForDashboard = () => {
    return put<IDataForDashboard>("/instrumentos/get-data-dashboard");
  };

  const create = async ({ data }: InstrumentosRequest) => {
    return await post<IResultApi>("/instrumentos/create", data);
  };

  const update = async (id: number, data: InstrumentosRequestBody) => {
    return await put<IResultApi>("/instrumentos/update/" + id, data);
  };

  const deleteOne = async (id: number) => {
    return await del<IResultApi>("/instrumentos/delete/" + id);
  };

  const restoreBySlave = async (idSlave: number) => {
    return await get<IResultApi>("/instrumentos/restore-by-slave/" + idSlave);
  };

  return {
    getAllWithTermopares,
    getDataForDashboard,
    create,
    getByObrasInstrumentos,
    getOneInstrument,
    update,
    deleteOne,
    restoreBySlave,
    getAll,
  };
};
