import { useApi } from "../";
import { IResultApi } from "../../../shared/interfaces/api/api.response";
import { ISincronizeData } from "../../../shared/interfaces/sincronize/sincronize";

export const useSincronizeServiceLocal = () => {
  const { get, put } = useApi();

  const getDataLocal = async () => {
    return await get<ISincronizeData>("/sincronize/get-data-local");
  };

  const sincronizeLocalByOnline = async () => {
    return await put<IResultApi>("/sincronize/sincronize-local-online");
  };

  const sincronizeOnlineByLocal = async () => {
    return await put<IResultApi>("/sincronize/sincronize-online-local");
  };

  return {
    getDataLocal,
    sincronizeLocalByOnline,
    sincronizeOnlineByLocal,
  };
};
