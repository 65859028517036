import React, { FunctionComponent, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Button from "../../../components/button";
import {
  ContainerForPointsUsers,
  CustomLine,
  FilterLayoutContent,
  TitleModal,
} from "./styles";
import Select from "../../../components/select";
import { SubmitHandler, useForm } from "react-hook-form";
import { IUserChecked } from "../../../shared/interfaces/user/user.data";
import { ROUTES } from "../../../constants/routes";
import { useNavigate } from "react-router-dom";
import {
  ContainerForCheckbox,
  ContainerInstrumentName,
} from "../../group/new/styles";
import CheckboxPoint from "../../../components/checkbox-points";
import { useUsersService } from "../../../hooks/api/user";
import { useToast } from "../../../hooks/toast";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { useWorksService } from "../../../hooks/api/works";
import { formatDataForSelect } from "../../../utils/formatDataForSelect";

interface ModalContentProps {
  closeModal: () => void;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}

interface IFormInput {
  nome: string;
  id_obra: number;
  id_user: number[];
}

const ModalContent: FunctionComponent<ModalContentProps> = ({
  closeModal,
  setShowModal,
}) => {
  const [dataWorks, setDataWorks] = useState<any[]>([]);
  const { handleSubmit, formState, control } = useForm<IFormInput>();
  const [dataUsers, setDataUsers] = useState<IUserChecked[]>(
    [] as IUserChecked[]
  );
  const { getAllForDropdown } = useWorksService();
  const { getAllUsersForCheckbox, linkUsersToWork, checkUserInWork } =
    useUsersService();
  const { showSuccessToast, showErrorToast } = useToast();

  const navigate = useNavigate();

  const getAllWorks = () => {
    getAllForDropdown().then(({ data }) => {
      setDataWorks(
        formatDataForSelect(data, { newLabel: "descricao", newValue: "id" })
      );
    });
  };

  const getAllUsers = () => {
    getAllUsersForCheckbox().then(({ data }) => {
      setDataUsers(data);
    });
  };

  useEffect(() => {
    getAllUsers();
    getAllWorks();
  }, []);

  const checkPoint = (newUserId: number) => {
    const updatedUsers = dataUsers.map((user) => {
      if (user.id === newUserId) {
        return {
          ...user,
          checked: !user.checked,
        };
      }
      return user;
    });

    setDataUsers(updatedUsers);
  };

  const onSubmit: SubmitHandler<IFormInput> = async (data) => {
    // const formatedData = formatDataForSubmit(data);
    let idData: number[] = [];
    const id_obra = data.id_obra;

    if (dataUsers) {
      await Promise.all(
        dataUsers.map(async (user) => {
          if (user.checked === true) {
            idData.push(user.id); // Adiciona o ID ao array idData
          }
        })
      );

      await checkUserInWork(idData).then(async ({ data }) => {
        if (data.length > 0) {
          const alertOptions: SweetAlertOptions = {
            title: `Usuários já em obras!`,
            text: `Os seguinte usuário(os) já estão em uma obra em andamento: ${data}.\n Deseja substituir a obra desse(s) usuário(os)?`,
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: "Cancelar",
          };

          Swal.fire(alertOptions).then(async (result) => {
            if (result.isConfirmed) {
              await linkUserToWork(id_obra, idData);
            }
          });
        } else {
          await linkUserToWork(id_obra, idData);
        }
      });
    }
  };

  const linkUserToWork = async (id_obra: number, idData: number[]) => {
    await linkUsersToWork(id_obra, idData).then(({ data }) => {
      if (data.status) {
        dataUsers.map(async (user) => {
          if (user.checked === true) {
            showSuccessToast(`Usuário ${user.name} vinculado com sucesso!`);
          }
          setShowModal(false);
          navigate(ROUTES.WORKS);
        });
      } else {
        showErrorToast(data.message);
        setShowModal(false);
      }
    });
  };

  return (
    <Modal.Body>
      <div className="d-block">
        <TitleModal>Selecione o usuário para vincular a obra</TitleModal>
        <CustomLine />
        <form onSubmit={handleSubmit(onSubmit)}>
          <FilterLayoutContent>
            <ContainerForPointsUsers>
              <ContainerInstrumentName>
                <p className="text-center">Lista de Usuário</p>
              </ContainerInstrumentName>
              <ContainerForCheckbox className="d-flex align-items-start">
                {dataUsers.map((user, indexPoint) => {
                  return (
                    <div
                      style={{
                        background: "#212B361A",
                        padding: "4px 16px",
                        borderRadius: "8px",
                        marginBottom: "8px",
                        margin: "5px 0",
                      }}
                    >
                      <CheckboxPoint
                        text={user.name}
                        action={() => checkPoint(user.id)}
                        checked={user.checked}
                      />
                    </div>
                  );
                })}
              </ContainerForCheckbox>
            </ContainerForPointsUsers>
            <Select
              defaultOption="Selecione a Obra"
              options={dataWorks}
              label="Obra"
              control={control}
              name="id_obra"
              rules={{ required: "O campo é obrigatório" }}
              validationMessage={
                formState.errors.id_obra
                  ? formState.errors.id_obra.message
                  : undefined
              }
              schema={formState.errors.id_obra ? "danger" : "default"}
              sizeInput="full"
            />
          </FilterLayoutContent>
          <div style={{ textAlign: "center", margin: "10px 0" }}>
            <Button sizeButton="md" schemaButton="full-primary">
              Vincular
            </Button>
          </div>
        </form>
      </div>
    </Modal.Body>
  );
};

export default ModalContent;
