import { FunctionComponent, useEffect, useState } from "react";
import {
  CartesianGrid,
  LineChart as Chart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { TypeElement } from "../../../shared/enums/typeContent/typeElement.enum";
import { useGroupsService } from "../../../hooks/api/groups";
import { useInstrumentsService } from "../../../hooks/api/instruments";
import { useTemperatureService } from "../../../hooks/api/temperature";
import CheckboxPoint from "../../checkbox-points";
import { CheckboxContainer, InputContainer, InputField } from "./styles"; // Adicione InputContainer e InputField no seu arquivo de estilos
import { Col, Container, Row } from "react-bootstrap";
import CustomInput from "../../input";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "../../button";
import { statusTermopar } from "../../../shared/enums/termopar/statusTermopar.enum";
import FullScreen from "../../../pages/full-screen/index";
import { lineColors } from "../../../shared/line-colors/lineColors";

interface CustomLineChartProps {
  id: number;
  type: TypeElement;
  dataTemperature?: any[];
  fullScreen?: boolean;
}

interface IFormInput {
  date_initial: string;
  date_final: string;
}

const CustomLineChart: FunctionComponent<CustomLineChartProps> = ({
  id,
  type,
  dataTemperature = [],
  fullScreen = false,
}) => {
  const { getOneInstrument } = useInstrumentsService();
  const { getOneGroup } = useGroupsService();
  const {
    getHistoricTemperatureByTermopar,
    getLastTemperatureByTermoparOnFilter,
  } = useTemperatureService();
  const { register, handleSubmit, formState, reset } = useForm<IFormInput>();

  const [dataGraph, setDataGraph] = useState<any[]>([]);
  const [dataTagsTermopares, setDataTagsTermopares] = useState<string[]>([]);
  const [idTermopares, setIdTermopares] = useState<number[]>([]);
  const [selectedTags, setSelectedTags] = useState(new Set<string>());
  const [showTooltip, setShowTooltip] = useState(true);
  const [onFilterDate, setOnFilterDate] = useState<boolean>(false);
  const [isRequest, setIsRequest] = useState<boolean>(false);

  const [minY, setMinY] = useState<number | undefined>(undefined);
  const [maxY, setMaxY] = useState<number | undefined>(undefined);

  function getRandomColor() {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);
    return "#" + "0".repeat(6 - randomColor.length) + randomColor;
  }

  async function getDataInstrument() {
    let tagsNamesTermopares: string[] = [];
    let idTermopares: number[] = [];

    await getOneInstrument(id).then(({ data }) => {
      data.termopares = data.termopares.filter(
        (termopar) => termopar.status !== statusTermopar.DESABILITADO
      );
      data.termopares.forEach((termopar) => {
        tagsNamesTermopares.push(termopar.tag);
        idTermopares.push(termopar.id);
      });
    });
    setDataTagsTermopares(tagsNamesTermopares);
    setIdTermopares(idTermopares);
    setIsRequest(false);
  }

  async function getDataGrupos() {
    let tagsNamesTermopares: string[] = [];
    let idTermopares: number[] = [];

    await getOneGroup(id).then(({ data }) => {
      data.termopares = data.termopares.filter(
        (termopar) => termopar.status !== statusTermopar.DESABILITADO
      );
      data.termopares.forEach((termopar) => {
        tagsNamesTermopares.push(termopar.tag);
        idTermopares.push(termopar.id);
      });
    });
    setDataTagsTermopares(tagsNamesTermopares);
    setIdTermopares(idTermopares);
    setIsRequest(false);
  }

  async function getTemperature(date_initial?: string, date_final?: string) {
    const dateMap = new Map();
    const newDataArray: any[] = [];
    if (date_initial && date_final && onFilterDate) {
      await getLastTemperatureByTermoparOnFilter(
        idTermopares,
        date_initial,
        date_final
      ).then(({ data }) => {
        for (const termopar of data) {
          termopar.temperatures.forEach((temperature: any) => {
            let date = new Date(temperature.date);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}`;

            if (!dateMap.has(formattedTime)) {
              dateMap.set(formattedTime, { name: formattedTime });
            }
            dateMap.get(formattedTime)[termopar.tag_termopar] =
              temperature.value;
          });
        }

        dateMap.forEach((value) => {
          newDataArray.push(value);
        });
        setDataGraph(newDataArray);
        setIsRequest(false);
      });
    } else {
      if (!fullScreen) {
        for (const termopar of dataTemperature) {
          termopar.temperatures.forEach((temperature: any) => {
            let date = new Date(temperature.date);
            let hours = date.getHours();
            let minutes = date.getMinutes();
            let formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
              .toString()
              .padStart(2, "0")}`;

            if (!dateMap.has(formattedTime)) {
              dateMap.set(formattedTime, { name: formattedTime });
            }
            dateMap.get(formattedTime)[termopar.tag_termopar] =
              temperature.value;
          });
        }
        dateMap.forEach((value) => {
          newDataArray.push(value);
        });
        setDataGraph(newDataArray.reverse());
      } else {
        await getHistoricTemperatureByTermopar(idTermopares).then(
          ({ data }) => {
            for (const termopar of data) {
              termopar.temperatures.forEach((temperature: any) => {
                let date = new Date(temperature.date);
                let hours = date.getHours();
                let minutes = date.getMinutes();
                let formattedTime = `${hours
                  .toString()
                  .padStart(2, "0")}:${minutes.toString().padStart(2, "0")}`;

                if (!dateMap.has(formattedTime)) {
                  dateMap.set(formattedTime, { name: formattedTime });
                }
                dateMap.get(formattedTime)[termopar.tag_termopar] =
                  temperature.value;
              });
            }

            dateMap.forEach((value) => {
              newDataArray.push(value);
            });

            setDataGraph(newDataArray.reverse());
          }
        );
      }
    }
    setIsRequest(false);
  }

  function filterTagsName(tag: string) {
    const newSelectedTags = new Set(selectedTags);
    if (newSelectedTags.has(tag)) {
      newSelectedTags.delete(tag);
    } else {
      newSelectedTags.add(tag);
    }
    setSelectedTags(newSelectedTags);
  }

  function filterTemperaturaBySelectTags(data: any[], tags: Set<string>) {
    return data.map((entry) => {
      const filteredEntry: any = { name: entry.name };
      tags.forEach((selectedTag) => {
        if (entry[selectedTag] !== undefined) {
          filteredEntry[selectedTag] = entry[selectedTag];
        }
      });
      return filteredEntry;
    });
  }

  function handleCheckboxChange(tag: string) {
    filterTagsName(tag);
  }

  function clearFilterDate() {
    reset({
      date_initial: "",
      date_final: "",
    });

    setOnFilterDate(false);
    getTemperature();
  }

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    setOnFilterDate(true);
    setIsRequest(true);
    getTemperature(data.date_initial, data.date_final);
  };

  const filteredData = filterTemperaturaBySelectTags(dataGraph, selectedTags);

  useEffect(() => {
    if (!isRequest) {
      setIsRequest(true);
      if (type === TypeElement.INSTRUMENTO) {
        getDataInstrument();
      } else {
        getDataGrupos();
      }
    }
  }, []);

  useEffect(() => {
    if (!isRequest && dataTemperature.length > 0 && !onFilterDate) {
      setIsRequest(true);
      getTemperature();
    }
  }, [dataTemperature]);

  useEffect(() => {
    if (selectedTags.size === 0) {
      setSelectedTags(new Set(dataTagsTermopares));
    }
  }, [dataTagsTermopares]);

  useEffect(() => {
    if (!isRequest && dataTagsTermopares.length > 0) {
      getTemperature();
    }
  }, [dataTagsTermopares]);

  useEffect(() => {
    if (!isRequest && idTermopares.length > 0) {
      getTemperature();
    }
  }, [idTermopares, setIdTermopares]);

  useEffect(() => {
    if (fullScreen) {
      const intervalTime =
        Number(process.env.TIME_SET_INTERVAL_CHARTS) || 30000;
      const interval = setInterval(() => {
        if (!isRequest && !onFilterDate) {
          setIsRequest(true);
          getTemperature();
          console.log("Buscando Temperatura");
        }
      }, intervalTime);
      return () => clearInterval(interval);
    }
  }, [fullScreen]);

  return (
    <>
      <CheckboxPoint
        text="Mostrar Informações"
        action={() => setShowTooltip(!showTooltip)}
        checked={showTooltip}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Row>
          <Col className="d-flex justify-content-center">
            <CustomInput
              label="Data Início"
              type="datetime-local"
              sizeInput="full"
              fieldName="date_initial"
              errors={formState.errors.date_initial}
              schema={formState.errors.date_initial ? "danger" : "default"}
              register={register}
              options={{ required: true }}
            />
          </Col>
          <Col className="d-flex justify-content-center">
            <CustomInput
              label="Data Fim"
              type="datetime-local"
              sizeInput="full"
              fieldName="date_final"
              errors={formState.errors.date_final}
              schema={formState.errors.date_final ? "danger" : "default"}
              register={register}
              options={{ required: true }}
            />
          </Col>
          <Col className="d-flex justify-content-center">
            <Container
              fluid
              className="d-flex justify-content-between align-items-center"
            >
              <Button
                className="m-1"
                sizeButton="md"
                schemaButton="full-primary"
                type="submit"
              >
                Buscar histórico
              </Button>

              {onFilterDate && (
                <Button
                  sizeButton="md"
                  schemaButton="out-danger"
                  type="button"
                  action={() => {
                    setOnFilterDate(false);
                    clearFilterDate();
                  }}
                >
                  Limpar busca
                </Button>
              )}
            </Container>
          </Col>
        </Row>
      </form>
      <InputContainer>
        <label>
          Valor Mínimo Y:
          <InputField
            type="number"
            value={minY ?? ""}
            onChange={(e: any) => setMinY(Number(e.target.value))}
          />
        </label>
        <label>
          Valor Máximo Y:
          <InputField
            type="number"
            value={maxY ?? ""}
            onChange={(e: any) => setMaxY(Number(e.target.value))}
          />
        </label>
      </InputContainer>
      <div
        style={{
          height: "400px",
          width: "100%",
        }}
      >
        <ResponsiveContainer>
          <Chart
            width={730}
            height={300}
            data={filteredData}
            margin={{
              top: 5,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis
              domain={[
                minY
                  ? (maxDataValue: number) =>
                      minY <= maxDataValue ? minY : Math.max(minY, maxDataValue)
                  : "",
                maxY
                  ? (maxDataValue: number) =>
                      maxY >= maxDataValue ? maxY : Math.min(maxY, maxDataValue)
                  : "",
              ]}
            />
            {/* Atualizando o domínio do eixo Y */}
            {showTooltip && <Tooltip />}
            <Legend />
            {Array.from(selectedTags).map((tag, index) => (
              <Line
                key={index}
                type="monotone"
                dataKey={tag}
                stroke={
                  lineColors[`line${index + 1}` as keyof typeof lineColors]
                }
              />
            ))}
          </Chart>
        </ResponsiveContainer>
      </div>
      <CheckboxContainer>
        {dataTagsTermopares.map((tag, index) => (
          <CheckboxPoint
            key={index}
            text={tag}
            action={() => handleCheckboxChange(tag)}
            checked={selectedTags.has(tag)}
          />
        ))}
      </CheckboxContainer>
    </>
  );
};

export default CustomLineChart;
