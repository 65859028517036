import styled from "styled-components";

export const WorkFormContainer = styled.div`
    width: 100%;
    min-height: 60vh;
    margin: auto;
    display: block;
    background: #F9FAFB;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 40px;
`

export const TitleWorkForm = styled.h3`
    color: #4B5563;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 16px;
`

export const HrWork = styled.hr`
    border: 0;
    width: 100%;
    height: 1px;
    background: #E5E7EB;
`