import { createContext, useContext, useState, ReactNode } from "react";
import { useLocalStorage } from "usehooks-ts";
import { IUserLoginData } from "../shared/interfaces/user/user.data";

interface AuthContextType {
  token: string;
  user: IUserLoginData;
  isSigned: boolean;
  signIn: (token: string, user: IUserLoginData) => void;
  signOut: () => void;
  refreshToken: (token: string) => void;
  getToken: () => string;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

interface AuthProviderProps {
  children: ReactNode;
}

function AuthProvider({ children }: AuthProviderProps) {
  const [token, setToken] = useLocalStorage("token", "");
  const [user, setUser] = useLocalStorage<IUserLoginData>(
    "user",
    {} as IUserLoginData
  );

  const existsToken = () => {
    const tokenLocal = localStorage.getItem("token");
    if (tokenLocal && tokenLocal !== "") {
      return true;
    }

    return false;
  };

  const [isSigned, setIsSigned] = useState(existsToken());

  const signIn = (token: string, user: IUserLoginData) => {
    setToken(token);
    setUser(user);
    setIsSigned(true);
  };

  const signOut = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    setIsSigned(false);
  };

  const refreshToken = (token: string) => {
    setToken(token);
  };

  const getToken = () => {
    return token;
  };

  return (
    <AuthContext.Provider
      value={{ token, user, isSigned, signIn, signOut, refreshToken, getToken }}
    >
      {children}
    </AuthContext.Provider>
  );
}

function useAuthContext(): AuthContextType {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth deve ser usado dentro de um AuthProvider");
  }
  return context;
}

export { AuthProvider, useAuthContext, AuthContext };
