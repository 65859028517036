import { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../../components/main-container";
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import Button from "../../../components/button";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useFieldArray, useForm } from "react-hook-form";
import { InstrumentInputs } from "./interfaces/instrument.inputs";
import { emptyInputsValues } from "./utils/initForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./validations/validation";
import { makeRequestBody } from "./utils/makeRequest";
import { useInstrumentsService } from "../../../hooks/api/instruments";
import { useToast } from "../../../hooks/toast";
import CustomInput from "../../../components/input";
import "bootstrap/dist/css/bootstrap.min.css";
import { EModelInput } from "../../../components/input/enum/modelInput.enum";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { IoMdSave } from "react-icons/io";
import { useIntegrationService } from "../../../hooks/api/integration";
import { statusTermopar } from "../../../shared/enums/termopar/statusTermopar.enum";
import Checkbox from "../../../components/checkbox";

interface InstrumentUpdateProps {}

const UpdateInstrument: FunctionComponent<InstrumentUpdateProps> = () => {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const { getOneInstrument, update } = useInstrumentsService();
  const { getTemperatureByIntegration } = useIntegrationService();

  const location = useLocation();
  const instrumentId = location.state.id;

  const [portCom, setPortCom] = useState<string>("");
  const [slave, setSlave] = useState<number>(0);
  const [slaveTemperature, setSlaveTemperature] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isDisabled, setIsDisabled] = useState<boolean[]>([]);
  const [isRequest, setIsRequest] = useState<boolean>(false);

  const formMethods = useForm<InstrumentInputs>({
    defaultValues: emptyInputsValues,
    mode: "onSubmit",
    resolver: yupResolver(schema, {}, {}, {}, "all", "all"),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = formMethods;

  const { fields, replace } = useFieldArray({
    control,
    name: "termopares",
  });

  const fetchData = async () => {
    try {
      const { data } = await getOneInstrument(instrumentId);
      setSlave(data.slave_number);
      setPortCom(data.port_com);
      setValue("nome", data.nome);
      setValue("time_collection", data.time_collection);
      const termopares: InstrumentInputs["termopares"] = [];

      data.termopares.map((termopar, index) => {
        termopares.push({
          temperature:
            termopar.temperatura && termopar.temperatura[0]
              ? termopar.temperatura[0].valor
              : 0,
          canal: `canal-${index + 1}`,
          tag: termopar.tag,
          funcao: termopar.funcao,
          minima: termopar.minima,
          maxima: termopar.maxima,
          id: termopar.id,
        });
        if (termopar.status === statusTermopar.DESABILITADO) {
          setIsDisabled((prev) => [...prev, true]);
        } else {
          setIsDisabled((prev) => [...prev, false]);
        }
      });

      replace(termopares);
    } catch (err) {
      console.error(err);
      showErrorToast("Erro ao carregar os dados do instrumento.");
    }
  };

  const onSubmit = handleSubmit(async (data: InstrumentInputs) => {
    data.termopares.forEach((termopar, index) => {
      termopar.status = isDisabled[index]
        ? statusTermopar.DESABILITADO
        : statusTermopar.OPERANTE;
    });
    const body = makeRequestBody(data);
    try {
      await update(instrumentId, body);
      showSuccessToast("Instrumento atualizado com sucesso!");
      navigate(ROUTES.INSTRUMENTS);
    } catch (err) {
      console.error(err);
      const alertOptions: SweetAlertOptions = {
        title: "Erro ao atualizar o Instrumento",
        text: "Error",
        icon: "warning",
        denyButtonText: "Ok",
      };
      Swal.fire(alertOptions);
    }
  });

  async function getTemperature(slave: number, port: string) {
    try {
      const { data } = await getTemperatureByIntegration(slave, port);
      if (data.length > 0) {
        setSlaveTemperature(data);
        data.forEach((temperature: number, index: number) => {
          setValue(`termopares.${index}.temperature`, temperature);
        });
      } else {
        setSlaveTemperature([]);
      }
    } catch (error) {
      console.error(error);
      setSlaveTemperature([]);
      showErrorToast("Erro ao buscar temperaturas da integração.");
    } finally {
      setIsLoading(false);
    }
  }

  const handleCheckChange = (index: number) => {
    setIsDisabled((prev) => {
      const newIsDisabled = [...prev];
      newIsDisabled[index] = !newIsDisabled[index];
      return newIsDisabled;
    });
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const intervalTime =
      Number(process.env.TIME_SET_INTERVAL_DASHBOARD) || 2000;
    const interval = setInterval(() => {
      if (!isRequest) {
        setIsRequest(true);
        if (slave && portCom) {
          getTemperature(slave, portCom);
        }
      }
    }, intervalTime);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    if (portCom) {
      setSlaveTemperature([]);
      getTemperature(slave, portCom);
      showSuccessToast(`Buscando temperaturas da slave ${slave}`);
    }
  }, [slave, portCom]);

  return (
    <MainContainer>
      <Container className="mt-5">
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Form.Label htmlFor="basic-url">
                  Endereço do instrumento
                </Form.Label>
                <InputGroup className="mb-5">
                  <InputGroup.Text id="basic-addon1">Endereço</InputGroup.Text>
                  <Form.Control
                    value={slave}
                    disabled={true}
                    min={0}
                    type="number"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    onChange={(e) => {
                      setSlave(parseInt(e.target.value));
                      setIsLoading(true);
                    }}
                  />
                </InputGroup>
              </Col>
              <Col>
                <Form.Label htmlFor="basic-url">
                  Porta COM cadastrada
                </Form.Label>
                <InputGroup className="mb-5">
                  <Form.Control
                    value={portCom}
                    disabled={true}
                    readOnly={true}
                  />
                </InputGroup>
              </Col>
            </Row>
            <hr />
            <form onSubmit={onSubmit}>
              {isLoading ? (
                <div className="d-flex justify-content-center align-items-center">
                  <Spinner animation="border" variant="primary" />
                  <span className="sr-only m-3">Buscando temperaturas</span>
                </div>
              ) : slaveTemperature && slaveTemperature.length > 0 ? (
                <>
                  <Row>
                    <Col>
                      <CustomInput
                        label="Nome"
                        placeholder="Digite o nome para o instrumento"
                        type="text"
                        sizeInput="full"
                        fieldName="nome"
                        errors={errors.nome}
                        schema={errors.nome ? "danger" : "default"}
                        register={register}
                        options={{ required: true }}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        label="Tempo de coleta (30 em 30 segundos)"
                        placeholder="Valor mínimo 30 segundos"
                        type="number"
                        sizeInput="full"
                        fieldName="time_collection"
                        errors={errors.time_collection}
                        schema={errors.time_collection ? "danger" : "default"}
                        register={register}
                        options={{ required: true }}
                      />
                    </Col>
                  </Row>
                  {slaveTemperature.map((temperature, index) => (
                    <Row
                      xs={1}
                      md={6}
                      key={index}
                      style={{ borderBottom: "1px solid #E5E7EB" }}
                    >
                      <Col>
                        <CustomInput
                          model={EModelInput.TEMP}
                          label="Temperatura atual"
                          value={temperature}
                          type="text"
                          sizeInput="full"
                          readOnly={true}
                          disabled={isDisabled[index]}
                          fieldName={`termopares.${index}.temperature`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.temperature
                              : undefined
                          }
                          schema={
                            errors.termopares &&
                            errors.termopares[index]?.temperature
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">
                        <Checkbox
                          checked={!isDisabled[index]}
                          text={`canal-${index + 1}`}
                          action={() => handleCheckChange(index)}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label="Tag"
                          type="text"
                          sizeInput="full"
                          fieldName={`termopares.${index}.tag`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.tag
                              : undefined
                          }
                          schema={
                            errors.termopares && errors.termopares[index]?.tag
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                          disabled={isDisabled[index]}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label="Função"
                          type="text"
                          placeholder="Insira a função"
                          sizeInput="full"
                          fieldName={`termopares.${index}.funcao`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.funcao
                              : undefined
                          }
                          schema={
                            errors.termopares &&
                            errors.termopares[index]?.funcao
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                          disabled={isDisabled[index]}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label="Temperatura mínima"
                          placeholder="Insira o valor da temperatura mínima"
                          type="number"
                          sizeInput="full"
                          fieldName={`termopares.${index}.minima`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.minima
                              : undefined
                          }
                          schema={
                            errors.termopares &&
                            errors.termopares[index]?.minima
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                          disabled={isDisabled[index]}
                        />
                      </Col>
                      <Col>
                        <CustomInput
                          label="Temperatura máxima"
                          placeholder="Insira o valor da temperatura máxima"
                          type="number"
                          sizeInput="full"
                          fieldName={`termopares.${index}.maxima`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.maxima
                              : undefined
                          }
                          schema={
                            errors.termopares &&
                            errors.termopares[index]?.maxima
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                          disabled={isDisabled[index]}
                        />
                      </Col>
                    </Row>
                  ))}
                </>
              ) : (
                <Alert variant="danger">
                  <Alert.Heading>
                    O endereço {slave} não possui nenhum canal configurado.
                  </Alert.Heading>
                </Alert>
              )}
              <div className="d-flex justify-content-between">
                <Button
                  schemaButton="out-danger"
                  sizeButton="md"
                  type="button"
                  action={() => {
                    navigate(ROUTES.INSTRUMENTS);
                  }}
                >
                  Cancelar <IoMdSave fontSize={22} />
                </Button>

                <Button
                  schemaButton="out-primary"
                  sizeButton="md"
                  type="submit"
                  disabled={isSubmitting}
                >
                  Atualizar Instrumento <IoMdSave fontSize={22} />
                </Button>
              </div>
            </form>
          </Card.Body>
        </Card>
      </Container>
    </MainContainer>
  );
};

export default UpdateInstrument;
