import { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../../components/main-container";
import HeaderSection from "../../../components/header-section";
import CarouselCustomized from "../../../components/carousel";
import {
  BoxCloseIcon,
  BoxForPointsSelected,
  BoxPoint,
  ContainerForCheckbox,
  ContainerForPoints,
  ContainerInstrumentName,
  SelectedPointsContainer,
  TermoparContainer,
  TermoparIndex,
  TitlePointsContainer,
} from "./styles";
import { BsPencil } from "react-icons/bs";
import { SlClose } from "react-icons/sl";
import CheckboxPoint from "../../../components/checkbox-points";
import { IInstrumentsTermoparesCheck } from "../../../shared/interfaces/instruments/instruments";
import { useToast } from "../../../hooks/toast";
import { ITermoparChecked } from "../../../shared/interfaces/termopares/termopares";
import { useGroupsService } from "../../../hooks/api/groups";
import { SubmitHandler, useForm } from "react-hook-form";
import CustomInput from "../../../components/input";
import { ButtonForSubmit } from "../../../components/input/styles";
import { Container, Spinner } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useInstrumentsService } from "../../../hooks/api/instruments";

interface NewGroupProps {}

interface IFormInput {
  nome: string;
  termopares_ids: number[];
}

const NewGroup: FunctionComponent<NewGroupProps> = () => {
  const [instrumentsTermopares, setInstrumentsTermopares] = useState<
    IInstrumentsTermoparesCheck[]
  >([] as IInstrumentsTermoparesCheck[]);
  const [selectedPoints, setSelectedPoints] = useState<ITermoparChecked[]>(
    [] as ITermoparChecked[]
  );
  const { getAllWithTermopares } = useInstrumentsService();

  const { showSuccessToast, showErrorToast } = useToast();
  const { create } = useGroupsService();
  const { register, handleSubmit, formState } = useForm<IFormInput>();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const handleGetAllInstrumentsTermopares = () => {
    getAllWithTermopares()
      .then(({ data }) => {
        let i = 1;
        data.forEach((instrumento) => {
          instrumento.termopares.forEach((termopar) => {
            termopar.index = i;
            i++;
          });
        });
        setInstrumentsTermopares(data);
        setIsLoading(false);
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const checkPoint = (newPointId: number) => {
    const updatedInstrumentsTermopares = instrumentsTermopares.map(
      (instrument) => {
        return {
          ...instrument,
          termopares: instrument.termopares.map((termopar) => {
            if (termopar.id === newPointId) {
              return {
                ...termopar,
                checked: !termopar.checked,
              };
            }
            return termopar;
          }),
        };
      }
    );

    const updatedSelectedPoints = updatedInstrumentsTermopares
      .flatMap((instrument) => instrument.termopares)
      .filter((termopar) => termopar.checked);

    setInstrumentsTermopares(updatedInstrumentsTermopares);
    setSelectedPoints(updatedSelectedPoints);
  };

  const formatDataForSubmit = (data: IFormInput) => {
    data.termopares_ids = selectedPoints.map((point) => point.id);

    return data;
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    const formatedData = formatDataForSubmit(data);
    create(formatedData)
      .then(() => {
        showSuccessToast("Grupo cadastrado com sucesso");
        navigate(ROUTES.GROUPS);
      })
      .catch((err) => {
        showErrorToast("Falha ao realizar cadastro do grupo");
      })
      .finally(() => {
        // cleaning selected points
        setSelectedPoints([]);
      });
  };

  useEffect(() => {
    handleGetAllInstrumentsTermopares();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <MainContainer>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center mt-5">
          <Spinner
            animation="border"
            variant="primary"
            style={{ width: "100px", height: "100px", borderWidth: "0.2em" }}
          />
        </div>
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <section className="mt-5">
            <HeaderSection title="Todos Instrumentos" />
            <CarouselCustomized
              itemsByResponsive={{ desktop: 4, mobile: 2, tablet: 4 }}
            >
              {instrumentsTermopares.map((instrument, index) => (
                <ContainerForPoints>
                  <ContainerInstrumentName>
                    <p className="text-center">{instrument.nome}</p>
                    <BsPencil color="#2563EB" />
                  </ContainerInstrumentName>
                  <ContainerForCheckbox>
                    {instrument.termopares.map((termopar, indexPoint) => (
                      <TermoparContainer key={termopar.id}>
                        <TermoparIndex>{termopar.index}</TermoparIndex>
                        <CheckboxPoint
                          text={termopar.tag}
                          action={() => checkPoint(termopar.id)}
                          checked={termopar.checked}
                        />
                      </TermoparContainer>
                    ))}
                  </ContainerForCheckbox>
                </ContainerForPoints>
              ))}
            </CarouselCustomized>
            <SelectedPointsContainer>
              <TitlePointsContainer>
                Termopares selecionados
              </TitlePointsContainer>
              <BoxForPointsSelected>
                {selectedPoints.map((point) => (
                  <TermoparContainer key={point.id}>
                    <TermoparIndex>{point.index}</TermoparIndex>
                    <CheckboxPoint
                      text={point.tag}
                      checked={point.checked}
                      checkedForever
                    />

                    <BoxCloseIcon onClick={() => checkPoint(point.id)}>
                      <SlClose style={{ background: "#fff" }} />
                    </BoxCloseIcon>
                  </TermoparContainer>
                ))}
              </BoxForPointsSelected>
              <Container fluid>
                <CustomInput
                  label="Nome"
                  type="text"
                  sizeInput="full"
                  fieldName="nome"
                  errors={formState.errors.nome}
                  schema={formState.errors.nome ? "danger" : "default"}
                  register={register}
                  options={{ required: true }}
                />
                <ButtonForSubmit type="submit" value="Criar Grupo" />
              </Container>
            </SelectedPointsContainer>
          </section>
        </form>
      )}
    </MainContainer>
  );
};

export default NewGroup;
