import React, { FunctionComponent } from "react";

interface SquareIconProps {
    width: string;
    height: string;
    color: string;
}
 
export const SquareIcon: FunctionComponent<SquareIconProps> = ({width, height, color}) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 26" fill="none">
            <path d="M22 0V26H34V0M0 26H12V0H0M12 26H22V0H12V26Z" fill={color}/>
        </svg>
    );
}