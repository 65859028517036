import { useApi } from "../";
import { IResultApi } from "../../../shared/interfaces/api/api.response";
import { ITermopar } from "../../../shared/interfaces/termopares/termopares";
import { InstrumentosRequest } from "../../../shared/interfaces/instruments/instruments";

export const useTermoparesService = () => {
  const { get, post, put, del } = useApi();

  const getAll = async () => {
    return await get<ITermopar[]>("/termopares/all");
  };

  const getByIdsWithTemperature = async (id_termopares: number[]) => {
    return post<any>("/termopar/get-by-ids-with-temperature", id_termopares);
  };

  const getByIdsWithCurrentTemperature = async (id_termopares: number[]) => {
    return post<any>(
      "/termopar/get-by-ids-with-current-temperature",
      id_termopares
    );
  };

  const create = async ({ data }: InstrumentosRequest) => {
    return await post<IResultApi>("/instrumentos/create", data);
  };

  const changeThermocoupleInstrument = (
    idTermopar1: number,
    idTermopar2: number
  ) => {
    return put<IResultApi>(
      `/termopar/changeThermocoupleInstrument/${idTermopar1}/${idTermopar2}`
    );
  };

  const deleteOne = (id: number) => {
    return del<IResultApi>("/instrumentos/delete/" + id);
  };

  return {
    getAll,
    create,
    changeThermocoupleInstrument,
    deleteOne,
    getByIdsWithTemperature,
    getByIdsWithCurrentTemperature,
  };
};
