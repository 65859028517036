import { useApi } from "../";
import { TypeGraphics } from "../../../shared/enums/layoutContent/typeGraphics.enum";
import { TypeElement } from "../../../shared/enums/typeContent/typeElement.enum";
import { IDataForDashboard } from "../../../shared/interfaces/temperatures/temperatures";

export const useTemperatureService = () => {
  const { get, post, put } = useApi();

  const getHistoricTemperatureByTermopar = async (id_termopares: number[]) => {
    return post<any>("/temperatura/historic-by-termopares", id_termopares);
  };

  const getLastTemperatureByTermopares = async (id_termopares: number[]) => {
    return post<any>("/temperatura/last-by-termopares", id_termopares);
  };

  const getLastTemperatureByTermoparOnFilter = async (
    id_termopares: number[],
    date_initial: string,
    date_final: string
  ) => {
    const data = {
      id_termopares,
      date_initial,
      date_final,
    };
    return post<any>("/temperatura/historic-by-termopares-on-filter", data);
  };

  const getTemperatureCurrentAllInstruments = async () => {
    return await put<any>(
      "/temperatura/get-temperature-current-all-instruments"
    );
  };

  const getTemperatureCurrentByInstrument = async (idInstrument: number) => {
    return await put<any>(
      `/temperatura/get-temperature-current-by-instrument/${idInstrument}`
    );
  };

  const getTemperatureCurrentByGroup = async (idGroup: number) => {
    return await put<any>(
      `/temperatura/get-temperature-current-by-group/${idGroup}`
    );
  };

  const getCurrentTemperatureForVisualization = async (
    id: number,
    type: TypeElement
  ) => {
    return await put<any>(
      "/temperatura/get-current-temperature-for-visualization/" + id,
      {
        type: type,
      }
    );
  };

  const getCurrentTemperatureForGraphs = (
    graphs: { id: number; typeElement: TypeElement; typeGraph: TypeGraphics }[]
  ) => {
    return put<any[]>(
      "/temperatura/get-current-temperature-for-graphs",
      graphs
    );
  };

  const getTemperatureCurrentByThermocouples = async (
    idTermopares: number[]
  ) => {
    const data = {
      idTermopares: idTermopares,
    };
    return await put<any>(
      "/temperatura/get-temperature-current-by-thermocouples",
      data
    );
  };

  return {
    getCurrentTemperatureForVisualization,
    getCurrentTemperatureForGraphs,
    getHistoricTemperatureByTermopar,
    getLastTemperatureByTermopares,
    getLastTemperatureByTermoparOnFilter,
    getTemperatureCurrentAllInstruments,
    getTemperatureCurrentByInstrument,
    getTemperatureCurrentByGroup,
    getTemperatureCurrentByThermocouples,
  };
};
