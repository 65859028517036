import styled from "styled-components";

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
`;

export const ModalContainer = styled.div`
  background-color: #fff;
  border-radius: 24px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.9);
  width: 70vw;
  max-width: 80vw;
`;

export const ModalContent = styled.div`
  padding: 20px;
`;

export const HeaderModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 16px;
`;
