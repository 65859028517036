import * as yup from "yup";

export const schema = yup.object().shape({
  nome: yup.string().required("Nome Obrigatório"),
  time_collection: yup
    .number()
    .required("Tempo de coleta Obrigatório")
    .min(30, "O tempo de coleta deve ser maior que 30"),
  termopares: yup
    .array()
    .of(
      yup.object().shape({
        canal: yup.string().required(),
        tag: yup.string(),
        funcao: yup
          .string()
          .test(
            "funcao-required-if-tag",
            "Função do Instrumento Obrigatória",
            function (value) {
              return this.parent.tag ? !!value : true;
            }
          ),
        minima: yup
          .number()
          .test(
            "minima-required-if-tag",
            "Insira um valor Mínimo",
            function (value) {
              return this.parent.tag ? value !== undefined && value >= 1 : true;
            }
          ),
        maxima: yup
          .number()
          .test(
            "maxima-required-if-tag",
            "Insira um valor Máximo",
            function (value) {
              return this.parent.tag ? value !== undefined && value >= 1 : true;
            }
          ),
      })
    )
    .required(),
});
