import { useApi } from "../";
import { useAuthContext } from "../../../context/auth-context";

import { ILoginRequest } from "../../../shared/interfaces/login/login.request";
import { ILoginResponse } from "../../../shared/interfaces/login/login.response";
import {
  IUser,
  IUserChecked,
  IUserRequestBody,
  IUserUpdateCreate,
} from "../../../shared/interfaces/user/user.data";
import { IPaginateQuery } from "../../../shared/interfaces/api/api.paginate.request";
import { IResultApi } from "../../../shared/interfaces/api/api.response";

export const useUsersService = () => {
  const { post, put, get, del } = useApi();
  const { signIn, getToken, signOut } = useAuthContext();

  const create = async (data: IUserUpdateCreate) => {
    return post<IResultApi>("/user/create", data);
  };

  const update = async (data: IUserUpdateCreate, id: number) => {
    return put<IResultApi>(`/user/update/${id}`, data);
  };

  const deleteOne = async (id: number) => {
    return del<IResultApi>(`/user/delete/${id}`);
  };

  const getProfile = async () => {
    return get<IUser>(`/user/profile`);
  };

  const getById = async (id: number) => {
    return get<IUser>(`/user/find-one/${id}`);
  };

  const getAll = async ({ take, page }: IPaginateQuery) => {
    return get<IUserRequestBody>("/user/all", {
      params: { take, page },
    });
  };

  const getAllUsersForCheckbox = async () => {
    return get<IUserChecked[]>("/user/all-for-checkbox");
  };

  const updateIdObra = async (id: number, data: { id_obra: number }) => {
    return put<IResultApi>(`/user/update-id-obra/${id}`, data);
  };

  const checkUserInWork = async (data: number[]) => {
    return put<string[]>(`/user/check-in-work`, data);
  };

  const linkUsersToWork = async (id_obra: number, data: number[]) => {
    return put<IResultApi>(`/obras-users/link-users-work/${id_obra}`, data);
  };

  const postLogin = async ({ body }: ILoginRequest) => {
    return post<ILoginResponse>("/user/login", body).then(({ data }) => {
      signIn(data.access_token, data.user);
    });
  };

  const putLoginWithToken = async (): Promise<any> => {
    let oldToken = getToken();
    return put<ILoginResponse>("/token/refresh", { oldToken: oldToken })
      .then((response) => {
        const accessToken = response.data.access_token;
        const user = response.data.user;
        if (accessToken) {
          return signIn(accessToken, user);
        } else {
          return Promise.reject({
            status: false,
            message: "Token inválido",
          });
        }
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  };

  const putLogout = async () => {
    return put("/user/logout").then(() => {
      signOut();
    });
  };

  return {
    create,
    update,
    deleteOne,
    getProfile,
    postLogin,
    putLoginWithToken,
    putLogout,
    getAll,
    getAllUsersForCheckbox,
    getById,
    updateIdObra,
    linkUsersToWork,
    checkUserInWork,
  };
};
