export const formatDataForSelect = (data: any, replaceFields?: {newLabel: any, newValue: any}) => {

    if(replaceFields) {
        return data.map((item: any) => {
            return { label: item[replaceFields.newLabel], value: item[replaceFields.newValue] }
        })
    }

    return data.map((item: any, index: number) => {
        return { label: item.label, value: index }
    })
}