import React, { FunctionComponent } from "react";
import { FormContainer, HrLogin, ImgLogo, LoginContainer, TitleLogin } from "../login/styles";
import urlLogoBig from '../../assets/logo-big.png';
import { ROUTES } from "../../constants/routes";
import { Link, useNavigate } from "react-router-dom";
import { ButtonForSubmit } from "../../components/input/styles";
import { SubmitHandler, useForm } from "react-hook-form";
import CustomInput from "../../components/input";

interface ForgotPassWordProps {
    
}

interface IFormInput {
    email: string
}
 
const ForgotPassWord: FunctionComponent<ForgotPassWordProps> = () => {
    const navigate = useNavigate()
    const { register, handleSubmit, formState } = useForm<IFormInput>();
    const onSubmit: SubmitHandler<IFormInput> = (data) => {
           alert(JSON.stringify(data))
           navigate(ROUTES.RECOVER_PASSWORD)
    }
    return (
        <LoginContainer>
        <ImgLogo src={urlLogoBig} alt="logo"/>
        <FormContainer>
            <TitleLogin>Recuperar senha</TitleLogin>
            <HrLogin />
            <p style={{fontSize: '16px',fontWeight: '400',color: '#4B5563',textAlign: 'left', margin: '16px 0'}}>
                Enviaremos um link para redefinir sua senha por email
            </p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <CustomInput
                    label="Email"
                    type="email"
                    sizeInput="full"
                    fieldName="email"
                    errors={formState.errors.email}
                    schema={formState.errors.email ? "danger" : "default"}
                    placeholder="email@thermojet.com.br"
                    register={register}
                    options={{required: true}} 
                />
                <ButtonForSubmit type="submit" value="Redefinir senha" />
            </form>
            <HrLogin style={{margin: '40px 0'}}/>
            <Link to={ROUTES.LOGIN} style={{color: '#2563EB', textDecoration: 'none'}}>Voltar</Link>
        </FormContainer>
    </LoginContainer>
    );
}
 
export default ForgotPassWord;