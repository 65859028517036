import React, { FunctionComponent } from "react";
import { ContainerDropzone, TextDropzone } from "./styles";
import { useDropzone } from "react-dropzone";
import { MdFileUpload } from "react-icons/md";

interface DropzoneProps {
  setFile: React.Dispatch<React.SetStateAction<File[] | null>>;
  fullHeight?: boolean;
}

const CustomDropzone: FunctionComponent<DropzoneProps> = ({ setFile }) => {
  const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject } =
    useDropzone({
      accept: { "image/*": [] },
      maxFiles: 1,
      onDrop: (acceptedFiles) => {
        setFile(
          acceptedFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
            })
          )
        );
      },
    });

  return (
    <ContainerDropzone
      {...getRootProps({ isFocused, isDragAccept, isDragReject })}
      fullHeight
    >
      <input {...getInputProps()} />
      <div style={{ display: "block", textAlign: "center", cursor: "pointer" }}>
        <MdFileUpload
          fontSize={56}
          style={{
            border: "2px solid #000",
            color: "#000",
            padding: "8px",
            borderRadius: "50%",
          }}
        />
        <TextDropzone>Carregar imagem</TextDropzone>
      </div>
    </ContainerDropzone>
  );
};

export default CustomDropzone;
