import { FunctionComponent, useCallback } from "react";
import {
  BoxForStatusIcon,
  InstrumentBox,
  PointBox,
  PointLabel,
  PointTemperature,
  PointsContainer,
  TitleInstrument,
} from "./styles";
import { SiFireship } from "react-icons/si";
import { IoAlertCircleOutline } from "react-icons/io5";
import { FaRegSnowflake } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { IInstrumentData } from "../../pages/dashboard/interface/instruments.data";
import { EPointStatus } from "../../pages/dashboard/enum/points.enum";
import { Spinner } from "react-bootstrap";

interface InstrumentContainerProps extends IInstrumentData {}

const InstrumentContainer: FunctionComponent<InstrumentContainerProps> = ({
  label,
  points,
  id,
  type,
}) => {
  const navigate = useNavigate();

  const checkIcon = useCallback((status: EPointStatus) => {
    switch (status) {
      case "alert":
        return (
          <BoxForStatusIcon>
            <IoAlertCircleOutline color="#DC2626" />
          </BoxForStatusIcon>
        );
      case "cold":
        return (
          <BoxForStatusIcon>
            <FaRegSnowflake color="#3B82F6" />
          </BoxForStatusIcon>
        );
      case "hot":
        return (
          <BoxForStatusIcon>
            <SiFireship color="#B91C1C " />
          </BoxForStatusIcon>
        );
      case "ok":
        return <></>;
      default:
        return <></>;
    }
  }, []);

  const handleClickInstrument = (id: number, type: string) => {
    navigate(ROUTES.VISUALIZATION, { state: { id, type } });
  };

  return (
    <InstrumentBox onClick={() => handleClickInstrument(id, type)}>
      <TitleInstrument>{label}</TitleInstrument>
      <PointsContainer>
        {points.map((point, index) => (
          <PointBox key={index}>
            {point.temperature.endsWith("°C") ? (
              <PointTemperature status={point.status}>
                {point.temperature}
              </PointTemperature>
            ) : (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner
                  animation="border"
                  variant="primary"
                  style={{
                    width: "25px",
                    height: "25px",
                    borderWidth: "0.2em",
                  }}
                />
              </div>
            )}
            {checkIcon(point.status)}
            <PointLabel status={point.status}>{point.tag}</PointLabel>
          </PointBox>
        ))}
      </PointsContainer>
    </InstrumentBox>
  );
};

export default InstrumentContainer;
