import React, { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../../components/main-container";
import ContainerNewUser from "../../../components/forms-user-container";
import { useLocation } from "react-router-dom";
import { IUser } from "../../../shared/interfaces/user/user.data";
import { useUsersService } from "../../../hooks/api/user";

interface UpdateUserProps {}

const UpdateUser: FunctionComponent<UpdateUserProps> = () => {
  const { getById } = useUsersService();

  const location = useLocation();
  const UserId = location.state.id;
  const editProfile = location.state.editProfile;
  const [dataUpdateUser, setDataUpdateUser] = useState<IUser>();

  const getDataUser = async () => {
    await getById(UserId).then(({ data }) => {
      setDataUpdateUser(data);
    });
  };

  useEffect(() => {
    getDataUser();
  }, []);

  return (
    <MainContainer>
      <section className="d-flex justify-content-center align-items-center mb-3 mt-3">
        <ContainerNewUser
          dataUpdateUser={dataUpdateUser}
          editPerfil={editProfile}
        ></ContainerNewUser>
      </section>
    </MainContainer>
  );
};

export default UpdateUser;
