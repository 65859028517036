import styled from "styled-components";

export const styleCelsiusTemp = {
  backgroundColor: "#6B7280",
  color: "#fff",
};

export type TInputSchema = "default" | "danger";
export type TInputSize = "full" | "default";

export const InputContainer = styled.div`
  display: block;
  padding: 0;
  margin: 32px 0;
`;

export const StyledInput = styled.input<{
  schema: TInputSchema;
  sizeInput: TInputSize;
}>`
  outline: 0;
  font-size: 16px;
  color: #4b5563;
  background: #fff;
  padding: 0.375rem 0.75rem;
  border-radius: 4px;

  ${(props) => {
    switch (props.schema) {
      case "default":
        return `
          border: 1px solid #DFE4EA;
        `;
      case "danger":
        return `
          border: 1px solid #F23030;
        `;
      default:
        break;
    }
  }}

  ${(props) => {
    switch (props.sizeInput) {
      case "default":
        return `
          width: 240px;
        `;
      case "full":
        return `
          width: 100%;
        `;
      default:
        break;
    }
  }}
  
  ${(props) =>
    props.disabled &&
    `
    background-color: #e9ecef;
    cursor: not-allowed;
    border: 1px solid #ced4da;
  `}
`;

export const LabelForInput = styled.label`
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b5563;
`;

export const ButtonForSubmit = styled.input`
  cursor: pointer;
  width: 100%;
  padding: 16px;
  background: #3758f9;
  color: #fff;
  border: 0;
  font-size: 18px;
  border-radius: 4px;
`;

export const IconFieldContainer = styled.span<{
  schema: TInputSchema;
  sizeInput: TInputSize;
}>`
  display: flex;
  align-items: center;
  background: #fff;
  border-radius: 4px;

  ${(props) => {
    switch (props.schema) {
      case "default":
        return `
          border: 1px solid #DFE4EA;
        `;
      case "danger":
        return `
          border: 1px solid #F23030;
        `;
      default:
        break;
    }
  }}

  ${(props) => {
    switch (props.sizeInput) {
      case "default":
        return `
          width: 240px;
        `;
      case "full":
        return `
          width: 100%;
        `;
      default:
        break;
    }
  }}
`;

export const StyledInputNoBorder = styled.input<{ sizeInput: TInputSize }>`
  outline: 0;
  font-size: 16px;
  color: #4b5563;
  padding: 16px;
  border: 0;

  ${(props) => {
    switch (props.sizeInput) {
      case "default":
        return `
          width: 240px;
        `;
      case "full":
        return `
          width: 100%;
        `;
      default:
        break;
    }
  }}
`;

export const IconBox = styled.span`
  cursor: pointer;
  display: inline-flex;
  height: fit-content;
  padding: 16px;
  font-size: 22px;
`;

export const InputMessageError = styled.span`
  margin-top: 8px;
  width: 100%;
  color: #f23030;
  font-size: 14px;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const TemperaturaInputGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const TemperaturaInputGroupText = styled.span`
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
`;

export const TemperaturaFormControl = styled.input`
  flex: 1 1 auto;
  width: 1%;
  margin-bottom: 0;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  padding: 0.375rem 0.75rem;

  ${(props) =>
    props.disabled &&
    `
    background-color: #e9ecef;
    cursor: not-allowed;
    border: 1px solid #ced4da;
  `}
`;
