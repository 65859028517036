import React, { useRef, useState } from "react";
import {
  DropdownStyle,
  DropdownItem,
  SelectContainer,
  SelectLabelButton,
  SelectMessageError,
  TSelectSchema,
  TSelectSize,
  LabelForSelect,
} from "./styles";
import { useOnClickOutside } from "usehooks-ts";
import { FaCaretDown } from "react-icons/fa";
import {
  Controller,
  RegisterOptions,
  FieldValues,
  FieldPath,
  Control,
} from "react-hook-form";
import { FiAlertCircle } from "react-icons/fi";

type TItem = {
  label: string;
  value: any;
};

export interface SelectProps<TFieldValues extends FieldValues> {
  name: FieldPath<TFieldValues>;
  control?: Control<TFieldValues>;
  label: string;
  validationMessage?: string | undefined;
  disabled?: boolean;
  options: SelectOptions[];
  id?: string;
  defaultOption: string | TItem;
  schema?: TSelectSchema;
  sizeInput: TSelectSize;
  rules: RegisterOptions;
  setSelectValue?: React.Dispatch<React.SetStateAction<any>>;
  // SERVE PARA DECLARAR UMA USESTATE DE OUTRA DOCUMENTO, E ADD UM VALOR DESSE DOCUMENTO NO USESTATE DO OUTRO DOCUMENTO
}

export interface SelectOptions {
  value: any;
  label: string;
}

const Select = <TFieldValues extends FieldValues>({
  id,
  label,
  disabled = false,
  validationMessage = "",
  control,
  options,
  name,
  schema,
  sizeInput,
  rules,
  defaultOption,
  setSelectValue,
}: SelectProps<TFieldValues>) => {
  const defaultValueForSelect =
    typeof defaultOption !== "string" ? defaultOption : null;
  const [itemSelected, setItemSelected] = useState<TItem | null>(
    defaultValueForSelect
  );
  const [open, setOpen] = useState(false);
  const selectRef = useRef(null);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClickOutside = () => {
    handleClose();
  };

  useOnClickOutside(selectRef, handleClickOutside);

  return (
    <Controller
      name={name}
      rules={rules}
      control={control}
      render={({ field: { onChange, value: valueSelected } }) => (
        <SelectContainer ref={selectRef}>
          <LabelForSelect>{label}</LabelForSelect>
          <SelectLabelButton
            onClick={handleOpen}
            schema={schema}
            sizeInput={sizeInput}
          >
            {itemSelected !== null
              ? itemSelected.label
              : (defaultOption as string)}
            <FaCaretDown />
          </SelectLabelButton>
          <DropdownStyle isVisible={open}>
            {options.map((item, index) => (
              <DropdownItem
                onClick={() => {
                  setSelectValue && setSelectValue(item.value);
                  setItemSelected(item);
                  onChange(item.value);
                  handleClose();
                }}
                active={
                  itemSelected !== null && item.value === itemSelected.value
                }
                key={index}
              >
                {item.label}
              </DropdownItem>
            ))}
          </DropdownStyle>
          {validationMessage && (
            <SelectMessageError>
              <FiAlertCircle size={18} />
              {validationMessage}
            </SelectMessageError>
          )}
        </SelectContainer>
      )}
    />
  );
};

export default Select;
