import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Row,
  Container,
  Col,
  Card,
  OverlayTrigger,
  Tooltip,
  Spinner,
} from "react-bootstrap";
import { GrUploadOption } from "react-icons/gr";
import CustomLineChart from "../../components/charts/custom-line-chart";
import TemperatureChart from "../../components/charts/temperature-chart";
import ChartContainer from "../../components/chart-container";
import MainContainer from "../../components/main-container";
import HeaderSection from "../../components/header-section";
import Select from "../../components/select";
import Button from "../../components/button";
import CustomAreaChart from "../../components/charts/custom-area-chart";
import {
  BoxForNewScreen,
  BoxUpload,
  CheckboxContainer,
  FilterContainer,
  Line,
  TextForUpload,
} from "./styles";
import { ROUTES } from "../../constants/routes";
import CheckboxPoint from "../../components/checkbox-points";
import { SubmitHandler, useForm } from "react-hook-form";
import { TypeElement } from "../../shared/enums/typeContent/typeElement.enum";
import { useInstrumentsService } from "../../hooks/api/instruments";
import { useGroupsService } from "../../hooks/api/groups";
import { TypeGraphics } from "../../shared/enums/layoutContent/typeGraphics.enum";
import { GreyContainer } from "../../components/grey-container/styles";
import { Image, Layer, Stage } from "react-konva";
import { statusTermopar } from "../../shared/enums/termopar/statusTermopar.enum";
import { useQuadrosService } from "../../hooks/api/quadros";
import {
  BoxForStatusIcon,
  PointBox,
  PointLabel,
  PointTemperature,
} from "../../components/instrument-container/styles";
import { IoAlertCircleOutline } from "react-icons/io5";
import { FaRegSnowflake, FaRepeat } from "react-icons/fa6";
import { SiFireship } from "react-icons/si";
import { EPointStatus } from "../dashboard/enum/points.enum";
import { ITermopar } from "../../shared/interfaces/termopares/termopares";
import Modal from "../../components/modal";
import ChangeThermocoupleContainer from "../../components/change-thermocouple-container";
import { useTemperatureService } from "../../hooks/api/temperature";
import { useTermoparesService } from "../../hooks/api/termopares";
import { url } from "inspector";

interface IFormInput {
  group: string;
  instrument: string;
}

interface SelectOptions {
  value: any;
  label: string;
}

interface IFormInput {
  element: string;
  group: string;
  instrument: string;
}

interface DataPoints {
  id_termopar: number;
  tag: string;
  x: number;
  y: number;
  min: number;
  max: number;
  index: number;
  status: statusTermopar;
  statusPoint: EPointStatus;
  temperature: number | null;
}

interface VisualizationProps {}

const Visualization: FunctionComponent<VisualizationProps> = () => {
  const { getByObrasInstrumentos, getOneInstrument } = useInstrumentsService();
  const { getByObrasGrupos, getOneGroup } = useGroupsService();
  const { getByInstrumentoOrGrupo } = useQuadrosService();
  const { getCurrentTemperatureForVisualization } = useTemperatureService();
  const { getTemperatureCurrentByThermocouples } = useTemperatureService();
  const { handleSubmit, formState, control } = useForm<IFormInput>();

  const location = useLocation();
  const navigate = useNavigate();
  const greyContainerRef = useRef<HTMLDivElement>(null);

  const [dataTemperatureBarGraph, setDataTemperatureBarGraph] = useState<
    number[]
  >([]);
  const [dataTemperatureLineGraph, setDataTemperatureLineGraph] = useState<
    any[]
  >([]);
  const [dataTemperatureRealTime, setDataTemperatureRealTime] = useState<
    number[]
  >([]);

  const [dataInoperanteTermopares, setDataInoperanteTermopares] = useState<
    ITermopar[]
  >([] as ITermopar[]);

  const [selectChangeIdTermopar, setSelectChangeIdTermopar] =
    useState<number>();

  const [dataPoints, setDataPoints] = useState<DataPoints[]>(
    [] as DataPoints[]
  );

  const [showCharts, setShowCharts] = useState({
    barGraph: true,
    history: true,
    realTime: true,
  });

  const [typeElement, setTypeElement] = useState<TypeElement>(
    location.state.type
  ); //Element => Instrumento ou Grupo
  const [idElement, setIdElement] = useState<number>(location.state.id); //Id do elemento
  const [dataFilterInstrumentos, setDataFilterInstrumentos] = useState<
    SelectOptions[]
  >([]);
  const [dataFilterGrupos, setDataFilterGrupos] = useState<SelectOptions[]>([]);
  const [selectFilterElement, setSelectFilterElement] = useState<TypeElement>();
  const [selectFilterId, setSelectFilterId] = useState<number>(0);
  const [show, setShow] = useState<boolean>(false);
  const [cursorMove] = useState(false);
  const [resizedWidth, setResizedWidth] = useState<number>();
  const [resizedHeight, setResizedHeight] = useState<number>();
  const [imgUrl, setImageUrl] = useState<string | null>(null);
  const [isRequest, setIsRequest] = useState<boolean>(true);

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    alert(JSON.stringify(data));
  };

  const elementOptions: SelectOptions[] = [
    { value: TypeElement.INSTRUMENTO, label: TypeElement.INSTRUMENTO },
    { value: TypeElement.GRUPO, label: TypeElement.GRUPO },
  ];

  function handleNavigateSetPoints(id: number, type: TypeElement) {
    navigate(ROUTES.SET_POINTS, { state: { id, type } });
  }

  function setValueFilter() {
    setDataPoints([] as DataPoints[]);
    setImageUrl("");
    setTypeElement(selectFilterElement!);
    setIdElement(selectFilterId);
  }

  async function getDataQuadroFigure() {
    let dataInstrumentsMap: DataPoints[] = [] as DataPoints[];
    let port = process.env.PORT || 3000;
    let data = {
      type: typeElement,
    };
    await getByInstrumentoOrGrupo(idElement, data).then(async ({ data }) => {
      if (data) {
        let filePath = `http://localhost:${port}/public/upload_figure/${data.url_imagem}`;

        setImageUrl(filePath);

        data.quadros_termopares.forEach((quadros, index) => {
          let Status: EPointStatus;
          const temperatura =
            quadros.termopar!.temperatura &&
            quadros.termopar!.temperatura.length > 0
              ? quadros.termopar!.temperatura[0].valor
              : null;

          if (temperatura !== null) {
            if (quadros.termopar!.status === statusTermopar.INOPERANTE) {
              Status = EPointStatus.ALERT;
            } else if (temperatura! < quadros.termopar!.minima) {
              Status = EPointStatus.COLD;
            } else if (temperatura! > quadros.termopar!.maxima) {
              Status = EPointStatus.HOT;
            } else {
              Status = EPointStatus.DEFAULT;
            }
          } else {
            Status = EPointStatus.DEFAULT;
          }

          dataInstrumentsMap.push({
            id_termopar: quadros.id_termopar,
            tag: quadros.termopar?.tag!,
            x: quadros.posicao_x,
            y: quadros.posicao_y,
            min: quadros.termopar?.minima!,
            max: quadros.termopar?.maxima!,
            index: index + 1,
            status: quadros.termopar!.status,
            statusPoint: Status,
            temperature: temperatura,
          });
        });
      }
    });
    setDataPoints(dataInstrumentsMap);
    setIsRequest(false);
  }

  async function setDataTemperatureFigure(temperatures: number[]) {
    if (temperatures.length > 0) {
      let resultDataPoints: DataPoints[] = [];
      dataPoints.forEach((point, index) => {
        const temperatura = temperatures[index];
        let statusPoint: EPointStatus;

        if (temperatura !== 0) {
          if (temperatura! < point.min) {
            statusPoint = EPointStatus.COLD;
          } else if (temperatura! > point.max) {
            statusPoint = EPointStatus.HOT;
          } else {
            statusPoint = EPointStatus.DEFAULT;
          }
        } else {
          statusPoint = EPointStatus.ALERT;
        }

        point.temperature = temperatura;
        point.statusPoint = statusPoint;
        resultDataPoints.push(point);
      });
      setDataPoints(resultDataPoints);
    }
  }

  async function getDataFilters() {
    await getByObrasInstrumentos().then(({ data }) => {
      if (data) {
        let dataInstrumentsMap = data.map((item) => ({
          value: item.id,
          label: item.nome,
        }));
        setDataFilterInstrumentos(dataInstrumentsMap);
      }
    });

    await getByObrasGrupos().then(({ data }) => {
      if (data) {
        let dataGroupMap = data.map((item) => ({
          value: item.id,
          label: item.nome,
        }));
        setDataFilterGrupos(dataGroupMap);
      }
    });

    // setIsRequestFilter(false);
  }

  const getDataInoperanteTermopar = async () => {
    if (typeElement === TypeElement.INSTRUMENTO) {
      await getOneInstrument(idElement).then(({ data }) => {
        const inoperanteTermopares = data.termopares.filter(
          (termopar) => termopar.status === statusTermopar.INOPERANTE
        );
        setDataInoperanteTermopares(inoperanteTermopares);
      });
    } else {
      await getOneGroup(idElement).then(({ data }) => {
        const inoperanteTermopares = data.termopares.filter(
          (termopar) => termopar.status === statusTermopar.INOPERANTE
        );
        setDataInoperanteTermopares(inoperanteTermopares);
      });
    }
  };

  async function getCurrentTemperature() {
    await getCurrentTemperatureForVisualization(idElement, typeElement).then(
      (response) => {
        const temperaturesData = response.data;
        setDataTemperatureBarGraph(temperaturesData.dataTemperatureBarGraph);
        setDataTemperatureLineGraph(temperaturesData.dataTemperatureLineGraph);
        setDataTemperatureRealTime(temperaturesData.dataTemperatureRealTime);

        if (dataPoints && dataPoints.length > 0) {
          setDataTemperatureFigure(temperaturesData.dataTemperatureRealTime);
        }
      }
    );
    setIsRequest(false);
  }

  const loadImage = (url: string) => {
    const image = new window.Image();
    image.src = url;
    if (resizedHeight === undefined) {
      image.onload = () => {
        if (greyContainerRef.current) {
          const containerWidth = greyContainerRef.current.offsetWidth - 15;
          const containerHeight = greyContainerRef.current.offsetHeight - 15;

          const scaleFactor = Math.min(
            containerWidth / image.width,
            containerHeight / image.height
          );

          const resizedWidth = image.width * scaleFactor;
          const resizedHeight = image.height * scaleFactor;

          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = resizedWidth;
          canvas.height = resizedHeight;
          context?.drawImage(image, 0, 0, resizedWidth, resizedHeight);

          const resizedImageSrc = canvas.toDataURL();
          const newImage = new window.Image();
          newImage.src = resizedImageSrc;

          const originalWidth = image.width;
          const originalHeight = image.height;
          const scaleX = resizedWidth / originalWidth;
          const scaleY = resizedHeight / originalHeight;

          const adjustedDataPoints = dataPoints.map((point) => ({
            ...point,
            x: point.x * scaleX,
            y: point.y * scaleY,
          }));

          setResizedWidth(resizedWidth);
          setResizedHeight(resizedHeight);
          setDataPoints(adjustedDataPoints);

          return { newImage };
        }
      };
    } else {
      image.width = resizedWidth!;
      image.height = resizedHeight!;
    }
    return image;
  };

  // const isRequest = () => {
  //   return !(
  //     show ||
  //     isRequestCurrentTemperature ||
  //     isRequestFigure ||
  //     isRequestInoperanteTermopar
  //   );
  // };

  const checkIcon = useCallback((status: EPointStatus) => {
    switch (status) {
      case "alert":
        return (
          <BoxForStatusIcon>
            <IoAlertCircleOutline color="#DC2626" />
          </BoxForStatusIcon>
        );
      case "cold":
        return (
          <BoxForStatusIcon>
            <FaRegSnowflake color="#3B82F6" />
          </BoxForStatusIcon>
        );
      case "hot":
        return (
          <BoxForStatusIcon>
            <SiFireship color="#B91C1C " />
          </BoxForStatusIcon>
        );
      case "ok":
        return <></>;
      default:
        return <></>;
    }
  }, []);

  function openModalChange(idTermopar: number) {
    setSelectChangeIdTermopar(idTermopar);
    setShow(true);
  }

  // 1° Pega os dados dos filtros
  useEffect(() => {
    if (isRequest) {
      getDataFilters(); // Pega todos instrumentos e grupos para o filtro
    }
  }, []);

  // 2° Pega os dados de termopares inoperantes
  useEffect(() => {
    if (dataFilterGrupos.length > 0 || dataFilterInstrumentos.length > 0) {
      // setIsRequestInoperanteTermopar(true);
      getDataInoperanteTermopar(); //Pegar os termopar inoperante
    }
  }, [dataFilterGrupos, dataFilterInstrumentos]);

  // 3° Pega os dados da figura
  useEffect(() => {
    getDataQuadroFigure(); // Pega os dados da figura
  }, [dataInoperanteTermopares]);

  useEffect(() => {
    if (!isRequest && !show) {
      getDataInoperanteTermopar(); //Pegar os termopar inoperante
    }
  }, [show, setShow]);

  useEffect(() => {
    const intervalTime = Number(process.env.TIME_SET_INTERVAL_CHARTS) || 2000;
    const interval = setInterval(() => {
      if (!isRequest && !show) {
        setIsRequest(true);
        getCurrentTemperature();
      }
    }, intervalTime);
    return () => clearInterval(interval);
  }, [dataPoints]);

  useEffect(() => {
    if (selectFilterId !== 0 && !isRequest && !show) {
      setValueFilter();
    }
  }, [selectFilterId, setSelectFilterId]);

  useEffect(() => {
    if (!isRequest && !show) {
      getDataQuadroFigure();
    }
  }, [idElement, typeElement, setIdElement, setTypeElement]);

  return (
    <MainContainer>
      <HeaderSection
        title="Visualização"
        // buttonRight={
        //   <Button
        //     sizeButton="md"
        //     action={handleCustomScreen}
        //     icon={<CiEdit />}
        //     schemaButton="out-primary"
        //   >
        //     Customizar Tela
        //   </Button>
        // }
      >
        <FilterContainer>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="d-flex" style={{ gap: "16px" }}>
              <Select
                defaultOption="Selecione uma opção"
                name="element"
                control={control}
                rules={{ required: "O campo é obrigatório" }}
                sizeInput="default"
                validationMessage={
                  formState.errors.element
                    ? formState.errors.element.message
                    : undefined
                }
                schema={formState.errors.element ? "danger" : "default"}
                options={elementOptions}
                label="Visualizar:"
                setSelectValue={setSelectFilterElement}
              />

              {selectFilterElement ? (
                selectFilterElement === TypeElement.INSTRUMENTO ? (
                  <Select
                    defaultOption={
                      dataFilterGrupos.length > 0
                        ? "Selecione o instrumento"
                        : "não há instrumentos cadastrados"
                    }
                    name="instrument"
                    rules={{ required: "O campo é obrigatório" }}
                    sizeInput="full"
                    validationMessage={
                      formState.errors.instrument
                        ? formState.errors.instrument.message
                        : undefined
                    }
                    schema={formState.errors.instrument ? "danger" : "default"}
                    control={control}
                    options={dataFilterInstrumentos}
                    label="Selecione um Instrumento"
                    setSelectValue={setSelectFilterId}
                  />
                ) : (
                  <Select
                    defaultOption={
                      dataFilterGrupos.length > 0
                        ? "Selecione o grupo"
                        : "não há grupos cadastrados"
                    }
                    name="group"
                    rules={{ required: "O campo é obrigatório" }}
                    sizeInput="full"
                    validationMessage={
                      formState.errors.group
                        ? formState.errors.group.message
                        : undefined
                    }
                    schema={formState.errors.group ? "danger" : "default"}
                    control={control}
                    options={dataFilterGrupos}
                    label="Selecione um grupo"
                    setSelectValue={setSelectFilterId}
                  />
                )
              ) : null}
            </div>
          </form>
        </FilterContainer>

        <Line />

        <CheckboxContainer>
          <CheckboxPoint
            text="Bar Graph"
            checked={showCharts.barGraph}
            action={() =>
              setShowCharts({ ...showCharts, barGraph: !showCharts.barGraph })
            }
          />
          <CheckboxPoint
            text="Tendência"
            checked={showCharts.realTime}
            action={() =>
              setShowCharts({ ...showCharts, realTime: !showCharts.realTime })
            }
          />
          <CheckboxPoint
            text="Histórico"
            checked={showCharts.history}
            action={() =>
              setShowCharts({ ...showCharts, history: !showCharts.history })
            }
          />
        </CheckboxContainer>
      </HeaderSection>

      <Container fluid className="my-5">
        {dataInoperanteTermopares.length > 0 && (
          <>
            <Card className="">
              <Card.Header>Termopares Inoperantes</Card.Header>
              <Card.Body>
                <Row className="justify-content-center">
                  {dataInoperanteTermopares.map((termopar, index) => (
                    <Col
                      key={index}
                      className="d-flex flex-column align-items-center"
                    >
                      <PointBox>
                        <PointTemperature status={EPointStatus.ALERT}>
                          {"Ino..."}
                        </PointTemperature>
                        {checkIcon(EPointStatus.ALERT)}
                        <PointLabel status={EPointStatus.ALERT}>
                          {termopar.tag}
                        </PointLabel>
                      </PointBox>
                      <Button
                        sizeButton="md"
                        schemaButton="full-primary"
                        type="button"
                        action={() => {
                          openModalChange(termopar.id);
                        }}
                        icon={<FaRepeat />}
                      ></Button>
                    </Col>
                  ))}
                </Row>
              </Card.Body>
            </Card>

            <Modal show={show} setShow={setShow}>
              <ChangeThermocoupleContainer
                idElement={idElement}
                typeElement={typeElement}
                idTermoparSelect={selectChangeIdTermopar!}
                show={show}
                setShow={setShow}
              />
            </Modal>
          </>
        )}

        <Row className="my-5">
          {dataTemperatureBarGraph.length > 0 &&
          dataTemperatureRealTime.length > 0 ? (
            <>
              {showCharts.barGraph && (
                <Col lg={6} md={12}>
                  <ChartContainer
                    title="BarGraph"
                    type={TypeGraphics.BARGRAPH}
                    idElement={idElement}
                    typeElement={typeElement}
                  >
                    <TemperatureChart
                      id={idElement}
                      type={typeElement}
                      dataTemperature={dataTemperatureBarGraph}
                    />
                  </ChartContainer>
                </Col>
              )}
              {showCharts.realTime && (
                <Col lg={6} md={12}>
                  <ChartContainer
                    title="Tempo real"
                    type={TypeGraphics.TENDENCIA}
                    idElement={idElement}
                    typeElement={typeElement}
                  >
                    <CustomAreaChart
                      id={idElement}
                      type={typeElement}
                      dataTemperatures={dataTemperatureRealTime}
                    />
                  </ChartContainer>
                </Col>
              )}
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner
                animation="border"
                variant="primary"
                style={{
                  width: "100px",
                  height: "100px",
                  borderWidth: "0.2em",
                }}
              />
            </div>
          )}
        </Row>

        <Row className="my-5">
          {dataTemperatureLineGraph.length > 0 &&
          dataTemperatureRealTime.length > 0 ? (
            <>
              {showCharts.history && (
                <Col lg={6} md={12}>
                  <ChartContainer
                    title="Histórico"
                    type={TypeGraphics.HISTORICO}
                    idElement={idElement}
                    typeElement={typeElement}
                  >
                    <CustomLineChart
                      id={idElement}
                      type={typeElement}
                      dataTemperature={dataTemperatureLineGraph}
                    />
                  </ChartContainer>
                </Col>
              )}
              <Col lg={6} md={12}>
                <ChartContainer
                  title="Visualização"
                  type={TypeGraphics.FIGURE}
                  idElement={idElement}
                  typeElement={typeElement}
                  fullScreen={false}
                >
                  {dataPoints && dataPoints.length > 0 ? (
                    <>
                      <GreyContainer
                        ref={greyContainerRef}
                        style={{ position: "relative" }}
                      >
                        <Stage
                          width={resizedWidth ? resizedWidth : 1800}
                          height={resizedHeight ? resizedHeight : 800}
                          style={{ cursor: cursorMove ? "move" : "default" }}
                        >
                          <Layer>
                            <Image image={loadImage(imgUrl!)} />
                          </Layer>
                        </Stage>
                        {dataPoints.map((point, index) => (
                          <OverlayTrigger
                            key={index}
                            placement="auto-start"
                            overlay={
                              <Tooltip id={`tooltip-${index}`}>
                                <strong>{point.tag}</strong>.
                              </Tooltip>
                            }
                          >
                            <div
                              key={index}
                              style={{
                                position: "absolute",
                                top: point.y,
                                left: point.x,
                              }}
                            >
                              <PointBox>
                                <PointTemperature
                                  status={point.statusPoint}
                                  fontSize={12}
                                >
                                  {point.temperature !== null
                                    ? `${point.temperature}°C`
                                    : "N/A"}
                                </PointTemperature>
                                {checkIcon(point.statusPoint)}
                                {/* <PointLabel status={point.statusPoint}>
                            {point.tag}
                          </PointLabel> */}
                              </PointBox>
                            </div>
                          </OverlayTrigger>
                        ))}
                      </GreyContainer>
                      <BoxForNewScreen>
                        <Button
                          schemaButton="out-primary"
                          sizeButton="md"
                          action={() =>
                            handleNavigateSetPoints(idElement, typeElement)
                          }
                        >
                          Nova Tela
                        </Button>
                      </BoxForNewScreen>
                    </>
                  ) : (
                    <>
                      <BoxUpload
                        onClick={() =>
                          handleNavigateSetPoints(idElement, typeElement)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <div style={{ textAlign: "center" }}>
                          <GrUploadOption
                            fontSize={40}
                            color="#2563EB"
                            className="mb-3"
                          />
                          <TextForUpload>Carregar imagem</TextForUpload>
                        </div>
                      </BoxUpload>
                    </>
                  )}
                </ChartContainer>
              </Col>
            </>
          ) : (
            <div className="d-flex justify-content-center align-items-center">
              <Spinner
                animation="border"
                variant="primary"
                style={{
                  width: "100px",
                  height: "100px",
                  borderWidth: "0.2em",
                }}
              />
            </div>
          )}
        </Row>
      </Container>
    </MainContainer>
  );
};

export default Visualization;
