import { useApi } from "../";

import { ICreateClients } from "../../../shared/interfaces/clients/clients.create";
import { IResultApi } from "../../../shared/interfaces/api/api.response";
import { IClients } from "../../../shared/interfaces/clients/clients";

export const useClientsService = () => {
  const { post, get } = useApi();

  const getAllForDropdown = async () => {
    return get<IClients[]>("/clientes/all");
  };

  const create = async (body: ICreateClients) => {
    return post<IResultApi>("/clientes/create", body);
  };

  return {
    create,
    getAllForDropdown,
  };
};
