import React, { FunctionComponent, useState } from "react";
import {
  FormContainer,
  HrLogin,
  ImgLogo,
  LoginContainer,
  TitleLogin,
} from "./styles";
import urlLogoBig from "../../assets/logo-big.png";
import CustomInput from "../../components/input";
import { useForm, SubmitHandler } from "react-hook-form";
import { ButtonForSubmit } from "../../components/input/styles";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useUsersService } from "../../hooks/api/user";
import { GoAlertFill } from "react-icons/go";
import toast from "react-hot-toast";
import { Spinner } from "react-bootstrap";

interface LoginProps {}

interface IFormInput {
  email: string;
  password: string;
}

const Login: FunctionComponent<LoginProps> = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const { register, handleSubmit, formState } = useForm<IFormInput>();
  const { postLogin } = useUsersService();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    postLogin({ body: data }).catch(({ response }) => {
      if (response.status === 401) {
        toast.error("Email ou senha incorretos", {
          duration: 4000,
          position: "top-center",
          style: {},
          className: "",
          icon: <GoAlertFill color="#B91C1C" />,
          iconTheme: {
            primary: "#B91C1C",
            secondary: "#fff",
          },
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
      } else {
        toast.error("Erro interno, tente Novamente mais tarde.", {
          duration: 4000,
          position: "top-center",
          style: {},
          className: "",
          icon: <GoAlertFill color="#B91C1C" />,
          iconTheme: {
            primary: "#B91C1C",
            secondary: "#fff",
          },
          ariaProps: {
            role: "status",
            "aria-live": "polite",
          },
        });
      }
    });
    setIsLogin(false);
  };

  //
  return (
    <LoginContainer>
      <ImgLogo src={urlLogoBig} alt="logo" />
      <FormContainer>
        <TitleLogin>Login</TitleLogin>
        <HrLogin />
        <form onSubmit={handleSubmit(onSubmit)}>
          <CustomInput
            label="Email"
            type="email"
            sizeInput="full"
            fieldName="email"
            errors={formState.errors.email}
            schema={formState.errors.email ? "danger" : "default"}
            placeholder="email@thermojet.com.br"
            register={register}
            options={{ required: true }}
          />
          <CustomInput
            label="Senha"
            sizeInput="full"
            fieldName="password"
            errors={formState.errors.password}
            schema={formState.errors.password ? "danger" : "default"}
            type={showPassword ? "text" : "password"}
            register={register}
            options={{ required: true }}
            icon={
              showPassword ? (
                <AiOutlineEyeInvisible
                  onClick={() => {
                    setIsLogin(true);
                    setShowPassword(false);
                  }}
                />
              ) : (
                <AiOutlineEye onClick={() => setShowPassword(true)} />
              )
            }
          />
          {!isLogin ? (
            <ButtonForSubmit type="submit" value="Entrar" />
          ) : (
            <Spinner animation="border" />
          )}
        </form>
        <HrLogin style={{ margin: "40px 0" }} />
      </FormContainer>
    </LoginContainer>
  );
};

export default Login;
