import React, { FunctionComponent } from "react";

interface HalfSquareIconProps {
    width: string;
    height: string;
    color: string;
}

export const HalfSquareIcon: FunctionComponent<HalfSquareIconProps> = ({width, height, color}) => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 34 26" fill="none">
            <path d="M18.5455 0V26H34V0M0 26H15.4545V0H0V26Z" fill={color}/>
        </svg>
    );
}