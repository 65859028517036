import  { FunctionComponent, useEffect, useState } from "react";
import Table from "rc-table";
import { SubmitHandler, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import CustomInput from "../../../components/input";
import Button from "../../../components/button";
import MainContainer from "../../../components/main-container";
import { useToast } from "../../../hooks/toast";
import { useGroupsService } from "../../../hooks/api/groups";
import { IGroups } from "../../../shared/interfaces/groups/groups";
import { ROUTES } from "../../../constants/routes";
import "../../../styles/table.css";
import Swal, { SweetAlertOptions } from "sweetalert2";

interface GroupListProps {}

interface IFormInput {
  search: string;
}

const GroupList: FunctionComponent<GroupListProps> = () => {
  const take = 10;

  const [page, setPage] = useState(0);
  const [GroupsData, setGroupsData] = useState<IGroups>({} as IGroups);

  const navigate = useNavigate();
  const { handleSubmit, register, getValues, reset, watch } =
    useForm<IFormInput>();
  const { showErrorToast, showSuccessToast } = useToast();
  const { getAll, deleteOne, getOneGroup } = useGroupsService();

  const handleGetAllGroups = () => {
    getAll({
      take,
      page: getValues("search") ? 0 : page,
      search: getValues("search"),
    })
      .then(({ data }) => {
        setGroupsData(data);
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    handleGetAllGroups();
  };

  const handleCleanForm = () => {
    reset();
    handleGetAllGroups();
  };

  const handleNavigateUpdate = (id: number) => {
    navigate(ROUTES.UPDATE_GROUP, { state: { id } });
  };

  const handleDelete = (id: number) => {
    deleteOne(id)
      .then(() => {
        showSuccessToast("Grupo excluído com sucesso");
      })
      .finally(() => {
        handleGetAllGroups();
      });
  };

  const handleShowDelete = (id: number) => {
    getOneGroup(id)
      .then(({ data }) => {
        if (data) {
          const alertOptions: SweetAlertOptions = {
            title: `Tem certeza que deseja excluir o grupo ${data.nome}`,
            text: "A ação poderá afetar outros registros.",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: "Não",
          };

          Swal.fire(alertOptions).then((result) => {
            if (result.isConfirmed) {
              handleDelete(data.id);
            }
          });
        }
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  useEffect(() => {
    handleGetAllGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
      width: 200,
    },
    {
      title: "Nome",
      dataIndex: "nome",
      key: "nome",
      width: 300,
    },
    {
      title: "Obra",
      dataIndex: "obra",
      key: "obra",
      width: 1000,
    },
    {
      title: "Ações",
      dataIndex: "id",
      key: "id",
      render: (id: number) => (
        <div style={{ display: "flex", gap: "16px" }}>
          <Button
            schemaButton="out-primary"
            sizeButton="md"
            action={() => handleNavigateUpdate(id)}
          >
            Editar
          </Button>
          <Button
            schemaButton="out-danger"
            sizeButton="md"
            action={() => handleShowDelete(id)}
          >
            Excluir
          </Button>
        </div>
      ),
    },
  ];

  return (
    <>
      <MainContainer>
        <div className="d-flex justify-content-between align-items-center">
          <Button
            schemaButton="full-primary"
            sizeButton="md"
            action={() => navigate(ROUTES.NEW_GROUP)}
          >
            Novo grupo
          </Button>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="d-flex align-items-center"
            style={{ gap: "16px" }}
          >
            <CustomInput
              label="Pesquisa"
              type="text"
              sizeInput="default"
              fieldName="search"
              errors={undefined}
              schema="default"
              placeholder="Pesquisar"
              register={register}
              options={{}}
            />
            <Button
              schemaButton="full-primary"
              sizeButton="md"
              type="submit"
              className="mt-4"
            >
              Pesquisar
            </Button>
            <Button
              schemaButton={watch("search") ? "out-danger" : "full-danger"}
              sizeButton="md"
              action={handleCleanForm}
              className="mt-4"
              disabled={watch("search") ? false : true}
            >
              Limpar
            </Button>
          </form>
        </div>
        <Table columns={columns} data={GroupsData.data} className="mt-3" />
        <div className="d-flex w-100 justify-content-center align-items-center mt-4">
          <ReactPaginate
            containerClassName={"pagination"}
            activeClassName={"active"}
            pageClassName={"page-item"}
            nextClassName={"next-label"}
            previousClassName={"previous-label"}
            onPageChange={(event) => setPage(event.selected)}
            breakLabel="..."
            pageCount={Math.ceil(GroupsData.total_count / take)}
            previousLabel={<MdOutlineArrowBackIos color="#4B5563" />}
            nextLabel={<MdOutlineArrowForwardIos color="#4B5563" />}
          />
        </div>
      </MainContainer>
    </>
  );
};

export default GroupList;
