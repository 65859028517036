import React, { FunctionComponent, useState, useEffect } from "react";
import {
  BellIcon,
  NotificationBox,
  TotalNotification,
  NotificationList,
  NotificationItem,
  ClosedIcon,
  AlertIcon,
} from "./styles";
import { useAlarmesService } from "../../hooks/api/alarms";
import { StatusAlarmes } from "../../shared/enums/alarmes/statusAlarmes.enum";
import { IAlarmes } from "../../shared/interfaces/alarmes/alarmes";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

interface NotificationDropdownProps {
  showDropdown: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
  setShowNotificationBar: React.Dispatch<React.SetStateAction<boolean>>;
}

const NotificationDropdown: FunctionComponent<NotificationDropdownProps> = ({
  showDropdown,
  setShowDropdown,
  setShowNotificationBar,
}) => {
  const { getAll } = useAlarmesService();
  const take: number = 99;
  const page: number = 0;
  const [notifications, setNotifications] = useState<string[]>([]);
  const [isRequest, setIsRequest] = useState<boolean>(false);

  const getAlertAlarmsTermopar = async () => {
    try {
      const response = await getAll({ take, page });
      const data: IAlarmes[] = response.data.data;
      const activeAlarms = data
        .filter((alarm) => alarm.status === StatusAlarmes.ATIVO)
        .map((alarm) => alarm.descricao);
      setNotifications(activeAlarms);

      setShowNotificationBar(activeAlarms.length > 0);
    } catch (err) {
      console.error(err);
    }
    setIsRequest(false);
  };

  useEffect(() => {
    if (!isRequest) {
      setIsRequest(true);
      getAlertAlarmsTermopar();
    }
  }, [page]);

  useEffect(() => {
    const intervalTime =
      Number(process.env.TIME_SET_INTERVAL_DASHBOARD) || 2000;
    const interval = setInterval(() => {
      if (!isRequest) {
        setIsRequest(true);
        getAlertAlarmsTermopar();
      }
    }, intervalTime);
    return () => clearInterval(interval);
  });

  const navigate = useNavigate();

  const handleNavigateAlarmes = () => {
    navigate(ROUTES.ALARM);
  };

  return (
    <OverlayTrigger
      placement="bottom"
      overlay={
        <Tooltip id={`tooltip-alarms`}>
          <strong>
            {notifications.length > 0
              ? `Possui ${notifications.length} alarme(s) ativo(s) !`
              : "Não há notificações de alarme no momento !"}
          </strong>
        </Tooltip>
      }
    >
      <div style={{ position: "relative" }}>
        <NotificationBox
          onClick={() =>
            setShowDropdown(notifications.length > 0 ? !showDropdown : false)
          }
        >
          {showDropdown ? (
            <ClosedIcon fontSize={24} />
          ) : (
            <BellIcon fontSize={24} />
          )}
          {notifications.length > 0 && (
            <TotalNotification>{notifications.length}</TotalNotification>
          )}
        </NotificationBox>
        {showDropdown && (
          <NotificationList>
            {notifications.map((notification, index) => (
              <NotificationItem
                onClick={() => {
                  handleNavigateAlarmes();
                }}
                key={index}
              >
                {notification}
              </NotificationItem>
            ))}
          </NotificationList>
        )}
      </div>
    </OverlayTrigger>
  );
};

export default NotificationDropdown;
