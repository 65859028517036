import React, { FunctionComponent } from "react";
import { DisplaySquares } from "./styles";
import { ContainerLayoutSquare, FigureLayout } from "../grid-layout/styles";
import { HalfSquareIcon, SquareIcon, SplitSquareIcon } from "../icons";
import { TypeContent } from "../../shared/enums/layoutContent/typeContent.enum";

interface SelectLayoutActionProps {
  setVisualization: React.Dispatch<React.SetStateAction<TypeContent | null>>;
}

const SelectLayoutAction: FunctionComponent<SelectLayoutActionProps> = ({
  setVisualization,
}) => {
  return (
    <DisplaySquares>
      <ContainerLayoutSquare>
        <FigureLayout onClick={() => setVisualization(TypeContent.SINGLE)}>
          <SquareIcon width="150" height="100" color="#3758F9" />
        </FigureLayout>
        <FigureLayout onClick={() => setVisualization(TypeContent.HALF)}>
          <HalfSquareIcon width="150" height="100" color="#3758F9" />
        </FigureLayout>
        <FigureLayout onClick={() => setVisualization(TypeContent.QUARTER)}>
          <SplitSquareIcon width="150" height="100" color="#3758F9" />
        </FigureLayout>
      </ContainerLayoutSquare>
    </DisplaySquares>
  );
};

export default SelectLayoutAction;
