import React, { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../../components/main-container";
import Table from "rc-table";
import Button from "../../../components/button";
import { useNavigate } from "react-router";
import { ROUTES } from "../../../constants/routes";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useUsersService } from "../../../hooks/api/user";
import {
  IUser,
  IUserRequestBody,
} from "../../../shared/interfaces/user/user.data";
import { useToast } from "../../../hooks/toast";
import Swal, { SweetAlertOptions } from "sweetalert2";

interface ListUsersProps {}

const ListUsers: FunctionComponent<ListUsersProps> = () => {
  const take = 10;
  const [page, setPage] = useState(0);

  const { getAll, getById, deleteOne } = useUsersService();
  const { showErrorToast, showSuccessToast } = useToast();

  const [usersData, setUsersData] = useState<IUserRequestBody>(
    {} as IUserRequestBody
  );
  const [filterData, setFilterData] = useState<IUser[]>([]);

  const getAllUsers = async () => {
    await getAll({
      take,
      page: page,
    })
      .then((response) => {
        setUsersData(response.data);
        let dataFilter = response.data.data;
        setFilterData(dataFilter);

      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const handleNavigateUpdate = (id: number) => {
    navigate(ROUTES.UPDATE_USER, { state: { id } });
  };

  const handleShowDelete = (id: number) => {
    getById(id)
      .then(({ data }) => {
        if (data) {
          const alertOptions: SweetAlertOptions = {
            title: `Tem certeza que deseja excluir o usuário ${data.name}`,
            text: "A ação poderá afetar outros registros.",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: "Não",
          };

          Swal.fire(alertOptions).then((result) => {
            if (result.isConfirmed) {
              handleDelete(data.id);
            }
          });
        }
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const handleDelete = (id: number) => {
    deleteOne(id)
      .then(() => {
        showSuccessToast("Grupo excluído com sucesso");
      })
      .catch((err) => {
        showErrorToast(
          "Não foi possível excluir essa usuário, verifique se está ativo em uma obra !"
        );
      })
      .finally(() => {
        getAllUsers();
      });
  };

  useEffect(() => {
    getAllUsers();
    }, [page]);

  const navigate = useNavigate();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 500,
    },
    {
      title: "Email",
      dataIndex: "username",
      key: "username",
      width: 300,
    },
    {
      title: "Matricula",
      dataIndex: "matricula",
      key: "matricula",
      width: 200,
    },
    {
      title: "Departamento",
      dataIndex: "setor",
      key: "setor",
      width: 300,
    },
    {
      title: "Nivel de Acesso",
      dataIndex: "type",
      key: "type",
      width: 300,
    },
    {
      title: "Ações",
      dataIndex: "id",
      key: "id",

      render: (id: number) => (
        <div style={{ display: "flex", gap: "16px" }}>
          <Button
            schemaButton="out-primary"
            sizeButton="md"
            action={() => handleNavigateUpdate(id)}
          >
            Editar
          </Button>
          <Button
            schemaButton="out-danger"
            sizeButton="md"
            action={() => handleShowDelete(id)}
          >
            Excluir
          </Button>
        </div>
      ),
    },
  ];

  return (
    <MainContainer>
      <Button
        schemaButton="full-primary"
        sizeButton="md"
        action={() => navigate(ROUTES.NEW_USER)}
      >
        Novo Usuário
      </Button>
      <Table columns={columns} data={filterData} className="mt-3 text-center" />
      <div className="d-flex w-100 justify-content-center align-items-center mt-4">
        <ReactPaginate
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          nextClassName={"next-label"}
          previousClassName={"previous-label"}
          onPageChange={(event) => setPage(event.selected)}
          breakLabel="..."
          pageCount={Math.ceil(usersData!.total_count / take)}
          previousLabel={<MdOutlineArrowBackIos color="#4B5563" />}
          nextLabel={<MdOutlineArrowForwardIos color="#4B5563" />}
        />
      </div>
    </MainContainer>
  );
};

export default ListUsers;
