import axios from "axios";
import { ILoginResponse } from "../../shared/interfaces/login/login.response";
import { useAuthContext } from "../../context/auth-context";

export const useApi = () => {
  const { getToken, signIn, signOut } = useAuthContext();

  const baseUrl = process.env.REACT_APP_API_URL;

  const api = axios.create({
    baseURL: baseUrl,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    timeout: 30000,
  });

  api.interceptors.request.use(
    (config) => {
      const token = getToken();
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {}
  );

  api.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      return new Promise((resolve, reject) => {
        const originalReq = err.config;
        const statusCode = err?.response?.status;

        if (statusCode === 401 && err.config && !err.config._retry) {
          originalReq._retry = true;
          const token = getToken();
          if (token) {
            let res = api
              .put<ILoginResponse>(`${baseUrl}/token/refresh`, {
                oldToken: token,
              })
              .then((res) => {
                signIn(res.data.access_token, res.data.user);
                originalReq.headers[
                  "Authorization"
                ] = `Bearer ${res.data.access_token}`;
                return axios(originalReq);
              })
              .catch((error) => {
                console.log("Refresh err:", error);
                signOut();
              });
            resolve(res);
          } else {
            reject(err);
          }
        } else {
          reject(err);
        }
      });
    }
  );

  const { get, delete: del, post, put, patch } = api;

  return {
    get,
    del,
    post,
    put,
    patch,
  };
};
