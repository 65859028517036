import { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../components/main-container";
import Button from "../../components/button";
import CarouselCustomized from "../../components/carousel";
import HeaderSection from "../../components/header-section";
import InstrumentContainer from "../../components/instrument-container";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { IInstrumentData } from "./interface/instruments.data";
import { EPointStatus } from "./enum/points.enum";
import { IGroupsData } from "./interface/grupos.data";
import { Alert, Col, Container, Row, Spinner } from "react-bootstrap";
import ChartContainer from "../../components/chart-container";
import TemperatureChart from "../../components/charts/temperature-chart";
import CustomAreaChart from "../../components/charts/custom-area-chart";
import CustomLineChart from "../../components/charts/custom-line-chart";
import { TypeGraphics } from "../../shared/enums/layoutContent/typeGraphics.enum";
import { statusTermopar } from "../../shared/enums/termopar/statusTermopar.enum";
import { useToast } from "../../hooks/toast";
import { IAlarmes } from "../../shared/interfaces/alarmes/alarmes";
import { StatusAlarmes } from "../../shared/enums/alarmes/statusAlarmes.enum";
import { AddViewContainer } from "../select-layout/formats/styles";
import { LuPlusCircle } from "react-icons/lu";
import { TextGreyContainer } from "../../components/text-grey-container/styles";
import { TypeElement } from "../../shared/enums/typeContent/typeElement.enum";
import { useQuadrosService } from "../../hooks/api/quadros";
import { IQuadros } from "../../shared/interfaces/quadros/quadros";
import { useTemperatureService } from "../../hooks/api/temperature";
import { IDataForDashboard } from "../../shared/interfaces/temperatures/temperatures";
import { IInstrumentsTermopares } from "../../shared/interfaces/instruments/instruments";
import { IGroup } from "../../shared/interfaces/groups/groups";
import { instrumentsData } from "../../static/data/data";
import { PointTemperature } from "../../components/instrument-container/styles";
import { useInstrumentsService } from "../../hooks/api/instruments";
interface IDataGraphs {
  id: number;
  typeElement: TypeElement;
  typeGraph: TypeGraphics;
}
interface DashboardProps {}
const Dashboard: FunctionComponent<DashboardProps> = () => {
  const { getByObrasQuadros } = useQuadrosService();
  const {
    getTemperatureCurrentAllInstruments,
    getCurrentTemperatureForGraphs,
  } = useTemperatureService();
  const { getDataForDashboard } = useInstrumentsService();

  const [dataInstruments, setDataInstruments] = useState<IInstrumentData[]>([]);
  const [dataGrupos, setDataGrupos] = useState<IGroupsData[]>([]);
  const [dataQuadros, setDataQuadros] = useState<IQuadros>({} as IQuadros);
  const [dataGraphs, setDataGraphs] = useState<IDataGraphs[]>(
    [] as IDataGraphs[]
  );
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const { showErrorToast, showSuccessToast } = useToast();

  function setDataInstrumentosForDashboard(
    instrumentos: IInstrumentsTermopares[]
  ) {
    if (instrumentos.length > 0) {
      let dataInstrumentsMap = instrumentos.map((item) => ({
        label: item.nome,
        id: item.id,
        type: TypeElement.INSTRUMENTO,
        slave: item.slave_number,
        points: item.termopares.map((temp) => {
          const minTemp = temp.minima;
          const maxTemp = temp.maxima;
          const temperatura =
            temp.temperatura && temp.temperatura.length > 0
              ? temp.temperatura[0].valor
              : null;

          let status;

          switch (true) {
            case temp.status === statusTermopar.INOPERANTE:
              status = EPointStatus.ALERT;
              break;
            case temperatura === null:
              status = EPointStatus.OK;
              break;
            case temperatura! < minTemp:
              status = EPointStatus.COLD;
              break;
            case temperatura! > maxTemp:
              status = EPointStatus.HOT;
              break;
            default:
              status = EPointStatus.OK;
          }

          return {
            tag: temp.tag,
            temperature:
              temp.temperatura && temp.temperatura.length > 0
                ? `${temp.temperatura[0].valor.toFixed(2)}°C`
                : "N / A",
            status: status,
            statusTermopar: temp.status,
            minino: minTemp,
            maximo: maxTemp,
          };
        }),
      }));
      setDataInstruments(dataInstrumentsMap);
    }
  }

  function setDataGruposForDashboard(grupos: IGroup[]) {
    if (grupos.length > 0) {
      let dataGroupMap = grupos.map((item) => ({
        label: item.nome,
        id: item.id,
        type: TypeElement.INSTRUMENTO,
        points: item.termopares.map((temp) => {
          const minTemp = temp.minima;
          const maxTemp = temp.maxima;
          const temperatura =
            temp.temperatura && temp.temperatura.length > 0
              ? temp.temperatura[0].valor
              : null;
          let status;

          switch (true) {
            case temp.status === statusTermopar.INOPERANTE:
              status = EPointStatus.ALERT;
              break;
            case temperatura === null:
              status = EPointStatus.OK;
              break;
            case temperatura! < minTemp:
              status = EPointStatus.COLD;
              break;
            case temperatura! > maxTemp:
              status = EPointStatus.HOT;
              break;
            default:
              status = EPointStatus.OK;
          }

          return {
            tag: temp.tag,
            temperature:
              temp.temperatura && temp.temperatura.length > 0
                ? `${temp.temperatura[0].valor.toFixed(2)}°C`
                : "N / A",
            status: status,
            statusTermopar: temp.status,
            minino: minTemp,
            maximo: maxTemp,
          };
        }),
      }));
      setDataGrupos(dataGroupMap);
    }
  }

  async function getDataDashboard() {
    getDataForDashboard()
      .then((result) => {
        let data: IDataForDashboard = result?.data;
        if (data !== undefined) {
          setDataInstrumentosForDashboard(data?.instrumentos);
          setDataGruposForDashboard(data?.grupos);
        } else {
          setDataInstrumentosForDashboard([]);
          setDataGruposForDashboard([]);
        }
      })
      .catch((error) => {
        console.log("Erro ao coletar dados:", error);
      });
    setIsRequest(false);
    setIsLoading(false);
  }

  async function getCurrentTemperature() {
    try {
      const temperaturaInstruments =
        await getTemperatureCurrentAllInstruments();
      const temperatures = temperaturaInstruments.data;
      if (dataInstruments.length > 0) {
        dataInstruments.forEach((instrument) => {
          const tempObject = temperatures.find(
            (obj: any) => obj[instrument.slave!] !== undefined
          );

          if (tempObject) {
            const temperaturesArray = tempObject[instrument.slave!];

            instrument.points.forEach((pointInstrument, index) => {
              if (temperaturesArray[index] !== undefined) {
                const newTemperature = `${temperaturesArray[index].toFixed(
                  2
                )}°C`;
                let status;
                switch (true) {
                  // case temp.status === statusTermopar.INOPERANTE:
                  //   status = EPointStatus.ALERT;
                  //   break;
                  case temperaturesArray[index] === null:
                    status = EPointStatus.OK;
                    break;
                  case temperaturesArray[index] < pointInstrument.minino:
                    status = EPointStatus.COLD;
                    break;
                  case temperaturesArray[index] > pointInstrument.maximo:
                    status = EPointStatus.HOT;
                    break;
                  default:
                    status = EPointStatus.OK;
                }

                pointInstrument.temperature = newTemperature;
                pointInstrument.status = status;
                dataGrupos.forEach((group) => {
                  group.points.forEach((pointGroup) => {
                    if (pointGroup.tag === pointInstrument.tag) {
                      pointGroup.temperature = newTemperature;
                      pointGroup.status = status;
                    }
                  });
                });
              } else {
                console.warn(`Temperature do index ${index} está vazia`);
              }
            });
          } else {
            console.warn(
              `Temperature data do endereço ${instrument.slave} está vazia`
            );
          }
        });
      }

      if (dataGraphs.length > 0) {
        const temperatureGraphs = await getCurrentTemperatureForGraphs(
          dataGraphs
        );
        const dataTemperature = dataQuadros.data.map((quadro, index) => ({
          ...quadro,
          dataTemperature: temperatureGraphs.data[index],
        }));

        setDataQuadros({ ...dataQuadros, data: dataTemperature });
      }
    } catch (error) {
      console.error("Error ao pegar a temperaturas:", error);
    }
    setIsRequest(false);
    setIsRequest(false);
  }

  const getDataQuadros = async () => {
    getByObrasQuadros()
      .then((result) => {
        let data = result?.data;
        if (data.length > 0) {
          const transformedData: IQuadros = {
            data: data
              .filter(
                (item) =>
                  item.tipo_visualizacao !== TypeGraphics.FIGURE && // Não é uma figura
                  (item.grupos !== null || item.instrumentos !== null) // Pelo menos um deles não é nulo
              )
              .map((item) => ({
                url_imagem: item.url_imagem,
                tipo_visualizacao: item.tipo_visualizacao,
                id_instrumento: item.id_instrumento,
                id_grupo: item.id_grupo,
                title:
                  item.id_grupo !== null
                    ? item.grupos?.nome
                    : item.instrumentos?.nome,
              })),
            tela: {
              quantidade_quadros: data[0].tela.quantidade_quadros,
              id_obra: data[0].tela.id_obra,
            },
          };
          setDataQuadros(transformedData);

          const graphs: IDataGraphs[] = [] as IDataGraphs[];
          for (const graph of transformedData.data) {
            graphs.push({
              id: graph.id_instrumento
                ? graph.id_instrumento!
                : graph.id_grupo!,
              typeElement: graph.id_instrumento
                ? TypeElement.INSTRUMENTO
                : TypeElement.GRUPO,
              typeGraph: graph.tipo_visualizacao,
            });
          }
          setDataGraphs(graphs);
        }
      })
      .finally(() => {
        setIsRequest(false);
      })
      .catch((error) => {
        console.log("GetQuadros: ", error);
      });
  };

  useEffect(() => {
    if (!isRequest) {
      setIsRequest(true);
      getDataDashboard();
      getDataQuadros();
    }
  }, []);

  useEffect(() => {
    if (dataInstruments.length > 0) {
      getCurrentTemperature();
    }
  }, [dataInstruments]);

  useEffect(() => {
    const intervalTime =
      Number(process.env.TIME_SET_INTERVAL_DASHBOARD) || 3000;
    const interval = setInterval(() => {
      if (!isRequest && dataInstruments.length > 0) {
        setIsRequest(true);
        getCurrentTemperature();
      }
    }, intervalTime);
    return () => clearInterval(interval);
  });

  const navigate = useNavigate();

  return (
    <MainContainer>
      <section className="mt-5">
        <HeaderSection
          title="Instrumentos"
          buttonLeft={
            <>
              <div>
                <Button
                  sizeButton="md"
                  schemaButton="full-primary"
                  action={() => navigate(ROUTES.NEW_INSTRUMENT)}
                >
                  Adicionar Instrumento
                </Button>
              </div>
              <div className="m-2">
                <Button
                  sizeButton="md"
                  schemaButton="out-primary"
                  action={() => navigate(ROUTES.INSTRUMENTS)}
                >
                  Ver Instrumentos
                </Button>
              </div>
              <div>
                <Button
                  sizeButton="md"
                  schemaButton="out-primary"
                  action={() => navigate(ROUTES.TESTE_INSTRUMENT)}
                >
                  Testar instrumentos
                </Button>
              </div>
            </>
          }
        />
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              variant="primary"
              style={{ width: "100px", height: "100px", borderWidth: "0.2em" }}
            />
          </div>
        ) : dataInstruments.length > 0 ? (
          <Row xl={3} md={2} xs={1}>
            {dataInstruments.map((instrument, index) => (
              <Col className="mt-3" key={index}>
                <InstrumentContainer
                  key={index}
                  label={instrument.label}
                  points={instrument.points}
                  id={instrument.id}
                  type={TypeElement.INSTRUMENTO}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Alert variant="primary">Nenhum instrumento criado!</Alert>
        )}
      </section>

      <section className="mt-5">
        <HeaderSection
          title="Grupos de termopares"
          buttonLeft={
            <>
              <div className="m-2">
                <Button
                  sizeButton="md"
                  schemaButton="full-primary"
                  action={() => navigate(ROUTES.NEW_GROUP)}
                >
                  Novo Grupo
                </Button>
              </div>
              <div>
                <Button
                  sizeButton="md"
                  schemaButton="out-primary"
                  action={() => navigate(ROUTES.GROUPS)}
                >
                  Ver Grupos
                </Button>
              </div>
            </>
          }
        />
        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              variant="primary"
              style={{ width: "100px", height: "100px", borderWidth: "0.2em" }}
            />
          </div>
        ) : dataGrupos.length > 0 ? (
          <Row xl={3} md={2} xs={1}>
            {dataGrupos.map((group, index) => (
              <Col className="mt-3" key={index}>
                <InstrumentContainer
                  key={index}
                  label={group.label}
                  points={group.points}
                  id={group.id}
                  type={TypeElement.GRUPO}
                />
              </Col>
            ))}
          </Row>
        ) : (
          <Alert variant="primary">Nenhum grupo criado!</Alert>
        )}
      </section>

      <section className="mt-5">
        <HeaderSection
          title="Visualizações personalizadas"
          buttonLeft={
            <>
              <div className="m-2">
                <Button
                  sizeButton="md"
                  schemaButton="full-primary"
                  action={() => navigate(ROUTES.SELECT_LAYOUT)}
                >
                  Criar Visualização
                </Button>
              </div>
              <div>
                <Button
                  sizeButton="md"
                  schemaButton="out-primary"
                  action={() =>
                    window.open(
                      `${ROUTES.VISUALIZATION_FULL}?title="Figure"&typeGraphic=${TypeGraphics.FIGURE}&typeElement=0&idElement=0`,
                      "_blank"
                    )
                  }
                >
                  Criar Figura
                </Button>
              </div>
            </>
          }
        />

        {isLoading ? (
          <div className="d-flex justify-content-center align-items-center">
            <Spinner
              animation="border"
              variant="primary"
              style={{
                width: "100px",
                height: "100px",
                borderWidth: "0.2em",
              }}
            />
          </div>
        ) : dataQuadros && dataQuadros.data ? (
          <Container fluid className="my-5">
            <Row className="my-5">
              {dataQuadros.data[0] && (
                <Col lg={dataQuadros.data[1] ? 6 : 12} md={12}>
                  <ChartContainer
                    title={dataQuadros.data[0].title!}
                    type={dataQuadros.data[0].tipo_visualizacao}
                    idElement={
                      dataQuadros.data[0].id_grupo !== null
                        ? dataQuadros.data[0].id_grupo!
                        : dataQuadros.data[0].id_instrumento!
                    }
                    typeElement={
                      dataQuadros.data[0].id_grupo !== null
                        ? TypeElement.GRUPO
                        : TypeElement.INSTRUMENTO
                    }
                  >
                    {(() => {
                      switch (dataQuadros.data[0].tipo_visualizacao) {
                        case TypeGraphics.BARGRAPH:
                          return (
                            <TemperatureChart
                              id={
                                dataQuadros.data[0].id_grupo !== null
                                  ? dataQuadros.data[0].id_grupo!
                                  : dataQuadros.data[0].id_instrumento!
                              }
                              type={
                                dataQuadros.data[0].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[0].dataTemperature
                              }
                            />
                          );
                        case TypeGraphics.HISTORICO:
                          return (
                            <CustomAreaChart
                              id={
                                dataQuadros.data[0].id_grupo !== null
                                  ? dataQuadros.data[0].id_grupo!
                                  : dataQuadros.data[0].id_instrumento!
                              }
                              type={
                                dataQuadros.data[0].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[0].dataTemperature
                              }
                            />
                          );
                        case TypeGraphics.TENDENCIA:
                          return (
                            <CustomLineChart
                              id={
                                dataQuadros.data[0].id_grupo !== null
                                  ? dataQuadros.data[0].id_grupo!
                                  : dataQuadros.data[0].id_instrumento!
                              }
                              type={
                                dataQuadros.data[0].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[0].dataTemperature
                              }
                            />
                          );
                        default:
                          return <></>;
                      }
                    })()}
                  </ChartContainer>
                </Col>
              )}
              {dataQuadros.data[1] && (
                <Col lg={6} md={12}>
                  <ChartContainer
                    title={dataQuadros.data[1].title!}
                    type={dataQuadros.data[1].tipo_visualizacao}
                    idElement={
                      dataQuadros.data[1].id_grupo !== null
                        ? dataQuadros.data[1].id_grupo!
                        : dataQuadros.data[1].id_instrumento!
                    }
                    typeElement={
                      dataQuadros.data[1].id_grupo !== null
                        ? TypeElement.GRUPO
                        : TypeElement.INSTRUMENTO
                    }
                  >
                    {(() => {
                      switch (dataQuadros.data[1].tipo_visualizacao) {
                        case TypeGraphics.BARGRAPH:
                          return (
                            <TemperatureChart
                              id={
                                dataQuadros.data[1].id_grupo !== null
                                  ? dataQuadros.data[1].id_grupo!
                                  : dataQuadros.data[1].id_instrumento!
                              }
                              type={
                                dataQuadros.data[1].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[1].dataTemperature
                              }
                            />
                          );
                        case TypeGraphics.HISTORICO:
                          return (
                            <CustomAreaChart
                              id={
                                dataQuadros.data[1].id_grupo !== null
                                  ? dataQuadros.data[1].id_grupo!
                                  : dataQuadros.data[1].id_instrumento!
                              }
                              type={
                                dataQuadros.data[1].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[1].dataTemperature
                              }
                            />
                          );
                        case TypeGraphics.TENDENCIA:
                          return (
                            <CustomLineChart
                              id={
                                dataQuadros.data[1].id_grupo !== null
                                  ? dataQuadros.data[1].id_grupo!
                                  : dataQuadros.data[1].id_instrumento!
                              }
                              type={
                                dataQuadros.data[1].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[1].dataTemperature
                              }
                            />
                          );
                        default:
                          return (
                            <AddViewContainer
                              onClick={() => navigate(ROUTES.SELECT_LAYOUT)}
                            >
                              <LuPlusCircle
                                style={{ fontSize: "64px" }}
                                color="#3758F9"
                              />
                              <TextGreyContainer>
                                Adicione a visualização de um instrumento ou
                                grupo.
                              </TextGreyContainer>
                            </AddViewContainer>
                          );
                      }
                    })()}
                  </ChartContainer>
                </Col>
              )}
            </Row>
            <Row className="my-5">
              {dataQuadros.data[2] && (
                <Col lg={6} md={12}>
                  <ChartContainer
                    title={dataQuadros.data[2].title!}
                    type={dataQuadros.data[2].tipo_visualizacao}
                    idElement={
                      dataQuadros.data[2].id_grupo !== null
                        ? dataQuadros.data[2].id_grupo!
                        : dataQuadros.data[2].id_instrumento!
                    }
                    typeElement={
                      dataQuadros.data[2].id_grupo !== null
                        ? TypeElement.GRUPO
                        : TypeElement.INSTRUMENTO
                    }
                  >
                    {(() => {
                      switch (dataQuadros.data[2].tipo_visualizacao) {
                        case TypeGraphics.BARGRAPH:
                          return (
                            <TemperatureChart
                              id={
                                dataQuadros.data[2].id_grupo !== null
                                  ? dataQuadros.data[2].id_grupo!
                                  : dataQuadros.data[2].id_instrumento!
                              }
                              type={
                                dataQuadros.data[2].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[2].dataTemperature
                              }
                            />
                          );
                        case TypeGraphics.HISTORICO:
                          return (
                            <CustomAreaChart
                              id={
                                dataQuadros.data[2].id_grupo !== null
                                  ? dataQuadros.data[2].id_grupo!
                                  : dataQuadros.data[2].id_instrumento!
                              }
                              type={
                                dataQuadros.data[2].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[2].dataTemperature
                              }
                            />
                          );
                        case TypeGraphics.TENDENCIA:
                          return (
                            <CustomLineChart
                              id={
                                dataQuadros.data[2].id_grupo !== null
                                  ? dataQuadros.data[2].id_grupo!
                                  : dataQuadros.data[2].id_instrumento!
                              }
                              type={
                                dataQuadros.data[2].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[2].dataTemperature
                              }
                            />
                          );
                        default:
                          return (
                            <AddViewContainer
                              onClick={() => navigate(ROUTES.SELECT_LAYOUT)}
                            >
                              <LuPlusCircle
                                style={{ fontSize: "64px" }}
                                color="#3758F9"
                              />
                              <TextGreyContainer>
                                Adicione a visualização de um instrumento ou
                                grupo.
                              </TextGreyContainer>
                            </AddViewContainer>
                          );
                      }
                    })()}
                  </ChartContainer>
                </Col>
              )}
              {dataQuadros.data[3] && (
                <Col lg={6} md={12}>
                  <ChartContainer
                    title={dataQuadros.data[3].title!}
                    type={dataQuadros.data[3].tipo_visualizacao}
                    idElement={
                      dataQuadros.data[3].id_grupo !== null
                        ? dataQuadros.data[3].id_grupo!
                        : dataQuadros.data[3].id_instrumento!
                    }
                    typeElement={
                      dataQuadros.data[3].id_grupo !== null
                        ? TypeElement.GRUPO
                        : TypeElement.INSTRUMENTO
                    }
                  >
                    {(() => {
                      switch (dataQuadros.data[3].tipo_visualizacao) {
                        case TypeGraphics.BARGRAPH:
                          return (
                            <TemperatureChart
                              id={
                                dataQuadros.data[3].id_grupo !== null
                                  ? dataQuadros.data[3].id_grupo!
                                  : dataQuadros.data[3].id_instrumento!
                              }
                              type={
                                dataQuadros.data[3].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[3].dataTemperature
                              }
                            />
                          );
                        case TypeGraphics.HISTORICO:
                          return (
                            <CustomAreaChart
                              id={
                                dataQuadros.data[3].id_grupo !== null
                                  ? dataQuadros.data[3].id_grupo!
                                  : dataQuadros.data[3].id_instrumento!
                              }
                              type={
                                dataQuadros.data[3].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[3].dataTemperature
                              }
                            />
                          );
                        case TypeGraphics.TENDENCIA:
                          return (
                            <CustomLineChart
                              id={
                                dataQuadros.data[3].id_grupo !== null
                                  ? dataQuadros.data[3].id_grupo!
                                  : dataQuadros.data[3].id_instrumento!
                              }
                              type={
                                dataQuadros.data[3].id_grupo !== null
                                  ? TypeElement.GRUPO
                                  : TypeElement.INSTRUMENTO
                              }
                              dataTemperature={
                                dataQuadros.data[3].dataTemperature
                              }
                            />
                          );
                        default:
                          return (
                            <AddViewContainer
                              onClick={() => navigate(ROUTES.SELECT_LAYOUT)}
                            >
                              <LuPlusCircle
                                style={{ fontSize: "64px" }}
                                color="#3758F9"
                              />
                              <TextGreyContainer>
                                Adicione a visualização de um instrumento ou
                                grupo.
                              </TextGreyContainer>
                            </AddViewContainer>
                          );
                      }
                    })()}
                  </ChartContainer>
                </Col>
              )}
            </Row>
          </Container>
        ) : (
          <Alert variant="primary">Nenhum quadro de visualização criado!</Alert>
        )}
      </section>
    </MainContainer>
  );
};

export default Dashboard;
