import React, { JSX } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "../pages/dashboard/index";
import Visualization from "../pages/visualization";
import Alarm from "../pages/alarm/alarm";
import SelectLayout from "../pages/select-layout";
import FullScreen from "../pages/full-screen";
import SetPoints from "../pages/set-points";
import NewGroup from "../pages/group/new";
import InstrumentList from "../pages/instruments/list";
import InstrumentNew from "../pages/instruments/new";
import NewUser from "../pages/users/new";
import ListUsers from "../pages/users/list";
import { ROUTES } from "../constants/routes";
import Login from "../pages/login";
import RecoverPassword from "../pages/recover-password";
import ForgotPassWord from "../pages/forgot-password";
import { useAuthContext } from "../context/auth-context";
import { ProtectedRoutes } from "./protected-routes";
import NewWork from "../pages/works/new";
import WorkList from "../pages/works/list";
import UpdateWork from "../pages/works/update";
import { UsersType } from "../shared/enums/users-type";
import Unauthorized from "../pages/unauthorized";
import GroupList from "../pages/group/list";
import UpdateGroup from "../pages/group/update";
import UpdateInstrument from "../pages/instruments/update";
import UpdateUser from "../pages/users/update";
import TestInstrumentConnection from "../pages/test-instrument-connection";

export const AppRoutes = () => {
  const { isSigned } = useAuthContext();

  const privateRoutes: {
    path: string;
    permissions: UsersType[];
    element: JSX.Element;
  }[] = [
    {
      path: ROUTES.DASHBOARD,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <Dashboard />,
    },
    {
      path: ROUTES.TESTE_INSTRUMENT,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <TestInstrumentConnection />,
    },
    {
      path: ROUTES.VISUALIZATION,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <Visualization />,
    },
    {
      path: ROUTES.VISUALIZATION_FULL,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <FullScreen />,
    },
    {
      path: ROUTES.SELECT_LAYOUT,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <SelectLayout />,
    },
    {
      path: ROUTES.ALARM,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <Alarm />,
    },
    {
      path: ROUTES.SET_POINTS,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <SetPoints />,
    },
    {
      path: ROUTES.NEW_GROUP,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <NewGroup />,
    },
    {
      path: ROUTES.GROUPS,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <GroupList />,
    },
    {
      path: ROUTES.UPDATE_GROUP,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <UpdateGroup />,
    },
    {
      path: ROUTES.INSTRUMENTS,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <InstrumentList />,
    },
    {
      path: ROUTES.NEW_INSTRUMENT,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <InstrumentNew />,
    },
    {
      path: ROUTES.UPDATE_INSTRUMENT,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],
      element: <UpdateInstrument />,
    },
    {
      path: ROUTES.USERS,
      permissions: [UsersType.ADMINISTRADOR],
      element: <ListUsers />,
    },
    {
      path: ROUTES.NEW_USER,
      permissions: [UsersType.ADMINISTRADOR],
      element: <NewUser />,
    },
    {
      path: ROUTES.UPDATE_USER,
      permissions: [
        UsersType.ADMINISTRADOR,
        UsersType.ENGENHEIRO,
        UsersType.OPERADOR,
      ],

      element: <UpdateUser />,
    },
    {
      path: ROUTES.NEW_WORK,
      permissions: [UsersType.ADMINISTRADOR, UsersType.ENGENHEIRO],

      element: <NewWork />,
    },
    {
      path: ROUTES.WORKS,
      permissions: [UsersType.ADMINISTRADOR, UsersType.ENGENHEIRO],
      element: <WorkList />,
    },
    {
      path: ROUTES.UPDATE_WORK,
      permissions: [UsersType.ADMINISTRADOR],
      element: <UpdateWork />,
    },
  ];

  return (
    <Routes>
      <Route
        path={ROUTES.LOGIN}
        element={
          isSigned ? <Navigate to={ROUTES.DASHBOARD} replace /> : <Login />
        }
      />

      <Route
        key={ROUTES.FORGOT_PASSWORD}
        path={ROUTES.FORGOT_PASSWORD}
        element={<ForgotPassWord />}
      />

      <Route
        key={ROUTES.RECOVER_PASSWORD}
        path={ROUTES.RECOVER_PASSWORD}
        element={<RecoverPassword />}
      />

      <Route
        key={ROUTES.UNAUTHORIZED}
        path={ROUTES.UNAUTHORIZED}
        element={<Unauthorized />}
      />

      <Route path={"*"} element={<div>Não encontrado</div>} />

      {privateRoutes.map(({ element, path, permissions }) => (
        <Route
          key={path}
          path={path}
          element={
            <ProtectedRoutes permissions={permissions}>
              {element}
            </ProtectedRoutes>
          }
        />
      ))}
    </Routes>
  );
};
