import styled, { css } from "styled-components";

export type TSelectSchema =  "default" | "danger"
export type TSelectSize =  "full" | "default"

export const SelectContainer = styled.div`
  position: relative;
  margin: 32px 0;
`;

export const SelectLabelButton = styled.span<{schema?: TSelectSchema, sizeInput?: TSelectSize}>`
  display: flex;
  padding: 0.375rem 0.75rem;
  width: 240px;
  font-size: ${props => props.theme.font.size.md};
  font-weight: 500;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid #DFE4EA;
  color: ${props => props.theme.colors.blueSteel};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  /* box-shadow: 0 1px 4px 0 #ccc; */
  transition: 0.3s ease;
  border-radius: 8px;
  &:hover {
    background-color: #eee;
  }

  ${props => {
        switch (props.schema) {
            case "danger":
                return `
                    border: 1px solid #F23030 !important;
                `
        }
    }}
    ${props => {
        switch (props.sizeInput) {

            case "full":
                return `
                    width: 100% !important;
                `
        }
    }}
`;

export const DropdownStyle = styled.div<{isVisible: boolean}>`
  position: absolute;
  z-index: 100;
  border-radius: 8px;
  top: 64px;
  left: 0;
  max-height: 40vmax;
  width: 240px;
  padding: 0;
  display: flex;
  flex-direction: column;
  background: ${props => props.theme.colors.white};
  border: 1.5px solid slategrey;
  transition: max-height 0.2s ease;
  overflow-y: auto;
  ${(p) =>
    p.isVisible !== true &&
    css`
      max-height: 40px;
      visibility: hidden;
    `}
`;

export const DropdownItem = styled.div<{active?: boolean}>`
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 4px 0;
  padding: 8px 12px;
  font-size: 0.9rem;
  font-weight: 400;
  color: #333;
  cursor: pointer;
  ${(p) =>
    p.active &&
    css`
      font-weight: 700;
    `}
  &:hover, :focus, :focus:hover {
    background-color: #3758F9;
    color: #fff;
    outline: none;
  }
`;

export const SelectMessageError = styled.span`
    margin-top: 8px;
    width: 100%;
    color: #F23030;
    font-size: 14px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 4px;
`

export const LabelForSelect = styled.label`
    display: block;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0em;
    text-align: left;
    color: #4B5563;
`