import { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../components/main-container";
import Button from "../../components/button";
import AlterLayout from "../../components/grid-layout";
import SelectLayoutAction from "../../components/select-layout-action";
import { GreyContainer } from "../../components/grey-container/styles";
import { TextGreyContainer } from "../../components/text-grey-container/styles";
import { Half, Quarter, Single } from "./formats";
import Modal from "../../components/modal";
import ModalLayoutContent from "./modal-layout-content";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { TypeContent } from "../../shared/enums/layoutContent/typeContent.enum";
import { IQuadros } from "../../shared/interfaces/quadros/quadros";
import { IoSave } from "react-icons/io5";
import { useQuadrosService } from "../../hooks/api/quadros";
import { useToast } from "../../hooks/toast";
import { FaLeftLong } from "react-icons/fa6";
import { TypeGraphics } from "../../shared/enums/layoutContent/typeGraphics.enum";

interface LayoutProps {}

const SelectLayout: FunctionComponent<LayoutProps> = () => {
  const { showSuccessToast, showErrorToast } = useToast();
  const { create, getByObrasQuadros } = useQuadrosService();
  const navigate = useNavigate();

  const [visualization, setVisualization] = useState<TypeContent | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [dataQuadros, setDataQuadros] = useState<IQuadros>({} as IQuadros);
  const [content, setContent] = useState<number>(0);

  const getDataQuadros = () => {
    getByObrasQuadros().then(({ data }) => {
      if (data && data.length > 0) {
        const transformedData: IQuadros = {
          data: data
            .filter(
              (item) =>
                item.tipo_visualizacao !== TypeGraphics.FIGURE &&
                (item.grupos !== null || item.instrumentos !== null)
            )
            .map((item) => ({
              url_imagem: item.url_imagem,
              tipo_visualizacao: item.tipo_visualizacao,
              id_instrumento: item.id_instrumento,
              id_grupo: item.id_grupo,
            })),
          tela: {
            quantidade_quadros: data[0].tela.quantidade_quadros,
            id_obra: data[0].tela.id_obra,
          },
        };
        setDataQuadros(transformedData);
      }
    });
  };

  useEffect(() => {
    getDataQuadros();
  }, []);

  const handleClickBack = () => {
    navigate(ROUTES.DASHBOARD);
  };

  const handleClickSave = () => {
    let data: IQuadros = {
      data: dataQuadros.data,
      tela: {
        id_obra: 0,
        quantidade_quadros: dataQuadros.data.length,
      },
    };
    return create(data)
      .then(() => {
        showSuccessToast("Visualização criada com sucesso!");
        navigate(ROUTES.DASHBOARD);
      })
      .catch((err) => {
        console.log(err);
        showErrorToast(err.response.data.message);
      });
  };

  return (
    <MainContainer>
      <div className="d-flex justify-content-between mt-4">
        <Button
          sizeButton="md"
          icon={<FaLeftLong />}
          schemaButton="out-primary"
          action={handleClickBack}
        >
          Voltar
        </Button>

        <Button
          sizeButton="md"
          icon={<IoSave />}
          schemaButton="out-success"
          action={handleClickSave}
        >
          Salvar visualização
        </Button>
        <AlterLayout
          visualization={visualization}
          setVisualization={setVisualization}
        />
      </div>

      <section>
        {visualization === TypeContent.SINGLE ? (
          <Single
            setContent={setContent}
            setShow={setShow}
            dataQuadros={dataQuadros}
          />
        ) : visualization === TypeContent.HALF ? (
          <Half
            setContent={setContent}
            setShow={setShow}
            dataQuadros={dataQuadros}
          />
        ) : visualization === TypeContent.QUARTER ? (
          <Quarter
            setContent={setContent}
            setShow={setShow}
            dataQuadros={dataQuadros}
          />
        ) : (
          <GreyContainer>
            <TextGreyContainer>
              Selecione o tipo de visualização
            </TextGreyContainer>
            <SelectLayoutAction setVisualization={setVisualization} />
          </GreyContainer>
        )}
      </section>

      <Modal show={show} setShow={setShow}>
        <ModalLayoutContent
          content={content}
          setShow={setShow}
          setDataQuadros={setDataQuadros}
          dataQuadros={dataQuadros}
        />
      </Modal>
    </MainContainer>
  );
};

export default SelectLayout;
