import { FunctionComponent, useEffect, useState } from "react";
import { ContainerForNewUser } from "./styles";
import { Line } from "../../pages/visualization/styles";
import { Title, TitleSection } from "../header-section/styles";
import { Col, Container, Row } from "react-bootstrap";
import Select from "../select";
import { setor } from "../../static/data/data";
import { SubmitHandler, useForm } from "react-hook-form";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import CustomInput from "../input";
import { ButtonForSubmit } from "../input/styles";
import {
  IUser,
  IUserUpdateCreate,
} from "../../shared/interfaces/user/user.data";
import { SelectTypeUser, UsersType } from "../../shared/enums/users-type";
import { useUsersService } from "../../hooks/api/user";
import { useToast } from "../../hooks/toast";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../constants/routes";
import { useAuthContext } from "../../context/auth-context";

interface ContainerFormsUserProps {
  dataUpdateUser?: IUser;
  editPerfil?: boolean;
}
interface IFormInput {
  name: string;
  matricula: string;
  username: string;
  setor: string;
  type: UsersType;
  password: string;
  passwordConfirm: string;
}
const ContainerFormsUser: FunctionComponent<ContainerFormsUserProps> = ({
  dataUpdateUser,
  editPerfil,
}) => {
  const { user, signOut } = useAuthContext();
  const { create, update } = useUsersService();
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(false);
  const {
    register,
    handleSubmit,
    formState,
    control,
    setValue,
  } = useForm<IFormInput>();

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    if (!dataUpdateUser) {
      if (data.password === data.passwordConfirm) {
        return create(data)
          .then(() => {
            showSuccessToast("Usuário cadastrado com sucesso!");
            if (user.type === UsersType.ADMINISTRADOR) {
              navigate(ROUTES.USERS);
            } else {
              navigate(ROUTES.DASHBOARD);
            }
          })
          .catch((err) => {
            showErrorToast(err.response.data.message);
          });
      } else {
        const confirmPasswordField =
          document.getElementsByName("passwordConfirm")[0];
        confirmPasswordField.focus();
        showErrorToast("As senhas não coincidem!");
      }
    } else {
      let dataUpdateDto: IUserUpdateCreate = {
        name: data.name,
        username: data.username,
        matricula: data.matricula,
        password: data.password ? data.password : null,
        setor: data.setor,
        type: data.type,
      };

      if (data.password === data.passwordConfirm) {
        return update(dataUpdateDto, dataUpdateUser.id)
          .then(async () => {
            if (user.type === UsersType.ADMINISTRADOR) {
              if (editPerfil) {
                if (dataUpdateDto.username !== dataUpdateUser.username) {
                  showSuccessToast(
                    "Usuário alterado com sucesso. Faça login novamente!"
                  );
                  signOut();
                  navigate(ROUTES.LOGIN);
                }
              } else {
                if (user.username === dataUpdateUser.username) {
                  if (dataUpdateDto.username !== dataUpdateUser.username) {
                    showSuccessToast(
                      "Usuário alterado com sucesso. Faça login novamente!"
                    );
                    signOut();
                    navigate(ROUTES.LOGIN);
                  }
                } else {
                  showSuccessToast("Usuário alterado com sucesso!");
                  navigate(ROUTES.USERS);
                }
              }
            } else {
              if (dataUpdateDto.username !== dataUpdateUser.username) {
                if (editPerfil) {
                  showSuccessToast(
                    "Usuário alterado com sucesso. Faça login novamente!"
                  );
                  signOut();
                  navigate(ROUTES.LOGIN);
                }
              } else {
                showSuccessToast("Usuário alterado com sucesso!");
                navigate(ROUTES.DASHBOARD);
              }
            }
          })
          .catch((err) => {
            console.log(err);
            showErrorToast(err.response.data.message);
          });
      } else {
        const confirmPasswordField =
          document.getElementsByName("passwordConfirm")[0];
        confirmPasswordField.focus();
        showErrorToast("As senhas não coincidem!");
      }
    }
  };

  useEffect(() => {
    console.log(formState.errors);
  }, [formState.errors]);

  useEffect(() => {
    if (dataUpdateUser !== undefined) {
      setValue("name", dataUpdateUser.name);
      setValue("username", dataUpdateUser.username);
      setValue("matricula", dataUpdateUser.matricula);
      setValue("setor", dataUpdateUser.setor);
      switch (dataUpdateUser.type) {
        case UsersType.ADMINISTRADOR:
          return setValue("type", UsersType.ADMINISTRADOR);
        case UsersType.ENGENHEIRO:
          return setValue("type", UsersType.ENGENHEIRO);
        case UsersType.OPERADOR:
          return setValue("type", UsersType.OPERADOR);
        default:
          break;
      }
    }
  }, [dataUpdateUser]);

  return (
    <ContainerForNewUser>
      <Title className="my-3">
        {!dataUpdateUser ? "Cadastro de Usuário" : "Atualizar dados do Usuário"}
      </Title>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TitleSection className="my-3">Dados Pessoais</TitleSection>
        <Line />
        <Container>
          <Row>
            <Col>
              <CustomInput
                label="Nome Completo"
                type="text"
                sizeInput="full"
                fieldName="name"
                errors={formState.errors.name}
                schema={formState.errors.name ? "danger" : "default"}
                register={register}
                options={{ required: true }}
              />
            </Col>
            <Col>
              <CustomInput
                label="Email"
                type="email"
                sizeInput="full"
                fieldName="username"
                name="username"
                errors={formState.errors.username}
                schema={formState.errors.username ? "danger" : "default"}
                register={register}
                options={{ required: true }}
              />
            </Col>
          </Row>
          {user.type === UsersType.ADMINISTRADOR && (
            <>
              <Row>
                <Col>
                  <CustomInput
                    label="Matricula"
                    type="text"
                    sizeInput="full"
                    fieldName="matricula"
                    errors={formState.errors.matricula}
                    schema={formState.errors.matricula ? "danger" : "default"}
                    register={register}
                    options={{ required: true }}
                  />
                </Col>
                <Col>
                  <Select
                    label="Setor"
                    defaultOption={
                      !dataUpdateUser
                        ? "Selecione o setor"
                        : dataUpdateUser.setor
                    }
                    control={control}
                    name="setor"
                    options={setor}
                    rules={{ required: "O campo é obrigatório" }}
                    validationMessage={
                      formState.errors.setor
                        ? formState.errors.setor.message
                        : undefined
                    }
                    schema={formState.errors.setor ? "danger" : "default"}
                    sizeInput="full"
                  />
                </Col>
              </Row>
              <Row>
                <Col className="mb-5">
                  <Select
                    label="Nível de acesso"
                    defaultOption={
                      !dataUpdateUser
                        ? "Selecione o nível de acesso"
                        : dataUpdateUser.type
                    }
                    control={control}
                    name="type"
                    options={SelectTypeUser}
                    rules={{ required: "O campo é obrigatório" }}
                    validationMessage={
                      formState.errors.type
                        ? formState.errors.type.message
                        : undefined
                    }
                    schema={formState.errors.type ? "danger" : "default"}
                    sizeInput="full"
                  />
                </Col>
              </Row>
            </>
          )}
        </Container>

        <TitleSection className="my-3">
          {!dataUpdateUser ? "Senha" : "Edita senha (opcional)"}
        </TitleSection>
        <Line />
        <Container>
          <Row>
            <Col>
              <CustomInput
                placeholder={dataUpdateUser ? "•••••••••••" : ""}
                label={!dataUpdateUser ? "Definir senha" : "Definir nova senha"}
                type={showPassword ? "text" : "password"}
                sizeInput="full"
                fieldName="password"
                errors={formState.errors.password}
                schema={formState.errors.password ? "danger" : "default"}
                register={register}
                options={{ required: !dataUpdateUser ? true : false }}
                icon={
                  showPassword ? (
                    <AiOutlineEyeInvisible
                      onClick={() => setShowPassword(false)}
                    />
                  ) : (
                    <AiOutlineEye onClick={() => setShowPassword(true)} />
                  )
                }
              />
            </Col>
            <Col>
              <CustomInput
                placeholder={dataUpdateUser ? "••••••••••" : ""}
                label={
                  !dataUpdateUser ? "Confirmar senha" : "Confirmar nova senha"
                }
                sizeInput="full"
                fieldName="passwordConfirm"
                errors={formState.errors.passwordConfirm}
                schema={formState.errors.passwordConfirm ? "danger" : "default"}
                type={showPasswordConfirm ? "text" : "password"}
                register={register}
                options={{ required: !dataUpdateUser ? true : false }}
                icon={
                  showPasswordConfirm ? (
                    <AiOutlineEyeInvisible
                      onClick={() => setShowPasswordConfirm(false)}
                    />
                  ) : (
                    <AiOutlineEye
                      onClick={() => setShowPasswordConfirm(true)}
                    />
                  )
                }
              />
            </Col>
          </Row>
        </Container>

        <Line className="my-4" />
        <ButtonForSubmit
          type="submit"
          value={!dataUpdateUser ? "Cadastrar usuário" : "Atualizar dados"}
        />
      </form>
    </ContainerForNewUser>
  );
};

export default ContainerFormsUser;
