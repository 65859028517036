import { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../components/main-container";
import {
  TitleFilter,
  TitleSection,
} from "../../components/header-section/styles";
import { FilterContainer } from "../visualization/styles";
import Select from "../../components/select";
import Checkbox from "../../components/checkbox";
import ReactPaginate from "react-paginate";
import {
  MdOutlineArrowForwardIos,
  MdOutlineArrowBackIos,
} from "react-icons/md";
import { Form } from "react-bootstrap";
import { ContainerAlarm } from "./styles";
import { useForm } from "react-hook-form";
import "../../styles/paginate.css";
import {
  IAlarmes,
  IAlarmesRequestBody,
} from "../../shared/interfaces/alarmes/alarmes";
import { useAlarmesService } from "../../hooks/api/alarms";
import { useToast } from "../../hooks/toast";
import { useGroupsService } from "../../hooks/api/groups";
import { useInstrumentsService } from "../../hooks/api/instruments";
import {
  FilterAlarmes,
  StatusAlarmes,
} from "../../shared/enums/alarmes/statusAlarmes.enum";
import { FilterLayoutContent } from "../select-layout/modal-layout-content/styles";
import Button from "../../components/button";
import { FaVolumeMute } from "react-icons/fa";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import { Badge, Col, Row, Stack } from "react-bootstrap";
import { statusTermopar } from "../../shared/enums/termopar/statusTermopar.enum";

interface IFormInput {
  element: string;
  group: string;
  instrument: string;
}

interface SelectOptions {
  value: any;
  label: string;
}

interface AlarmProps {}
const Alarm: FunctionComponent<AlarmProps> = () => {
  const take = 10;
  const [page, setPage] = useState(0);

  const { getAll, getAllByFilter, updateStatus, createAlarmeHistorico } =
    useAlarmesService();
  const { getByObrasInstrumentos } = useInstrumentsService();
  const { getByObrasGrupos } = useGroupsService();
  const [dataInstrumentos, setDataInstrumentos] = useState<SelectOptions[]>([]);
  const [dataGrupos, setDataGrupos] = useState<SelectOptions[]>([]);
  const [selectFilter, setSelectFilter] = useState<string>("All");
  const [selectFilterId, setSelectFilterId] = useState<number>(0);
  const [loadList, setLoadList] = useState<boolean>(false);

  const { showSuccessToast, showErrorToast } = useToast();

  const [selectedAlarms, setSelectedAlarms] = useState<IAlarmes[]>([]);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [alarmData, setAlarmData] = useState<IAlarmesRequestBody>(
    {} as IAlarmesRequestBody
  );

  const [filterData, setFilterData] = useState<IAlarmes[]>([]);

  const { formState, control } = useForm<IFormInput>();

  const elementOptions: SelectOptions[] = [
    { value: "All", label: "Todos" },
    { value: FilterAlarmes.INSTRUMENTO, label: "Instrumentos" },
    { value: FilterAlarmes.GRUPO, label: "Grupos" },
  ];

  const deletePoint = (id: number) => {
    let updatedArray = selectedAlarms.filter((item) => item.id !== id);
    setSelectedAlarms(updatedArray);
  };

  const selectAlarm = (alarmObj: IAlarmes) => {
    const isSelected = selectedAlarms.find((item) => item.id === alarmObj.id);
    if (isSelected) {
      deletePoint(alarmObj.id);
    } else {
      setSelectedAlarms((oldState) => [...oldState, alarmObj]);
    }
  };

  const isChecked = (alarmObj: IAlarmes) => {
    return !!selectedAlarms.find((item) => item.id === alarmObj.id);
  };

  const handleStatusAlarmes = (status: StatusAlarmes) => {
    selectedAlarms.map(async (item) => {
      let data = {
        id_termopar: item.id_termopar,
        status_termopar: statusTermopar.OPERANTE,
        status_alarme: status,
      };
      await updateStatus(item.id, data)
        .then(async () => {
          let dataHistorico = {
            id_alarme: item.id,
            status: status,
          };

          await createAlarmeHistorico(dataHistorico);
          showSuccessToast(
            `Alarme do termopar: ${item.termopar.tag} foi ${status} com sucesso!`
          );
        })
        .catch((err) => {
          console.log(err);
          showErrorToast(err.response.data.message);
        });
    });

    setSelectedAlarms([]);
    setLoadList(!loadList);
  };

  const getDataInstrumentos = async () => {
    await getByObrasInstrumentos().then(({ data }) => {
      let dataInstrumentsMap = data.map((item) => ({
        value: item.id,
        label: item.nome,
      }));
      setDataInstrumentos(dataInstrumentsMap);
    });
  };

  const getDataGrupos = async () => {
    await getByObrasGrupos().then(({ data }) => {
      let dataGroupMap = data.map((item) => ({
        value: item.id,
        label: item.nome,
      }));
      setDataGrupos(dataGroupMap);
    });
  };

  const getAllAlarmes = async () => {
    setSelectFilterId(0);
    await getAll({
      take,
      page: page,
    })
      .then((response) => {
        setAlarmData(response.data);
        let dataFilter = response.data.data;
        setFilterData(dataFilter);
        showSuccessToast(`Alarme está com filtro TODOS!`);
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const getAllAlarmesByFilter = async () => {
    getAllByFilter(selectFilter, selectFilterId!, {
      take,
      page: page,
    })
      .then((response) => {
        setAlarmData(response.data);
        let dataFilter = response.data.data;
        setFilterData(dataFilter);
        showSuccessToast(
          `Alarmes foi filtrado pelo ${selectFilter} escolhido!`
        );
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const handleSelectAll = () => {
    if (!selectAll) {
      setSelectedAlarms(filterData);
    } else {
      setSelectedAlarms([]);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (selectFilter === "All") {
      getAllAlarmes();
    }
  }, [selectFilter, loadList]);

  useEffect(() => {
    if (selectFilterId !== 0) {
      getAllAlarmesByFilter();
    }
  }, [selectFilterId, loadList]);

  useEffect(() => {
    getDataInstrumentos();
    getDataGrupos();
  }, [page]);

  return (
    <MainContainer>
      <section className="mt-3">
        <TitleSection>Alarmes</TitleSection>
        <FilterContainer className="mt-4">
          <FilterLayoutContent>
            <TitleFilter>Filtro:</TitleFilter>
            <Select
              defaultOption="Todos"
              name="element"
              control={control}
              rules={{ required: "O campo é obrigatório" }}
              sizeInput="default"
              validationMessage={
                formState.errors.element
                  ? formState.errors.element.message
                  : undefined
              }
              schema={formState.errors.element ? "danger" : "default"}
              options={elementOptions}
              label="Filtrar alarme por:"
              setSelectValue={setSelectFilter}
            />

            {selectFilter !== "All" ? (
              selectFilter === FilterAlarmes.INSTRUMENTO ? (
                <Select
                  defaultOption={
                    dataGrupos.length > 0
                      ? "Selecione o instrumento"
                      : "não há instrumentos cadastrados"
                  }
                  name="instrument"
                  rules={{ required: "O campo é obrigatório" }}
                  sizeInput="full"
                  validationMessage={
                    formState.errors.instrument
                      ? formState.errors.instrument.message
                      : undefined
                  }
                  schema={formState.errors.instrument ? "danger" : "default"}
                  control={control}
                  options={dataInstrumentos}
                  label="Selecione um Instrumento"
                  setSelectValue={setSelectFilterId}
                />
              ) : (
                <Select
                  defaultOption={
                    dataGrupos.length > 0
                      ? "Selecione o grupo"
                      : "não há grupos cadastrados"
                  }
                  name="group"
                  rules={{ required: "O campo é obrigatório" }}
                  sizeInput="full"
                  validationMessage={
                    formState.errors.group
                      ? formState.errors.group.message
                      : undefined
                  }
                  schema={formState.errors.group ? "danger" : "default"}
                  control={control}
                  options={dataGrupos}
                  label="Selecione um grupo"
                  setSelectValue={setSelectFilterId}
                />
              )
            ) : null}
          </FilterLayoutContent>
          {selectedAlarms.length > 0 && (
            <div className="d-flex align-items-center">
              <div
                style={{
                  height: "48px",
                  width: "auto",
                }}
              >
                <Button
                  sizeButton="md"
                  icon={<FaVolumeMute />}
                  schemaButton="full-primary"
                  action={() => handleStatusAlarmes(StatusAlarmes.SILENCIADO)}
                >
                  Silenciar selecionados
                </Button>{" "}
                <Button
                  sizeButton="md"
                  icon={<IoIosCheckmarkCircleOutline />}
                  schemaButton="out-success"
                  action={() => handleStatusAlarmes(StatusAlarmes.RESOLVIDO)}
                >
                  Resolver selecionados
                </Button>
              </div>
            </div>
          )}
        </FilterContainer>
        <Checkbox
          text="Selecionar todos"
          checked={selectAll}
          action={() => {
            handleSelectAll();
          }}
        />
        <ContainerAlarm fluid>
          {filterData.map((item, index) => {
            return (
              <Row key={index} style={{ borderBottom: "1px solid #d1d5db99" }}>
                <Col className="d-flex justify-content-between align-items-center py-3">
                  <Checkbox
                    text={item.descricao}
                    checked={isChecked(item)}
                    action={() => {
                      selectAlarm(item);
                    }}
                  />
                  <Stack direction="horizontal" gap={2}>
                    <Badge
                      bg={
                        item.status === StatusAlarmes.ATIVO
                          ? "danger"
                          : item.status === StatusAlarmes.RESOLVIDO
                          ? "success"
                          : "primary"
                      }
                    >
                      {item.status}
                    </Badge>
                  </Stack>
                </Col>
              </Row>
            );
          })}
        </ContainerAlarm>
      </section>
      <div className="d-flex w-100 justify-content-center align-items-center mt-4">
        <ReactPaginate
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          nextClassName={"next-label"}
          previousClassName={"previous-label"}
          onPageChange={(event) => setPage(event.selected)}
          breakLabel="..."
          pageCount={Math.ceil(alarmData!.total_count / take)}
          previousLabel={<MdOutlineArrowBackIos color="#4B5563" />}
          nextLabel={<MdOutlineArrowForwardIos color="#4B5563" />}
        />
      </div>
    </MainContainer>
  );
};

export default Alarm;
