import styled from "styled-components";

export const ProfileDropdownBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
`

export const NameBox = styled.div`
    display: block;
`;

export const LabelForName = styled.label`
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
`

export const LabelForRole = styled.label`
    display: block;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;

`

export const PhotoBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
`;

export const ImageProfile = styled.img`
    width: 40px;
    height: 40px;
    border-radius: 50%;
`;