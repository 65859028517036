import { FunctionComponent, useEffect, useRef, useState } from "react";
import { Image, Layer, Stage, Text } from "react-konva";
import Konva from "konva";
import { GreyContainer } from "../../components/grey-container/styles";
import CustomDropzone from "../../components/custom-dropzone";
import MainContainer from "../../components/main-container";
import Modal from "../../components/modal";
import Button from "../../components/button";
import { KonvaEventObject } from "konva/lib/Node";
import { useLocation, useNavigate } from "react-router-dom";
import { useInstrumentsService } from "../../hooks/api/instruments";
import { useGroupsService } from "../../hooks/api/groups";
import { TypeElement } from "../../shared/enums/typeContent/typeElement.enum";
import { statusTermopar } from "../../shared/enums/termopar/statusTermopar.enum";
import { ROUTES } from "../../constants/routes";
import { IoSave } from "react-icons/io5";
import { FaLeftLong } from "react-icons/fa6";
import { IQuadrosFigure } from "../../shared/interfaces/quadros/quadros";
import { useQuadrosService } from "../../hooks/api/quadros";
import { useToast } from "../../hooks/toast";
import ChangeThermocoupleContainer from "../../components/change-thermocouple-container";

interface DataPoints {
  id_termopar: number;
  tag: string;
  x: number;
  y: number;
  index: number;
  status: statusTermopar;
  instrumentName: string;
  id_instrumento: number;
}

interface SetPointsProps {}

const SetPoints: FunctionComponent<SetPointsProps> = () => {
  const [resizedImage, setResizedImage] = useState<HTMLImageElement | null>(
    null
  );

  const location = useLocation();
  const navigate = useNavigate();

  const idChart: number = location.state.id;
  const typeChart: TypeElement = location.state.type;
  const { showSuccessToast, showErrorToast } = useToast();
  const { getOneInstrument } = useInstrumentsService();
  const { getOneGroup } = useGroupsService();
  const { createFigure } = useQuadrosService();
  const [dataPoints, setDataPoints] = useState<DataPoints[]>([]);
  const [selectChangeIdTermopar, setSelectChangeIdTermopar] =
    useState<number>();

  const [file, setFile] = useState<any[] | null>(null);
  const [imgUrl, setImageUrl] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [cursorMove, setCursorMove] = useState(false);
  const [resizedWidth, setResizedWidth] = useState<number>();
  const [resizedHeight, setResizedHeight] = useState<number>();

  useEffect(() => {
    if (file !== null) {
      setImageUrl(file[0].preview);
    }
  }, [file]);

  const greyContainerRef = useRef<HTMLDivElement>(null);

  const getDataInstrumentos = async () => {
    await getOneInstrument(idChart).then(({ data }) => {
      data.termopares = data.termopares.filter(
        (termopar) => termopar.status !== statusTermopar.DESABILITADO
      );
      let dataInstrumentsMap: any = [];
      data.termopares.map((termopar, index) => {
        dataInstrumentsMap.push({
          id_termopar: termopar.id,
          tag: termopar.tag,
          x: 0,
          y: 0,
          index: index + 1,
          status: termopar.status,
          instrumentName: data.nome,
          id_instrumento: termopar.id_instrumento!,
        });
        return null;
      });
      setDataPoints(dataInstrumentsMap);
    });
  };

  const getDataGrupos = async () => {
    await getOneGroup(idChart).then(({ data }) => {
      data.termopares = data.termopares.filter(
        (termopar) => termopar.status !== statusTermopar.DESABILITADO
      );
      let dataGroupMap = data.termopares.map((termopar, index) => ({
        id_termopar: termopar.id,
        tag: termopar.tag,
        x: 0,
        y: 0,
        index: index + 1,
        status: termopar.status,
        instrumentName: data.nome,
        id_instrumento: termopar.id_instrumento!,
      }));
      setDataPoints(dataGroupMap);
    });
  };

  useEffect(() => {
    if (typeChart === TypeElement.INSTRUMENTO) {
      getDataInstrumentos();
    } else {
      getDataGrupos();
    }
  }, [idChart, typeChart, setShow, show]);

  const loadImage = (url: string) => {
    const image = new window.Image();
    image.src = url;
    if (resizedHeight === undefined) {
      image.onload = () => {
        if (greyContainerRef.current) {
          const containerWidth = greyContainerRef.current.offsetWidth - 15;
          const containerHeight = greyContainerRef.current.offsetHeight - 15;

          const scaleFactor = Math.min(
            containerWidth / image.width,
            containerHeight / image.height
          );

          const resizedWidth1 = image.width * scaleFactor;
          const resizedHeight1 = image.height * scaleFactor;

          // Criando um novo objeto de imagem redimensionado
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = resizedWidth1;
          canvas.height = resizedHeight1;
          context?.drawImage(image, 0, 0, resizedWidth1, resizedHeight1);

          const resizedImageSrc = canvas.toDataURL();
          const newImage = new window.Image();
          newImage.src = resizedImageSrc;

          setResizedImage(newImage);

          setResizedWidth(resizedWidth1);
          setResizedHeight(resizedHeight1);
        }
      };
    } else {
      image.width = resizedWidth!;
      image.height = resizedHeight!;
    }
    return image;
  };

  async function imageToBase64(image: HTMLImageElement): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      const ctx = canvas.getContext("2d");
      if (!ctx) {
        reject(new Error("Failed to get 2D context"));
        return;
      }

      ctx.drawImage(image, 0, 0);
      const dataURL = canvas.toDataURL("image/png");

      resolve(dataURL);
    });
  }

  const positionElement = (data: KonvaEventObject<DragEvent>) => {
    const newDataPoints = dataPoints.map((point) => {
      if (point.index === data.target.index) {
        return {
          ...point,
          x: data.target.attrs.x,
          y: data.target.attrs.y,
        };
      } else {
        return point;
      }
    });
    setDataPoints(newDataPoints);
  };

  const registerPositionPoint = async () => {
    const imageBase64 = await imageToBase64(resizedImage!);

    let data: IQuadrosFigure = {
      url_imagem: imageBase64,
      tipo_visualizacao: "FIGURE",
      id_instrumento: typeChart === TypeElement.INSTRUMENTO ? idChart : null,
      id_grupo: typeChart === TypeElement.GRUPO ? idChart : null,
      quadrosTermopares: dataPoints.map((termopar) => ({
        id_termopar: termopar.id_termopar,
        posicao_x: Number(termopar.x.toFixed(5)),
        posicao_y: Number(termopar.y.toFixed(5)),
      })),
      tela: {
        quantidade_quadros: 1,
      },
    };

    return createFigure(data)
      .then(() => {
        showSuccessToast("Figura de visualização criada com sucesso!");
        navigate(ROUTES.DASHBOARD);
      })
      .catch((err) => {
        console.log(err);
        showErrorToast(err.response.data.message);
      });
  };

  const handleClickInstrument = (id: number, type: string) => {
    navigate(ROUTES.VISUALIZATION, { state: { id, type } });
  };

  const openModal = (value: number) => {
    setSelectChangeIdTermopar(value);
    setShow(true);
  };

  return (
    <>
      <MainContainer>
        <div className="d-flex justify-content-between mt-4">
          <Button
            sizeButton="md"
            icon={<FaLeftLong />}
            schemaButton="out-primary"
            action={() => handleClickInstrument(idChart, typeChart)}
          >
            Voltar
          </Button>
          {imgUrl !== null && (
            <Button
              sizeButton="md"
              icon={<IoSave />}
              schemaButton="out-success"
              action={registerPositionPoint}
            >
              Salvar figura de visualização
            </Button>
          )}
        </div>

        <GreyContainer ref={greyContainerRef}>
          {file == null ? (
            <CustomDropzone setFile={setFile} fullHeight />
          ) : null}
          {file !== null ? (
            <>
              <Stage
                width={resizedWidth ? resizedWidth : 1800}
                height={resizedHeight ? resizedHeight : 800}
                style={{ cursor: cursorMove ? "move" : "default" }}
              >
                <Layer>
                  {imgUrl !== null ? <Image image={loadImage(imgUrl)} /> : null}
                  {dataPoints.map((point, index) => (
                    <>
                      {point.status === statusTermopar.INOPERANTE && (
                        <Text
                          text={"TROCAR"}
                          x={30}
                          y={30 + 50 * index}
                          fill={"blue"}
                          padding={16}
                          corner
                          sceneFunc={(context, shape) => {
                            context.fillStyle = "rgb(255, 255, 255)";
                            context.fillRect(
                              0,
                              0,
                              shape.width(),
                              shape.height()
                            );
                            (shape as Konva.Text)._sceneFunc(context);
                          }}
                          onClick={() => openModal(point.id_termopar)}
                        />
                      )}
                      <Text
                        text={point.tag}
                        x={
                          point.status !== statusTermopar.INOPERANTE ? 30 : 100
                        }
                        y={30 + 50 * index}
                        draggable={
                          point.status !== statusTermopar.INOPERANTE
                            ? true
                            : false
                        }
                        fill={
                          point.status !== statusTermopar.INOPERANTE
                            ? "white"
                            : "red"
                        }
                        padding={16}
                        sceneFunc={(context, shape) => {
                          context.fillStyle =
                            point.status !== statusTermopar.INOPERANTE
                              ? "rgb(4, 112, 116)"
                              : "rgb(255, 255, 255)";
                          context.fillRect(0, 0, shape.width(), shape.height());
                          (shape as Konva.Text)._sceneFunc(context);
                        }}
                        onDragEnd={(e) => {
                          positionElement(e);
                        }}
                        onMouseEnter={() => {
                          setCursorMove(true);
                        }}
                        onMouseLeave={(e) => {
                          setCursorMove(false);
                        }}
                      />
                    </>
                  ))}
                </Layer>
              </Stage>
            </>
          ) : null}
        </GreyContainer>
      </MainContainer>
      <Modal show={show} setShow={setShow}>
        <ChangeThermocoupleContainer
          idInstrument={idChart}
          idTermoparSelect={selectChangeIdTermopar!}
          show={show}
          setShow={setShow}
        />
      </Modal>
    </>
  );
};

export default SetPoints;
