import styled from "styled-components";

export const AddViewContainer = styled.div`
    padding: 64px;
    border: ${props => props.theme.border.style};
    border-color: ${props => props.theme.colors.blueSteel};
    border-radius: ${props => props.theme.border.radius};
    text-align: center;
    cursor: pointer;
`

export const CenteredContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
`