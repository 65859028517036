import { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../../components/main-container";
import {
  Alert,
  Card,
  Col,
  Container,
  Form,
  InputGroup,
  Row,
  Spinner,
} from "react-bootstrap";
import Button from "../../../components/button";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useFieldArray, useForm } from "react-hook-form";
import { InstrumentInputs } from "./interfaces/instrument.inputs";
import { emptyInputsValues } from "./utils/initForm";
import { yupResolver } from "@hookform/resolvers/yup";
import { schema } from "./validations/validation";
import { makeRequestBody } from "./utils/makeRequest";
import { useInstrumentsService } from "../../../hooks/api/instruments";
import { useToast } from "../../../hooks/toast";
import CustomInput from "../../../components/input";
import "bootstrap/dist/css/bootstrap.min.css";
import { EModelInput } from "../../../components/input/enum/modelInput.enum";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { IoMdSave } from "react-icons/io";
import { useIntegrationService } from "../../../hooks/api/integration";
import Checkbox from "../../../components/checkbox";
import { statusTermopar } from "../../../shared/enums/termopar/statusTermopar.enum";

interface InstrumentNewProps {}

const InstrumentNew: FunctionComponent<InstrumentNewProps> = () => {
  const { showSuccessToast, showErrorToast } = useToast();
  const navigate = useNavigate();
  const { create, restoreBySlave } = useInstrumentsService();
  const { getPortCOM, getTemperatureByIntegration } = useIntegrationService();

  const [portCom, setPortCom] = useState<string>("");
  const [slave, setSlave] = useState<number>(1);
  const [slaveTemperature, setSlaveTemperature] = useState<number[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  const [isDisabled, setIsDisabled] = useState<boolean[]>([]);

  const formMethods = useForm<InstrumentInputs>({
    defaultValues: {
      nome: "",
      time_collection: 300,
      termopares: [emptyInputsValues.termopares[0]],
    },
    mode: "onSubmit",
    resolver: yupResolver(schema, {}, {}, {}, "all", "all"),
  });

  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    control,
    setValue,
  } = formMethods;

  const { fields, replace } = useFieldArray({
    control,
    name: "termopares",
  });

  const onSubmit = handleSubmit(async (data: InstrumentInputs) => {
    data.termopares.forEach((termopar, index) => {
      termopar.status = isDisabled[index]
        ? statusTermopar.DESABILITADO
        : statusTermopar.OPERANTE;
    });
    const body = {
      port_com: portCom,
      slave_number: slave,
      ...makeRequestBody(data),
    };

    return create({ data: body })
      .then((response) => {
        showSuccessToast("Instrumento cadastrado com sucesso!");
        navigate(ROUTES.INSTRUMENTS);
      })
      .catch((err) => {
        console.log(err);
        if (err.response.data.status === "Deleted") {
          const alertOptions: SweetAlertOptions = {
            title: `Deseja restaurar o instrumento ?`,
            text: err.response.data.message,
            icon: "question",
            showDenyButton: true,
            confirmButtonText: "Restaurar",
            denyButtonText: "Cancelar",
          };

          Swal.fire(alertOptions).then(async (result) => {
            if (result.isConfirmed) {
              await restoreBySlave(slave).then((response) => {
                showSuccessToast("Instrumento restaurado com sucesso!");
                navigate(ROUTES.INSTRUMENTS);
              });
            }
          });
        } else {
          const alertOptions: SweetAlertOptions = {
            title: `Erro ao cadastrar o Instrumento`,
            text: err.response.data.message,
            icon: "warning",
            denyButtonText: "Ok",
          };
          Swal.fire(alertOptions);
        }
      });
  });

  async function getPort() {
    await getPortCOM()
      .then((response) => {
        setPortCom(response.data.toUpperCase());
      })
      .catch(() => {
        showErrorToast(
          "Não foi possível identificar a porta COM, verifique se o equipamento está conectado ao computador"
        );
        setPortCom("Porta não identificada!");
      })
      .finally(() => {
        setIsRequest(false);
      });
  }

  async function getTemperature(slave: number, port: string) {
    await getTemperatureByIntegration(slave, port)
      .then(({ data }) => {
        if (data.length !== undefined && data.length > 0) {
          setSlaveTemperature(data);
          for (const temp of data) {
            if (temp >= 32000 || temp <= -32000) {
              setIsDisabled((prev) => [...prev, true]);
            } else {
              setIsDisabled((prev) => [...prev, false]);
            }
          }
          data.forEach((temperature: number, index: number) => {
            setValue(`termopares.${index}.temperature`, temperature);
          });
        } else {
          setSlaveTemperature([]);
        }
        setIsRequest(false);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setSlaveTemperature([]);
      })
      .finally(() => {
        setIsRequest(false);
        setIsLoading(false);
      });
  }

  const handleCheckChange = (index: number) => {
    setIsDisabled((prev) => {
      const newIsDisabled = [...prev];
      newIsDisabled[index] = !newIsDisabled[index];
      return newIsDisabled;
    });
  };

  useEffect(() => {
    if (!isRequest) {
      setIsRequest(true);
      getPort();
    }
  }, []);

  useEffect(() => {
    if (portCom && portCom.includes("COM") && slave >= 0) {
      if (!isRequest) {
        setSlaveTemperature([]);
        getTemperature(slave, portCom);
        showSuccessToast(`Buscando temperaturas da slave ${slave}`);
      }
    }
  }, [slave, portCom]);

  useEffect(() => {
    const intervalTime =
      Number(process.env.TIME_SET_INTERVAL_DASHBOARD) || 2000;
    const interval = setInterval(() => {
      if (!isRequest) {
        setIsRequest(true);
        if (slave && portCom) {
          getTemperature(slave, portCom);
        }
      }
    }, intervalTime);
    return () => clearInterval(interval);
  });

  useEffect(() => {
    const newFields = slaveTemperature
      .map((temperature, index) => {
        return {
          temperature,
          canal: `canal-${index + 1}`,
          tag: `TAG_EI-${slave}_${index + 1}`,
          funcao: `Funcao-${slave}_${index + 1}`,
          minima: 10,
          maxima: 900,
          checked: true,
        };
      })
      .filter((field) => field !== null);
    replace(newFields);
  }, [slaveTemperature, replace]);

  useEffect(() => {
    setValue("nome", `Instrumento Endereço ${slave}`);
    setIsDisabled([]);
  }, [slave, setValue]);

  return (
    <MainContainer>
      <Container className="mt-5">
        <Card>
          <Card.Body>
            <Row>
              <Col>
                <Form.Label htmlFor="basic-url">
                  Insira o endereço do instrumento
                </Form.Label>
                <InputGroup className="mb-5">
                  <InputGroup.Text id="basic-addon1">Endereço</InputGroup.Text>
                  <Form.Control
                    value={slave}
                    min={0}
                    type="number"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    disabled={isLoading}
                    onChange={(e) => {
                      if (parseInt(e.target.value) >= 0) {
                        setSlave(parseInt(e.target.value));
                        setIsLoading(true);
                      }
                    }}
                  />
                </InputGroup>
              </Col>
              <Col>
                <Form.Label htmlFor="basic-url">
                  Porta COM identificada
                </Form.Label>
                <InputGroup className="mb-5">
                  <Form.Control
                    value={portCom}
                    disabled={true}
                    readOnly={true}
                  />
                </InputGroup>
              </Col>
            </Row>
            <hr />

            {isLoading ? (
              <div className="d-flex justify-content-center align-items-center">
                <Spinner animation="border" variant="primary" />{" "}
                <span className="sr-only m-3"> Buscando temperaturas</span>
              </div>
            ) : slaveTemperature && slaveTemperature.length > 0 ? (
              <form onSubmit={onSubmit}>
                <>
                  <Row>
                    <Col>
                      <CustomInput
                        label="Nome"
                        placeholder="Digite o nome para o instrumento"
                        value={`Instrumento Endereço ${slave}`}
                        type="text"
                        sizeInput="full"
                        fieldName="nome"
                        errors={errors.nome}
                        schema={errors.nome ? "danger" : "default"}
                        register={register}
                        options={{ required: true }}
                      />
                    </Col>
                    <Col>
                      <CustomInput
                        label="Tempo de coleta (30 em 30 segundos)"
                        placeholder="Valor mínimo 30 segundos"
                        type="number"
                        value={300}
                        sizeInput="full"
                        fieldName="time_collection"
                        errors={errors.time_collection}
                        schema={errors.time_collection ? "danger" : "default"}
                        register={register}
                        options={{ required: true }}
                      />
                    </Col>
                  </Row>
                  {slaveTemperature.map((temperature, index) => (
                    <Row
                      xs={1}
                      md={6}
                      key={index}
                      style={{
                        borderBottom: "1px solid #E5E7EB",
                        marginBottom: "10px",
                      }}
                    >
                      <Col className="d-flex align-items-center">
                        <CustomInput
                          model={EModelInput.TEMP}
                          label="Temperatura w"
                          value={temperature}
                          type="text"
                          sizeInput="full"
                          readOnly={true}
                          disabled={isDisabled[index]}
                          fieldName={`termopares.${index}.temperature`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.temperature
                              : undefined
                          }
                          schema={
                            errors.termopares &&
                            errors.termopares[index]?.temperature
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">
                        <Checkbox
                          checked={!isDisabled[index]}
                          text={`canal-${index + 1}`}
                          action={() => handleCheckChange(index)}
                        />
                      </Col>

                      <Col className="d-flex align-items-center">
                        <CustomInput
                          label="Tag"
                          type="text"
                          value={`TAG_EI${slave}_C${index + 1}`}
                          sizeInput="full"
                          fieldName={`termopares.${index}.tag`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.tag
                              : undefined
                          }
                          schema={
                            errors.termopares && errors.termopares[index]?.tag
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                          disabled={isDisabled[index]}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">
                        <CustomInput
                          label="Função"
                          type="text"
                          value={`Funcao_EI${slave}_C${index + 1}`}
                          placeholder="Insira a função"
                          sizeInput="full"
                          fieldName={`termopares.${index}.funcao`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.funcao
                              : undefined
                          }
                          schema={
                            errors.termopares &&
                            errors.termopares[index]?.funcao
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                          disabled={isDisabled[index]}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">
                        <CustomInput
                          model={EModelInput.TEMP}
                          label="Min. Temperatura"
                          type="number"
                          value={10}
                          placeholder="Temp. Miníma"
                          sizeInput="full"
                          fieldName={`termopares.${index}.minima`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.minima
                              : undefined
                          }
                          schema={
                            errors.termopares &&
                            errors.termopares[index]?.minima
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                          disabled={isDisabled[index]}
                        />
                      </Col>
                      <Col className="d-flex align-items-center">
                        <CustomInput
                          model={EModelInput.TEMP}
                          label="Max. Temperatura"
                          type="number"
                          value={900}
                          placeholder="Temp. Maxima"
                          sizeInput="full"
                          fieldName={`termopares.${index}.maxima`}
                          errors={
                            errors.termopares
                              ? errors.termopares[index]?.maxima
                              : undefined
                          }
                          schema={
                            errors.termopares &&
                            errors.termopares[index]?.maxima
                              ? "danger"
                              : "default"
                          }
                          register={register}
                          options={{ required: true }}
                          disabled={isDisabled[index]}
                        />
                      </Col>
                    </Row>
                  ))}
                  <Row className="mt-3">
                    <Col className="d-flex align-items-end justify-content-end">
                      <Button
                        schemaButton="out-primary"
                        sizeButton="md"
                        type="submit"
                        disabled={isSubmitting}
                      >
                        Salvar Instrumento <IoMdSave fontSize={22} />
                      </Button>
                    </Col>
                  </Row>
                </>
              </form>
            ) : (
              <Alert variant="primary">
                Não foi possível encontrar temperaturas nesse endereço!
              </Alert>
            )}
          </Card.Body>
        </Card>
      </Container>
    </MainContainer>
  );
};

export default InstrumentNew;
