import React, { FunctionComponent } from "react";
import {
  CheckboxContainer,
  HiddenCheckbox,
  StyledCheckbox,
  Text,
} from "../checkbox/styles";
import { AiOutlineCheck } from "react-icons/ai";

interface CheckboxPointProps {
  text: string;
  action?: () => void;
  checked: boolean;
  checkedForever?: boolean;
  disabled?: boolean;
}

const CheckboxPoint: FunctionComponent<CheckboxPointProps> = ({
  text,
  checked,
  checkedForever,
  disabled = false,
  action,
}) => {
  function handleCheckboxChange() {
    if (action) {
      action();
    }
  }

  if (checkedForever) {
    return (
      <CheckboxContainer checked={true}>
        <HiddenCheckbox checked={true} />
        <StyledCheckbox checked={true}>
          <AiOutlineCheck />
        </StyledCheckbox>
        <Text checked={true}>{text}</Text>
      </CheckboxContainer>
    );
  }

  return (
    <CheckboxContainer checked={checked} onClick={handleCheckboxChange}>
      <HiddenCheckbox onChange={handleCheckboxChange} checked={checked} />
      {!disabled && (
        <StyledCheckbox checked={checked}>
          {checked && <AiOutlineCheck />}
        </StyledCheckbox>
      )}
      <Text checked={checked}> {text} </Text>
    </CheckboxContainer>
  );
};
export default CheckboxPoint;
