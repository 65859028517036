import { InstrumentosRequestBody } from "../../../../shared/interfaces/instruments/instruments";
import { InstrumentInputs } from "../interfaces/instrument.inputs";

export const makeRequestBody = (
  data: InstrumentInputs
): InstrumentosRequestBody => {
  return {
    nome: data.nome,
    time_collection: data.time_collection,
    termopares: data.termopares,
  };
};
