import { FunctionComponent, useEffect, useState } from "react";
import { Modal, ProgressBar } from "react-bootstrap";
import { CustomLine, TitleModal } from "./styles";
import { useSincronizeServiceLocal } from "../../../hooks/api/sincronize";
import Swal, { SweetAlertOptions } from "sweetalert2";

interface ModalContentProps {
  closeModal: () => void;
  type: "online" | "local";
}

const ModalContentSincronize: FunctionComponent<ModalContentProps> = ({
  closeModal,
  type,
}) => {
  const { sincronizeLocalByOnline, sincronizeOnlineByLocal } =
    useSincronizeServiceLocal();
  const [now, setNow] = useState<number>(0);
  const [sincronizeInCall, setSincronizeInCall] = useState<boolean>(false);

  const handleSincronizeLocalOnline = async () => {
    if (!sincronizeInCall) {
      setSincronizeInCall(true);
      await sincronizeLocalByOnline().then(({ data }) => {
        if (data.status) {
          setNow(100);
          // showSuccessToast(data.message);
          const alertOptions: SweetAlertOptions = {
            title: "Sincronização concluída!",
            text: data.message,
            icon: "success",
            showDenyButton: false,
            confirmButtonText: "Finalizar",
          };

          Swal.fire(alertOptions).then((result) => {
            if (result.isConfirmed) {
              setSincronizeInCall(false);
              closeModal();
            }
          });
        } else {
          // showErrorToast(data.message);
          const alertOptions: SweetAlertOptions = {
            title: "Sincronização Falhou!",
            text: data.message,
            icon: "error",
            showDenyButton: false,
            confirmButtonText: "Finalizar",
          };

          Swal.fire(alertOptions).then((result) => {
            if (result.isConfirmed) {
              setSincronizeInCall(false);
              closeModal();
            }
          });
        }
      });
    }
  };

  const handleSincronizeOnlineLocal = async () => {
    if (!sincronizeInCall) {
      setSincronizeInCall(true);
      await sincronizeOnlineByLocal().then(({ data }) => {
        if (data.status) {
          setNow(100);
          // showSuccessToast(data.message);
          const alertOptions: SweetAlertOptions = {
            title: "Sincronização concluída!",
            text: data.message,
            icon: "success",
            showDenyButton: false,
            confirmButtonText: "Finalizar",
          };

          Swal.fire(alertOptions).then((result) => {
            if (result.isConfirmed) {
              setSincronizeInCall(false);
              closeModal();
            }
          });
        } else {
          // showErrorToast(data.message);
          const alertOptions: SweetAlertOptions = {
            title: "Sincronização Falhou!",
            text: data.message,
            icon: "error",
            showDenyButton: false,
            confirmButtonText: "Finalizar",
          };

          Swal.fire(alertOptions).then((result) => {
            if (result.isConfirmed) {
              setSincronizeInCall(false);
              closeModal();
            }
          });
        }
      });
    }
  };

  useEffect(() => {
    if (type === "local") {
      handleSincronizeLocalOnline();
    } else {
      handleSincronizeOnlineLocal();
    }
  }, []);

  useEffect(() => {
    if (sincronizeInCall) {
      const interval = setInterval(() => {
        setNow((value) => {
          if (value < 100) {
            return value++;
          }
          return value;
        });
      }, 100);

      return () => clearInterval(interval);
    }
  }, [sincronizeInCall]);

  return (
    <Modal.Body>
      <div className="d-block">
        <TitleModal>Sincronizando dados Locais ao banco Online</TitleModal>
        <CustomLine />
        <ProgressBar animated now={now} label={`${now}%`} />
      </div>
    </Modal.Body>
  );
};

export default ModalContentSincronize;
