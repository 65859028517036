import { InstrumentInputs } from "../interfaces/instrument.inputs";

export const emptyInputsValues: InstrumentInputs = {
  nome: "",
  temperature: 0,
  time_collection: "",
  termopares: [
    {
      canal: "canal-1",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-2",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-3",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-4",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-5",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-6",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-7",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-8",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-9",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-10",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-11",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
    {
      canal: "canal-12",
      tag: "",
      funcao: "",
      minima: "",
      maxima: "",
      temperature: "",
    },
  ],
};
