import { SiAngularuniversal } from "react-icons/si";
import { useApi } from "../";

export const useIntegrationService = () => {
  const { get, put } = useApi();

  const getPortCOM = async () => {
    return await get<any>("/integration/get-port-com");
  };

  const getTemperatureByIntegration = async (slave: number, port: string) => {
    return await put<any>("/integration/get-temperature", { port, slave });
  };

  const getTestCommunicationInstrument = async (
    port: string,
    slave: number
  ) => {
    return await put<any>("/integration/test-communication-instruments", {
      port,
      slave,
    });
  };

  return {
    getPortCOM,
    getTemperatureByIntegration,
    getTestCommunicationInstrument,
  };
};
