import { AiOutlineBell } from "react-icons/ai";
import { IoMdAlert, IoMdClose } from "react-icons/io";
import { IoAlertSharp } from "react-icons/io5";
import styled from "styled-components";

export const NotificationBox = styled.div`
  width: 45px;
  height: 45px;
  border-radius: 4px;
  border: 1px solid #ef4444;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
  &:hover {
    background: #ef4444;
  }
`;

export const TotalNotification = styled.div`
  width: 26px;
  height: 26px;
  position: absolute;
  top: -16px;
  right: -8px;
  border: 1px solid #ef4444;
  color: #fff;
  font-size: 12px;
  font-weight: 700;
  padding: 4px;
  border-radius: 13px;
  background: #ef4444;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s, color 0.3s;
  ${NotificationBox}:hover & {
    background: #fff;
    color: #ef4444;
  }
`;

export const BellIcon = styled(AiOutlineBell)`
  color: #dc2626;
  transition: color 0.5s;
  ${NotificationBox}:hover & {
    color: #fff;
  }
`;

export const AlertIcon = styled(IoAlertSharp)`
  color: #fff;
  transition: color 0.5s;
  ${NotificationBox}:hover & {
    color: #dc2626;
  }
`;

export const ClosedIcon = styled(IoMdClose)`
  color: #dc2626;
  transition: color 0.5s;
  ${NotificationBox}:hover & {
    color: #fff;
  }
`;

export const NotificationList = styled.div`
  position: absolute;
  top: 50px;
  right: 0;
  width: 300px;
  max-height: 350px;
  background: white;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow-y: auto;
  z-index: 10;
`;

export const NotificationItem = styled.div`
  cursor: pointer;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background: #f6f6f6;
  }
`;
