import styled from "styled-components";

export const SelectContainer = styled.div`
  position: relative;
  margin: 0;
`;

export const SelectLabelButton = styled.button`
  display: flex;
  padding: 16px;
  width: 240px;
  font-size: ${props => props.theme.font.size.md};
  font-weight: 500;
  background-color: ${props => props.theme.colors.white};
  border: ${props => props.theme.border.style};
  border-color: ${props => props.theme.colors.blueSteel};
  color: ${props => props.theme.colors.blueSteel};
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  box-shadow: 0 1px 4px 0 #ccc;
  transition: 0.3s ease;
`;

