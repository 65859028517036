import React, { FunctionComponent, useRef } from "react";
import {
  CustomButton,
  CustomLink,
  DropdownContainer,
  LogoutButton,
} from "./styles";
import { useOnClickOutside } from "usehooks-ts";
import { ROUTES } from "../../constants/routes";
import { useUsersService } from "../../hooks/api/user";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth-context";
import { UsersType } from "../../shared/enums/users-type";

interface DropDownProps {
  show: boolean;
  setShowDropdown: React.Dispatch<React.SetStateAction<boolean>>;
}

const DropDown: FunctionComponent<DropDownProps> = ({
  show,
  setShowDropdown,
}) => {
  const { user } = useAuthContext();
  const { putLogout, getProfile } = useUsersService();

  const handleSignOut = () => {
    putLogout().then(() => {
      window.location.href = ROUTES.LOGIN;
    });
  };

  const navigate = useNavigate();
  const handleNavigateUpdate = () => {
    getProfile().then((response) => {
      let id = response.data.id;
      let editProfile = true;
      navigate(ROUTES.UPDATE_USER, { state: { id, editProfile } });
    });
  };

  const dropdwonRef = useRef(null);

  useOnClickOutside(dropdwonRef, () => setShowDropdown(false));

  if (show)
    return (
      <DropdownContainer ref={dropdwonRef}>
        <CustomButton onClick={handleNavigateUpdate}>Meu perfil</CustomButton>
        {user.type === UsersType.ADMINISTRADOR && (
          <>
            <CustomLink to={ROUTES.USERS}>Usuários</CustomLink>
          </>
        )}
        {user.type !== UsersType.OPERADOR && (
          <>
            <CustomLink to={ROUTES.WORKS}>Obras</CustomLink>
          </>
        )}
        <CustomLink to={ROUTES.ALARM}>Alarmes</CustomLink>

        <LogoutButton onClick={handleSignOut}>Logout</LogoutButton>
      </DropdownContainer>
    );

  return <></>;
};

export default DropDown;
