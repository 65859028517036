export type TPointStatus = "hot" | "cold" | "alert" | "ok"

export type TDataDashboard = {
    label: string,
    points: {temperature: string, status: TPointStatus}[]
}

export const instrumentsData: TDataDashboard[] = [
    {label: "Instrumento 1", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 2", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 3", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 4", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 5", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 6", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 7", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 8", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 9", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 10", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 11", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 12", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Instrumento 13", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
]

export const termoparData: TDataDashboard[] = [
    {label: "Grupo 1", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Grupo 2", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
    ]},
    {label: "Grupo 3", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
    ]},
    {label: "Grupo 4", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Grupo 5", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Grupo 6", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
]


export const viewData: TDataDashboard[] = [
    {label: "Visualização 1", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Visualização 2", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Visualização 3", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
    ]},
    {label: "Visualização 4", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Visualização 5", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
    {label: "Visualização 6", points: [
        {temperature: '42,3°', status: "ok"},
        {temperature: '1215°', status: "hot"},
        {temperature: '1115°', status: "alert"},
        {temperature: '1118°', status: "cold"},
        {temperature: '1215°', status: "hot"},
        {temperature: '2.000°', status: "ok"},
        {temperature: '1118', status: "cold"},
        {temperature: '42,3°', status: "ok"},
        {temperature: '1115°', status: "alert"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '2.500°', status: "ok"},
        {temperature: '1215°', status: "hot"},
    ]},
]

export const temperatureData = [
    {temperature: 300, status: "ok"},
    {temperature: 1215, status: "hot"},
    {temperature: 1115, status: "alert"},
    {temperature: 1118, status: "cold"},
    {temperature: 2500, status: "hot"},
    {temperature: 578, status: "ok"},
    {temperature: 300, status: "ok"},
    {temperature: 1215, status: "hot"},
    {temperature: 1115, status: "alert"},
    {temperature: 1118, status: "cold"},
    {temperature: 2500, status: "hot"},
    {temperature: 578, status: "ok"},
]


export const customGroup = [
    {label: "Grupo 1 - Instrumento 1", points: ['01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°','01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°']},
    {label: "Grupo 1 - Instrumento 2", points: ['01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°']},
    {label: "Grupo 2 - Instrumento 2", points: ['01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°']},
    {label: "Grupo 4 - Instrumento 1", points: ['01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°']},
    {label: "Grupo 4 - Instrumento 2", points: ['01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°']},
    {label: "Grupo Personalizado", points: ['']}
]

export const points = ['01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°','01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°', '01 T1 3 - 42,3°']; 

export const setor = [
    {label: "Setor A", value:"1"},
    {label: "Setor B", value:"2"},
    {label: "Setor C", value:"3"},
    {label: "Setor D", value:"4"},
    {label: "Setor E", value:"5"}
]


export const usersData = [
    {name: 'Marcus Custódio', email: 'marcus@thermojet.com.br', registration: '001245', department: 'Engenharia', role: 'Administrador'},
    {name: 'João Carlos', email: 'joao@thermojet.com.br', registration: '001246', department: 'Manutenção', role: 'Operador'},
    {name: 'Julia dos Santos', email: 'julia@thermojet.com.br', registration: '001247', department: 'Admin', role: 'Administrador'},
    {name: 'Pedro Cabral', email: 'pedro@thermojet.com.br', registration: '001248', department: 'Engenharia', role: 'Operador'},
    {name: 'Juliano Santana', email: 'juliano@thermojet.com.br', registration: '001249', department: 'RH', role: 'Administrador'},
    {name: 'Ana Maria  Pereira', email: 'ana@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
    {name: 'José Carlos', email: 'jose@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
    {name: 'Pedro', email: 'ana@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
    {name: 'Ana', email: 'ana@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
    {name: 'Maria ', email: 'ana@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
    {name: 'João Santana', email: 'ana@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
    {name: 'João Santana', email: 'ana@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
    {name: 'João Santana', email: 'ana@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
    {name: 'João Santana', email: 'ana@thermojet.com.br', registration: '001250', department: 'Engenharia', role: 'Administrador'},
]

export type TAlarmData = {id: number; label: string}

export const alarmData = [
    {id: 1, label: '20/12/2023 às 10:14:11 - T1 Alta temperatura forno 1'},
    {id: 2, label: '20/12/2023 às 10:14:11 - T2 Alta temperatura forno 2'},
    {id: 3, label: '20/12/2023 às 10:14:11 - T3 Alta temperatura forno 3'},
    {id: 4, label: '20/12/2023 às 10:14:11 - T4 Baixa temperatura forno 4'},
    {id: 5, label: '20/12/2023 às 10:14:11 - T5 Alta temperatura forno 5'},
    {id: 6, label: '20/12/2023 às 10:14:11 - T6 Baixa temperatura forno 6'},
    {id: 7, label: '20/12/2023 às 10:14:11 - T7 Alta temperatura forno 7'},
    {id: 8, label: '20/12/2023 às 10:14:11 - T8 Alta temperatura forno 8'},
    {id: 9, label: '20/12/2023 às 10:14:11 - T9 Baixa temperatura forno 9'},
    {id: 10, label: '20/12/2023 às 10:14:11 - T10 Baixa temperatura forno 10'},
    {id: 11, label: '20/12/2023 às 10:14:11 - T11 Alta temperatura forno 11'},
    {id: 12, label: '20/12/2023 às 10:14:11 - T12 Alta temperatura forno 12'},
    {id: 13, label: '20/12/2023 às 10:14:11 - T13 Baixa temperatura forno 13'},
    {id: 14, label: '20/12/2023 às 10:14:11 - T14 Alta temperatura forno 14'},
]

export const instrumentsDataForSelect = [
    {label: 'Instrumento 1', value: '0'},
    {label: 'Instrumento 2', value: '1'},
    {label: 'Instrumento 3', value: '2'},
    {label: 'Instrumento 4', value: '3'},
    {label: 'Instrumento 5', value: '4'},
    {label: 'Instrumento 6', value: '5'},
    {label: 'Instrumento 7', value: '6'},
    {label: 'Instrumento 8', value: '7'},
]

export const groupsDataForSelect = [
    {label: 'Grupo 1', value: '0'},
    {label: 'Grupo 2', value: '1'},
    {label: 'Grupo 3', value: '2'},
    {label: 'Grupo 4', value: '3'},
    {label: 'Grupo 5', value: '4'},
    {label: 'Grupo 6', value: '5'},
    {label: 'Grupo 7', value: '6'},
    {label: 'Grupo 8', value: '7'},
]

export const rolesDataForSelect = [
    {label: 'Usuario', value: '0'},
    {label: 'Administrador', value: '1'},
    {label: 'Engenheiro', value: '2'},
]