import { FunctionComponent, useEffect, useState } from "react";
import MainContainer from "../../../components/main-container";
import { Col, Container, Row } from "react-bootstrap";
import { HrWork, TitleWorkForm, WorkFormContainer } from "./styles";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../constants/routes";
import { useToast } from "../../../hooks/toast";
import { SubmitHandler, useForm } from "react-hook-form";
import { useWorksService } from "../../../hooks/api/works";
import CustomInput from "../../../components/input";
import { ButtonForSubmit } from "../../../components/input/styles";
import Select from "../../../components/select";
import { useClientsService } from "../../../hooks/api/clients";
import { formatDataForSelect } from "../../../utils/formatDataForSelect";
import { IWork } from "../../../shared/interfaces/works/works";

interface UpdateWorkProps {}

interface IFormInput {
  descricao: string;
  data_inicio: Date;
  data_fim: Date;
  cliente_id: number;
}

const UpdateWork: FunctionComponent<UpdateWorkProps> = () => {
  const [allClients, setAllClients] = useState<any[]>([]);
  const [oldData, setOldData] = useState<IWork>();

  const { register, handleSubmit, formState, control } = useForm<IFormInput>();
  const { showErrorToast, showSuccessToast } = useToast();
  const { update, getOne } = useWorksService();
  const { getAllForDropdown } = useClientsService();

  const navigate = useNavigate();
  const location = useLocation();
  const workId = location.state.id;

  const onSubmit: SubmitHandler<IFormInput> = (data) => {
    update(data, workId)
      .then(() => {
        handleClickSave();
        showSuccessToast("Obra Atualizada com sucesso");
      })
      .catch((err) => {
        showErrorToast("Falha ao realizar atualização de obra");
      });
  };

  const handleClickSave = () => {
    navigate(ROUTES.WORKS);
  };

  useEffect(() => {
    getOne(workId)
      .then(({ data }) => {
        setOldData(data);
        getAllForDropdown().then(({ data: dataClientes }) => {
          setAllClients(
            formatDataForSelect(dataClientes, {
              newLabel: "nome",
              newValue: "id",
            })
          );
        });
      })
      .catch((err) => {
        showErrorToast("Falha ao buscar obra");
      });
  }, []);

  if (!oldData || !allClients) return null;
  return (
    <MainContainer>
      <Container className="mt-5">
        <WorkFormContainer>
          <TitleWorkForm>Nova Obra</TitleWorkForm>
          <HrWork />
          <form onSubmit={handleSubmit(onSubmit)}>
            <Row>
              <Col>
                <CustomInput
                  label="Descrição"
                  type="text"
                  sizeInput="full"
                  defaultValue={oldData.descricao}
                  fieldName="descricao"
                  errors={formState.errors.descricao}
                  schema={formState.errors.descricao ? "danger" : "default"}
                  placeholder="Descrição da obra"
                  register={register}
                  options={{ required: true }}
                />
              </Col>
              <Col>
                <Select
                  defaultOption={{
                    label: oldData.cliente,
                    value: oldData.cliente_id,
                  }}
                  options={allClients}
                  label="Cliente"
                  control={control}
                  name="cliente_id"
                  rules={{}}
                  validationMessage={
                    formState.errors.cliente_id
                      ? formState.errors.cliente_id.message
                      : undefined
                  }
                  schema={formState.errors.cliente_id ? "danger" : "default"}
                  sizeInput="full"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <CustomInput
                  defaultValue={new Date(oldData.data_inicio)
                    .toISOString()
                    .slice(0, 10)}
                  label="Data Início"
                  type="date"
                  sizeInput="full"
                  fieldName="data_inicio"
                  errors={formState.errors.data_inicio}
                  schema={formState.errors.data_inicio ? "danger" : "default"}
                  register={register}
                  options={{ required: true }}
                />
              </Col>
              <Col>
                <CustomInput
                  defaultValue={new Date(oldData.data_fim)
                    .toISOString()
                    .slice(0, 10)}
                  label="Data Fim"
                  type="date"
                  sizeInput="full"
                  fieldName="data_fim"
                  errors={formState.errors.data_fim}
                  schema={formState.errors.data_fim ? "danger" : "default"}
                  register={register}
                  options={{ required: true }}
                />
              </Col>
            </Row>
            <ButtonForSubmit type="submit" value="Atualizar" />
          </form>
        </WorkFormContainer>
      </Container>
    </MainContainer>
  );
};

export default UpdateWork;
