import styled from "styled-components";

export const LoginContainer = styled.div`
    display: block;
    width: 45vw;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    text-align: center;
`

export const ImgLogo = styled.img`
    margin-top: -80px;
    width: auto;
    height: 120px;
`

export const FormContainer = styled.div`
    width: 100%;
    min-height: 60vh;
    margin: auto;
    display: block;
    background: #F9FAFB;
    border: 1px solid #E5E7EB;
    border-radius: 4px;
    padding: 40px;
`

export const TitleLogin = styled.h3`
    color: #4B5563;
    font-size: 20px;
    font-weight: 700;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 16px;
`

export const HrLogin = styled.hr`
    border: 0;
    width: 100%;
    height: 1px;
    background: #E5E7EB;
`