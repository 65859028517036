import { FunctionComponent, useEffect, useState } from "react";
import Table from "rc-table";
import Button from "../../../components/button";
import MainContainer from "../../../components/main-container";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../hooks/toast";
import "../../../styles/table.css";
import { ROUTES } from "../../../constants/routes";
import ReactPaginate from "react-paginate";
import {
  MdEdit,
  MdOutlineArrowBackIos,
  MdOutlineArrowForwardIos,
} from "react-icons/md";
import { useInstrumentsService } from "../../../hooks/api/instruments";
import Swal, { SweetAlertOptions } from "sweetalert2";
import { FaTrash } from "react-icons/fa6";
import { statusTermopar } from "../../../shared/enums/termopar/statusTermopar.enum";

interface InstrumentListProps {}

const InstrumentList: FunctionComponent<InstrumentListProps> = () => {
  const [page, setPage] = useState(0);
  const { showErrorToast, showSuccessToast } = useToast();

  const [formattedData, setFormattedData] = useState<
    { id: number; name: string; slave: string; ativos: number; total: number }[]
  >([]);

  const [filterData, setFilterData] =
    useState<{ name: string }[]>(formattedData);
  const n = 10;

  const { getByObrasInstrumentos, deleteOne, getOneInstrument } =
    useInstrumentsService();

  const loadList = () => {
    getByObrasInstrumentos().then(({ data }) => {
      setFormattedData(
        data.map((item) => {
          const termoparesHabilitados = item.termopares.filter(
            (termopar) => termopar.status !== statusTermopar.DESABILITADO
          );
          return {
            id: item.id,
            name: item.nome,
            slave: `Endereço ${item.slave_number}`,
            ativos: termoparesHabilitados.length,
            total: item.termopares.length,
          };
        })
      );
    });
  };

  useEffect(() => {
    setFilterData(
      formattedData.filter((item, index) => {
        return index >= page * n && index < (page + 1) * n;
      })
    );
  }, [page, formattedData]);

  useEffect(() => {
    loadList();
  }, []);

  const handleNavigateUpdate = (id: number) => {
    navigate(ROUTES.UPDATE_INSTRUMENT, { state: { id } });
  };

  const handleShowDelete = (id: number) => {
    getOneInstrument(id)
      .then(({ data }) => {
        if (data) {
          const alertOptions: SweetAlertOptions = {
            title: `Tem certeza que deseja excluir o instrumento ${data.nome}`,
            text: "A ação poderá afetar outros registros.",
            icon: "warning",
            showDenyButton: true,
            confirmButtonText: "Sim",
            denyButtonText: "Não",
          };

          Swal.fire(alertOptions).then((result) => {
            if (result.isConfirmed) {
              handleDelete(data.id);
            }
          });
        }
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const handleDelete = (id: number) => {
    deleteOne(id)
      .then((response) => {
        console.log("Response Delete: ", response);
        showSuccessToast("Instrumento deletado com sucesso!");
        loadList();
      })
      .catch((error) => {
        console.log("Error Delete: ", error);
      });
  };

  const navigate = useNavigate();

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: 1500,
    },
    {
      title: "Endereço do instrumento",
      dataIndex: "slave",
      key: "slave",
      width: 1000,
      className: "center-text",
    },
    {
      title: "Termopares ativos",
      dataIndex: "ativos",
      key: "ativos",
      width: 600,
      className: "center-text",
    },
    {
      title: "Total de termopares",
      dataIndex: "total",
      key: "total",
      width: 600,
      className: "center-text",
    },
    {
      title: "Ações",
      dataIndex: "id",
      key: "id",

      render: (id: number) => (
        <div style={{ display: "flex", gap: "16px" }}>
          <Button
            schemaButton="out-primary"
            sizeButton="md"
            action={() => handleNavigateUpdate(id)}
          >
            Editar
            <MdEdit fontSize={22} />
          </Button>
          <Button
            schemaButton="out-danger"
            sizeButton="md"
            action={() => handleShowDelete(id)}
          >
            Excluir
            <FaTrash fontSize={18} />
          </Button>
        </div>
      ),
    },
  ];

  return (
    <MainContainer>
      <Button
        schemaButton="full-primary"
        sizeButton="md"
        action={() => navigate(ROUTES.NEW_INSTRUMENT)}
      >
        Novo Instrumento
      </Button>
      <Table columns={columns} data={filterData} className="mt-3" />
      <div className="d-flex w-100 justify-content-center align-items-center mt-4">
        <ReactPaginate
          containerClassName={"pagination"}
          activeClassName={"active"}
          pageClassName={"page-item"}
          nextClassName={"next-label"}
          previousClassName={"previous-label"}
          onPageChange={(event) => setPage(event.selected)}
          breakLabel="..."
          pageCount={Math.ceil(formattedData.length / n)}
          previousLabel={<MdOutlineArrowBackIos color="#4B5563" />}
          nextLabel={<MdOutlineArrowForwardIos color="#4B5563" />}
        />
      </div>
    </MainContainer>
  );
};

export default InstrumentList;
