import React, { FunctionComponent, ReactNode, PropsWithChildren } from "react";
import { TitleSection } from "./styles";

interface HeaderSectionProps {
  title: string;
  buttonLeft?: ReactNode;
  buttonRight?: ReactNode;
}

const HeaderSection: FunctionComponent<
  PropsWithChildren<HeaderSectionProps>
> = ({ buttonLeft, buttonRight, title, children }) => {
  return (
    <>
      <div className="d-flex justify-content-between align-items-center mb-3 mt-3">
        <div className="d-flex align-items-center">
          <TitleSection>{title}</TitleSection>
          {buttonLeft}
        </div>
        <div>{buttonRight}</div>
      </div>
      {children}
    </>
  );
};

export default HeaderSection;
