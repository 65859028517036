import React, { useCallback, useEffect, useRef, useState } from "react";
import { Col, Container, OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import ChartContainer from "../../components/chart-container";
import { TypeGraphics } from "../../shared/enums/layoutContent/typeGraphics.enum";
import TemperatureChart from "../../components/charts/temperature-chart";
import { TypeElement } from "../../shared/enums/typeContent/typeElement.enum";
import CustomAreaChart from "../../components/charts/custom-area-chart";
import CustomLineChart from "../../components/charts/custom-line-chart";
import MainContainer from "../../components/main-container";
import Button from "../../components/button";
import { FaLeftLong } from "react-icons/fa6";
import { IQuadrosFigure } from "../../shared/interfaces/quadros/quadros";
import { ROUTES } from "../../constants/routes";
import { KonvaEventObject } from "konva/lib/Node";
import { useLocation, useNavigate } from "react-router-dom";
import { useToast } from "../../hooks/toast";
import { useInstrumentsService } from "../../hooks/api/instruments";
import { useGroupsService } from "../../hooks/api/groups";
import { statusTermopar } from "../../shared/enums/termopar/statusTermopar.enum";
import { useQuadrosService } from "../../hooks/api/quadros";
import { IoAlertCircleOutline, IoEye, IoEyeOff, IoSave } from "react-icons/io5";
import { GreyContainer } from "../../components/grey-container/styles";
import CustomDropzone from "../../components/custom-dropzone";
import { Image, Layer, Stage, Text } from "react-konva";
import Konva from "konva";
import Modal from "../../components/modal";
import ChangeThermocoupleContainer from "../../components/change-thermocouple-container";
import { IInstrumentsTermoparesCheck } from "../../shared/interfaces/instruments/instruments";
import { ITermoparChecked } from "../../shared/interfaces/termopares/termopares";
import HeaderSection from "../../components/header-section";
import CarouselCustomized from "../../components/carousel";
import {
  ContainerForCheckbox,
  ContainerForPoints,
  ContainerInstrumentName,
  TermoparContainer,
  TermoparIndex,
} from "../group/new/styles";
import CheckboxPoint from "../../components/checkbox-points";
import { EPointStatus } from "../dashboard/enum/points.enum";
import { useTermoparesService } from "../../hooks/api/termopares";
import {
  BoxForStatusIcon,
  PointBox,
  PointTemperature,
} from "../../components/instrument-container/styles";
import { FaRegSnowflake } from "react-icons/fa";
import { SiFireship } from "react-icons/si";
import { points } from "../../static/data/data";
import { useTemperatureService } from "../../hooks/api/temperature";
interface dataGraphic {
  title: string;
  typeElement: TypeElement;
  typeGraphic: TypeGraphics;
  id: number;
}
interface DataPoints {
  id_termopar: number;
  tag: string;
  x: number;
  y: number;
  min: number;
  max: number;
  index: number;
  status: statusTermopar;
  statusPoint: EPointStatus;
  temperature: number | null;
}

const FullScreen: React.FC = () => {
  const parsedUrl = new URL(window.location.href);
  const params = new URLSearchParams(parsedUrl.search);

  const typeGraphic = params.get("typeGraphic");
  const typeElement = params.get("typeElement");
  const idElement = params.get("idElement");
  const title = params.get("title");

  const [dataGraphic, setDataGraphic] = useState<dataGraphic>();
  const [resizedImage, setResizedImage] = useState<HTMLImageElement | null>(
    null
  );

  const { showSuccessToast, showErrorToast } = useToast();

  const { getAllWithTermopares } = useInstrumentsService();
  const { getTemperatureCurrentByThermocouples } = useTemperatureService();

  const [instrumentsTermopares, setInstrumentsTermopares] = useState<
    IInstrumentsTermoparesCheck[]
  >([] as IInstrumentsTermoparesCheck[]);

  const [dataPoints, setDataPoints] = useState<DataPoints[]>(
    [] as DataPoints[]
  );
  const [selectChangeIdTermopar, setSelectChangeIdTermopar] =
    useState<number>();

  const [selectedPoints, setSelectedPoints] = useState<ITermoparChecked[]>(
    [] as ITermoparChecked[]
  );

  const [file, setFile] = useState<any[] | null>(null);
  const [imgUrl, setImageUrl] = useState<string | null>(null);
  const [show, setShow] = useState<boolean>(false);
  const [cursorMove, setCursorMove] = useState(false);
  const [resizedWidth, setResizedWidth] = useState<number>();
  const [resizedHeight, setResizedHeight] = useState<number>();
  const [monitoring, setMonitoring] = useState<boolean>(false);
  const [isRequest, setIsRequest] = useState<boolean>(false);
  useEffect(() => {
    if (file !== null) {
      setImageUrl(file[0].preview);
    }
  }, [file]);

  const greyContainerRef = useRef<HTMLDivElement>(null);

  const handleGetAllInstrumentsTermopares = () => {
    getAllWithTermopares()
      .then(({ data }) => {
        let i = 1;
        data.forEach((instrumento) => {
          instrumento.termopares = instrumento.termopares.filter(
            (termopar) => termopar.status !== statusTermopar.DESABILITADO
          );
          instrumento.termopares.forEach((termopar) => {
            termopar.index = i;
            i++;
          });
        });
        setInstrumentsTermopares(data);
      })
      .catch((err) => {
        showErrorToast("Erro ao carregar os dados.");
      });
  };

  const checkPoint = (newPointId: number) => {
    const updatedInstrumentsTermopares = instrumentsTermopares.map(
      (instrument) => {
        return {
          ...instrument,
          termopares: instrument.termopares.map((termopar) => {
            if (termopar.id === newPointId) {
              return {
                ...termopar,
                checked: !termopar.checked,
              };
            }
            return termopar;
          }),
        };
      }
    );

    const updatedSelectedPoints = updatedInstrumentsTermopares
      .flatMap((instrument) => instrument.termopares)
      .filter((termopar) => termopar.checked);

    let resultDataPoints: DataPoints[] = [];
    updatedSelectedPoints.map((item, index) => {
      let data: DataPoints = {
        id_termopar: item.id,
        status: item.status,
        statusPoint: EPointStatus.DEFAULT,
        tag: item.tag,
        min: item.minima,
        max: item.maxima,
        temperature:
          item.temperatura && item.temperatura[0]
            ? item.temperatura[0].valor
            : 0,
        x: 0,
        y: 0,
        index: index + 1,
      };
      resultDataPoints.push(data);
    });

    dataPoints.forEach((point) => {
      resultDataPoints.forEach((resultPoint) => {
        if (resultPoint.id_termopar == point.id_termopar) {
          resultPoint.x = point.x;
          resultPoint.y = point.y;
        }
      });
    });

    setDataPoints(resultDataPoints);
    setInstrumentsTermopares(updatedInstrumentsTermopares);
    setSelectedPoints(updatedSelectedPoints);
  };

  async function getTemperature() {
    const id_termopares: number[] = selectedPoints.map((point) => {
      return point.id;
    });

    if (id_termopares.length > 0) {
      await getTemperatureCurrentByThermocouples(id_termopares).then(
        async (response) => {
          if (response) {
            let resultDataPoints: DataPoints[] = [];
            dataPoints.forEach((point, index) => {
              const temperatura = response.data[index];
              let statusPoint: EPointStatus;

              if (temperatura !== 0) {
                if (temperatura! < point.min) {
                  statusPoint = EPointStatus.COLD;
                } else if (temperatura! > point.max) {
                  statusPoint = EPointStatus.HOT;
                } else {
                  statusPoint = EPointStatus.DEFAULT;
                }
              } else {
                statusPoint = EPointStatus.ALERT;
              }

              point.temperature = temperatura;
              point.statusPoint = statusPoint;
              resultDataPoints.push(point);
            });
            setDataPoints(resultDataPoints);
          }
        }
      );
    }
    setIsRequest(false);
  }

  const loadImage = (url: string) => {
    const image = new window.Image();
    image.src = url;
    if (resizedHeight === undefined) {
      image.onload = () => {
        if (greyContainerRef.current) {
          const containerWidth = greyContainerRef.current.offsetWidth - 15;
          const containerHeight = greyContainerRef.current.offsetHeight - 15;

          const scaleFactor = Math.min(
            containerWidth / image.width,
            containerHeight / image.height
          );

          const resizedWidth1 = image.width * scaleFactor;
          const resizedHeight1 = image.height * scaleFactor;

          // Criando um novo objeto de imagem redimensionado
          const canvas = document.createElement("canvas");
          const context = canvas.getContext("2d");
          canvas.width = resizedWidth1;
          canvas.height = resizedHeight1;
          context?.drawImage(image, 0, 0, resizedWidth1, resizedHeight1);

          const resizedImageSrc = canvas.toDataURL();
          const newImage = new window.Image();
          newImage.src = resizedImageSrc;

          setResizedImage(newImage);

          setResizedWidth(resizedWidth1);
          setResizedHeight(resizedHeight1);
        }
      };
    } else {
      image.width = resizedWidth!;
      image.height = resizedHeight!;
    }
    return image;
  };

  const positionElement = (data: KonvaEventObject<DragEvent>) => {
    const newDataPoints = dataPoints.map((point) => {
      if (point.index === data.target.index) {
        return {
          ...point,
          x: data.target.attrs.x,
          y: data.target.attrs.y,
        };
      } else {
        return point;
      }
    });
    setDataPoints(newDataPoints);
  };

  const checkIcon = useCallback((status: EPointStatus) => {
    switch (status) {
      case "alert":
        return (
          <BoxForStatusIcon>
            <IoAlertCircleOutline color="#DC2626" />
          </BoxForStatusIcon>
        );
      case "cold":
        return (
          <BoxForStatusIcon>
            <FaRegSnowflake color="#3B82F6" />
          </BoxForStatusIcon>
        );
      case "hot":
        return (
          <BoxForStatusIcon>
            <SiFireship color="#B91C1C " />
          </BoxForStatusIcon>
        );
      case "ok":
        return <></>;
      default:
        return <></>;
    }
  }, []);

  useEffect(() => {
    if (typeGraphic === TypeGraphics.FIGURE) {
      handleGetAllInstrumentsTermopares();
    } else {
      setDataGraphic({
        id: parseInt(idElement!),
        title: title!,
        typeElement: typeElement as TypeElement,
        typeGraphic: typeGraphic as TypeGraphics,
      });
    }
  }, []);

  useEffect(() => {
    const intervalTime = Number(process.env.TIME_SET_INTERVAL_CHARTS) || 5000;

    const interval = setInterval(() => {
      if (monitoring && !isRequest) {
        setIsRequest(true);
        getTemperature();
      }
    }, intervalTime);

    return () => clearInterval(interval);
  }, [monitoring, isRequest]);

  return (
    <Container fluid className="my-5">
      {typeGraphic === TypeGraphics.FIGURE ? (
        <>
          {imgUrl !== null && (
            <>
              <HeaderSection title="Todos Instrumentos" />
              <CarouselCustomized
                itemsByResponsive={{ desktop: 4, mobile: 2, tablet: 4 }}
              >
                {instrumentsTermopares.map((instrument, index) => (
                  <ContainerForPoints key={index}>
                    <ContainerInstrumentName>
                      <p className="text-center">{instrument.nome}</p>
                    </ContainerInstrumentName>
                    <ContainerForCheckbox>
                      {instrument.termopares.map((termopar, indexPoint) => (
                        <TermoparContainer key={termopar.id}>
                          <TermoparIndex>{termopar.index}</TermoparIndex>
                          <CheckboxPoint
                            text={termopar.tag}
                            action={() => checkPoint(termopar.id)}
                            disabled={
                              termopar.status === statusTermopar.INOPERANTE
                                ? true
                                : false
                            }
                            checked={termopar.checked}
                          />
                        </TermoparContainer>
                      ))}
                    </ContainerForCheckbox>
                  </ContainerForPoints>
                ))}
              </CarouselCustomized>

              <Button
                className="mt-5"
                sizeButton="md"
                icon={
                  monitoring ? (
                    <IoEyeOff style={{ fontSize: 24 }} />
                  ) : (
                    <IoEye style={{ fontSize: 24 }} />
                  )
                }
                schemaButton={monitoring ? "full-danger" : "out-primary"}
                action={() => {
                  setMonitoring(!monitoring);
                }}
              >
                {monitoring ? "Parar de Monitorar" : "Monitorar"}
              </Button>
            </>
          )}

          <GreyContainer
            ref={greyContainerRef}
            style={{ position: "relative" }}
          >
            {file == null ? (
              <CustomDropzone setFile={setFile} fullHeight />
            ) : null}
            {file !== null ? (
              <>
                <Stage
                  width={resizedWidth ? resizedWidth : 1800}
                  height={resizedHeight ? resizedHeight : 800}
                  style={{ cursor: cursorMove ? "move" : "default" }}
                >
                  <Layer>
                    {imgUrl !== null ? (
                      <Image image={loadImage(imgUrl)} />
                    ) : null}
                    {!monitoring &&
                      dataPoints.map((point, index) => (
                        <Text
                          text={point.tag}
                          x={
                            point.status !== statusTermopar.INOPERANTE
                              ? point.x != 0
                                ? point.x
                                : 30
                              : 100
                          }
                          y={
                            point.status !== statusTermopar.INOPERANTE &&
                            point.y != 0
                              ? point.y
                              : 30 + 50 * index
                          }
                          draggable={
                            point.status !== statusTermopar.INOPERANTE
                              ? true
                              : false
                          }
                          fill={
                            point.status !== statusTermopar.INOPERANTE
                              ? "white"
                              : "red"
                          }
                          padding={16}
                          sceneFunc={(context, shape) => {
                            context.fillStyle =
                              point.status !== statusTermopar.INOPERANTE
                                ? "rgb(4, 112, 116)"
                                : "rgb(255, 255, 255)";
                            context.fillRect(
                              0,
                              0,
                              shape.width(),
                              shape.height()
                            );
                            (shape as Konva.Text)._sceneFunc(context);
                          }}
                          onDragEnd={(e) => {
                            positionElement(e);
                          }}
                          onMouseEnter={() => {
                            setCursorMove(true);
                          }}
                          onMouseLeave={(e) => {
                            setCursorMove(false);
                          }}
                        />
                      ))}
                  </Layer>
                </Stage>
                {monitoring &&
                  dataPoints.map((point, index) => (
                    <OverlayTrigger
                      key={index}
                      placement="auto-start"
                      overlay={
                        <Tooltip id={`tooltip-${index}`}>
                          <strong>{point.tag}</strong>.
                        </Tooltip>
                      }
                    >
                      <div
                        key={index}
                        style={{
                          position: "absolute",
                          top: point.y,
                          left: point.x,
                        }}
                      >
                        <PointBox>
                          <PointTemperature status={point.statusPoint}>
                            {point.temperature !== null
                              ? `${point.temperature}°C`
                              : "N/A"}
                          </PointTemperature>
                          {checkIcon(point.statusPoint)}
                        </PointBox>
                      </div>
                    </OverlayTrigger>
                  ))}
              </>
            ) : null}
          </GreyContainer>

          <Modal show={show} setShow={setShow}>
            <ChangeThermocoupleContainer
              idInstrument={parseInt(idElement!)}
              idTermoparSelect={selectChangeIdTermopar!}
              show={show}
              setShow={setShow}
            />
          </Modal>
        </>
      ) : (
        <Row className="my-5">
          <Col lg={12} md={12}>
            <ChartContainer
              newTab={false}
              title={dataGraphic?.title!}
              type={dataGraphic?.typeGraphic!}
            >
              {(() => {
                switch (dataGraphic?.typeGraphic!) {
                  case TypeGraphics.BARGRAPH:
                    return (
                      <TemperatureChart
                        id={dataGraphic?.id!}
                        type={dataGraphic?.typeElement!}
                        fullScreen={true}
                      />
                    );
                  case TypeGraphics.HISTORICO:
                    return (
                      <CustomLineChart
                        id={dataGraphic?.id!}
                        type={dataGraphic?.typeElement!}
                        fullScreen={true}
                      />
                    );
                  case TypeGraphics.TENDENCIA:
                    return (
                      <CustomAreaChart
                        id={dataGraphic?.id!}
                        type={dataGraphic?.typeElement!}
                        fullScreen={true}
                      />
                    );
                  default:
                    return <></>;
                }
              })()}
            </ChartContainer>
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default FullScreen;
