import React, { FunctionComponent } from "react";
import { BreadcrumbContainer } from "./styles";
import { GrHomeRounded } from "react-icons/gr";
import { MdArrowForwardIos } from "react-icons/md";
import { CustomLink } from "../link/styles";
import { ROUTES } from "../../constants/routes";

interface BreadcrumbProps {}

const Breadcrumb: FunctionComponent<BreadcrumbProps> = () => {
  const url = window.location.pathname;
  const cleanedPaths = url.split("/").filter((item) => item !== "");
  const formatedPaths = cleanedPaths.map((word) => {
    const firstLetter = word.charAt(0);
    const firstLetterCap = firstLetter.toUpperCase();
    const remainingLetters = word.slice(1);

    return firstLetterCap + remainingLetters;
  });

  return (
    <BreadcrumbContainer>
      <GrHomeRounded color="#4B5563" style={{ marginRight: "8px" }} />
      <CustomLink to={ROUTES.DASHBOARD}>Home</CustomLink>
      {formatedPaths.length > 0 && (
        <MdArrowForwardIos color="#4B5563" style={{ margin: "0 8px" }} />
      )}
      {formatedPaths.map((pathname, index) => (
        <React.Fragment key={pathname}>
          {index !== formatedPaths.length - 1 ? (
            <CustomLink to={`/${pathname}`} style={{ color: "#4B5563" }}>
              {pathname}
            </CustomLink>
          ) : (
            <span style={{ color: "#2563EB" }}>{pathname}</span>
          )}
          {index !== formatedPaths.length - 1 && (
            <MdArrowForwardIos color="#4B5563" style={{ margin: "0 8px" }} />
          )}
        </React.Fragment>
      ))}
    </BreadcrumbContainer>
  );
};

export default Breadcrumb;
