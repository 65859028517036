import styled from 'styled-components';

export type TSizeCheckBox = 'lg' | 'md' 


export const CheckboxContainer = styled.div<{checked: boolean}>`
    /* width: 100%; */
    height: 35px;
    padding-left: 5px;
    margin: 0px 4px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    cursor: pointer;
`;
export const HiddenCheckbox = styled.input.attrs({type: 'checkbox'})`
    overflow: hidden;
    white-space: nowrap;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
`;
export const Text = styled.label<{checked: boolean}>`
    color: ${props => props.theme.colors.black};
    cursor: pointer;
`;

export const StyledCheckbox = styled.label<{checked: boolean}>`
    width: 20px;
    height: 20px;
    /* width: ${props => props.theme.checkbox.width}; */
    /* height: ${props => props.theme.checkbox.height}; */
    
    border-color: ${props => props.theme.colors.black};
    border: 2px solid;
    border-radius: 4px;
    background: ${props => props.theme.colors.white};
    margin-right: ${props => props.theme.padding.sm};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;