import { useApi } from "../";
import { IResultApi } from "../../../shared/interfaces/api/api.response";
import {
  IQuadros,
  IQuadrosFigure,
  IRequestBodyByObrasQuadros,
  IRequestBodyQuadros,
  IRequestQuadrosFigure,
} from "../../../shared/interfaces/quadros/quadros";
import { TypeElement } from "../../../shared/enums/typeContent/typeElement.enum";

export const useQuadrosService = () => {
  const { get, post, put } = useApi();

  const getAllWithTermopares = async () => {
    const result = await get<IRequestBodyQuadros>("/quadros/all");
    if (result) {
      return result;
    }
  };

  // const getOneInstrument = (id: number) => {
  //   return get<IInstrumentsTermopares>("/instrumentos/get-by-id/" + id);
  // };

  // const getAll = async () => {
  //   const result = await get<IInstrumentsTermopares[]>("/instrumentos/all");
  // };

  const getByObrasQuadros = () => {
    return get<IRequestBodyByObrasQuadros[]>("/quadros/get-by-obra");
  };

  const create = async (data: IQuadros) => {
    return post<IResultApi>("/quadros/create", data);
  };

  const getByInstrumentoOrGrupo = async (
    id: number,
    data: { type: TypeElement }
  ) => {
    return put<IRequestQuadrosFigure>(
      `/quadros/get-by-instrumento-or-grupo/${id}`,
      data
    );
  };

  const createFigure = async (data: IQuadrosFigure) => {
    return post<IResultApi>("/quadros/create/figure", data);
  };

  // const update = (id: number, data: InstrumentosRequestBody) => {
  //   return put<IResultApi>("/instrumentos/update/" + id, data);
  // };

  // const deleteOne = (id: number) => {
  //   return del<IResultApi>("/instrumentos/delete/" + id);
  // };

  return {
    getAllWithTermopares,
    getByObrasQuadros,
    getByInstrumentoOrGrupo,
    create,
    createFigure,
  };
};
