import styled from "styled-components";

export const ContainerMiniLayout = styled.div`
    display: flex;
    align-items: center;
    gap: 8px;
`

export const AlterLayoutLabel = styled.p`
    color: ${(props) => props.theme.colors.blueSteel};
    font-size: 16px;
    font-weight: ${(props) => props.theme.font.weight.default};
`

export const FigureLayout = styled.span`
    display: flex;
    width: fit-content;
    height: fit-content;
    padding: 8px 4px;
    border-radius: 4px;
    border: 1px solid ${(props) => props.theme.colors.grey};
    cursor: pointer;
`

export const ContainerLayoutSquare = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;
`