import styled from 'styled-components'

type TDropzone = {
    isDragAccept: boolean
    isDragReject: boolean
    isFocused: boolean
    fullHeight: boolean
}

const getColor = (props: TDropzone) => {
    if (props.isDragAccept) {
        return '#00e676';
    }
    if (props.isDragReject) {
        return '#ff1744';
    }
    if (props.isFocused) {
        return '#2196f3';
    }
    return '#eeeeee';
}
  
export const ContainerDropzone = styled.div<TDropzone>`
    flex: 1;
    height: ${props => props.fullHeight ? '100%' : 'auto'};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 72px;
    border-width: 2px;
    border-radius: 2px;
    border-color: ${props => getColor(props)};
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border .24s ease-in-out;
`;

export const TextDropzone = styled.strong`
    margin-top: ${props => props.theme.padding.md};
    color: ${props => props.theme.colors.black};
    display: block
`;
