import { useApi } from "../";
import { IResultApi } from "../../../shared/interfaces/api/api.response";
import { IPaginateQuery } from "../../../shared/interfaces/api/api.paginate.request";
import { ICreateGrupos } from "../../../shared/interfaces/groups/groups.create";
import { IGroup, IGroups } from "../../../shared/interfaces/groups/groups";
import {
  IGroupTermopares,
} from "../../../pages/dashboard/interface/grupos.data";
import { IUpdateGrupos } from "../../../shared/interfaces/groups/groups.update";

export const useGroupsService = () => {
  const { post, put, get, del } = useApi();

  const getAll = async ({ take, page, search }: IPaginateQuery) => {
    return get<IGroups>("/grupos/all", { params: { take, page, search } });
  };

  const getOneGroup = async (id: number) => {
    return get<IGroup>(`/grupos/get-by-id/${id}`);
  };

  const getByObrasGrupos = () => {
    return get<IGroupTermopares[]>("/grupos/get-by-obra");
  };

  const create = async (body: ICreateGrupos) => {
    return post<IResultApi>("/grupos/create", body);
  };

  const update = async (id: number, body: IUpdateGrupos) => {
    return put<IResultApi>(`/grupos/update/${id}`, body);
  };

  const deleteOne = async (id: number) => {
    return del<IResultApi>(`/grupos/delete/${id}`);
  };

  return {
    create,
    getAll,
    getOneGroup,
    update,
    deleteOne,
    getByObrasGrupos,
  };
};
